import { FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import { IonContent, IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import classNames from 'classnames';
import { getIsCurrentCardBlurredSelector, getRadabankCurrentCardSelector, useAppSelector } from 'store';
import { useMeasureNonRenderedElement } from 'utils/hooks';
import { RadabankOperationsMenu } from '../OperationsMenu';
import { RadabankCardsSlider } from './CardsSlider';
import styles from './index.module.scss';

interface RadabankCardWithOperationsProps {
	isPaymentAllowed: boolean;
	isFrozen: boolean;
	onRefresh: (e: CustomEvent<RefresherEventDetail>) => void;
}

const measureContainer = document.getElementById('measure-layer');

export const RadabankCardWithOperations: FC<RadabankCardWithOperationsProps> = ({
	isPaymentAllowed,
	isFrozen,
	onRefresh,
}) => {
	const ref = useRef<HTMLDivElement>(null);

	const cardData = useAppSelector(getRadabankCurrentCardSelector);
	const isCardBlurred = useAppSelector(getIsCurrentCardBlurredSelector);

	const { dimensions, elementRef } = useMeasureNonRenderedElement<HTMLDivElement>([cardData]);

	return (
		<>
			{measureContainer &&
				createPortal(
					<div className={classNames(styles.content)} ref={elementRef}>
						<RadabankCardsSlider initialMounted />
						<div className={styles.operations}>
							<RadabankOperationsMenu isDisabled={!isPaymentAllowed} />
						</div>
					</div>,
					measureContainer
				)}
			{!!dimensions.height && (
				<IonContent style={{ minHeight: dimensions.height, height: '100%' }}>
					<IonRefresher pullMin={80} slot="fixed" onIonRefresh={onRefresh}>
						<IonRefresherContent />
					</IonRefresher>
					<div className={classNames(styles.content)} ref={ref}>
						<RadabankCardsSlider />
						<div
							className={classNames(styles.operations, {
								[styles.blocked]: isFrozen || isCardBlurred,
							})}
						>
							<RadabankOperationsMenu isDisabled={!isPaymentAllowed || isCardBlurred} />
						</div>
					</div>
				</IonContent>
			)}
		</>
	);
};
