import { FC } from 'react';
import { getMaskedPhone } from 'utils/helpers';
import { AuthContentHeader } from '../AuthContentHeader';
import { OtpInput } from './OtpInput';
import { CountdownTimer } from './Timer';
import styles from './index.module.scss';

interface ConfirmOTPCodeProps {
	phone: string;
	value: string;
	onChange: (value: string) => void;
	isValid: boolean;
	onResendCode?: () => void;
	length?: number;
	title?: string;
	onSubmit?: () => void;
	isShouldFocus?: boolean;
}

export const ConfirmOTPCode: FC<ConfirmOTPCodeProps> = ({
	onChange,
	value,
	isValid,
	onResendCode,
	phone,
	length,
	title = 'Введіть код, надісланий на ваш номер телефону',
	onSubmit,
	isShouldFocus = true,
}) => {
	const handleChange = (value: string): void => {
		onChange(value);
	};

	return (
		<>
			<AuthContentHeader
				title={title}
				description={
					<span className={styles.description}>
						Код прийде на ваш номер телефону
						<span className={styles.description__phone}>{getMaskedPhone(phone)}</span>
					</span>
				}
			/>
			<OtpInput
				isShouldFocus={isShouldFocus}
				onEnterPress={onSubmit}
				value={value}
				length={length}
				onChange={handleChange}
				isValid={isValid}
			/>
			{!!onResendCode && <CountdownTimer onResendCode={onResendCode} />}
		</>
	);
};
