import { FC } from 'react';
import { Capacitor } from '@capacitor/core';
import { getRadabankCardsSelector, getSavedCardsWithoutRadabankSelector, useAppSelector } from 'store';
import { CustomModal } from 'components/shared';
import { getPaymentMethodDisabledMessage } from 'utils/helpers';
import { ICardListItemResponse, PaymentMethods } from 'utils/types';
import { PayByAnotherCardButton } from '../AnotherCardButton';
import { PaymentMethodCardItem } from '../PaymentMethodCardItem';
import styles from './index.module.scss';

type ChoosePaymentMethodModalProps = {
	isOpen: boolean;
	onClose: () => void;
	activeMethod: ICardListItemResponse;
	onSelectMethod: (method: ICardListItemResponse) => void;
	allowedMethods: Partial<PaymentMethods>;
	feeAmount: number;
	feeAmountHostCard: number;
	isLoading: boolean;
	onPayByAnotherCard?: () => void;
};

export const ChoosePaymentMethodModal: FC<ChoosePaymentMethodModalProps> = ({
	isOpen,
	onClose,
	activeMethod,
	onSelectMethod,
	allowedMethods,
	feeAmount,
	feeAmountHostCard,
	onPayByAnotherCard,
	isLoading,
}) => {
	const radabankCards = useAppSelector(getRadabankCardsSelector);
	const savedCards = useAppSelector(getSavedCardsWithoutRadabankSelector);

	const isWebPlatform = Capacitor.getPlatform() === 'web';

	const handleSelectCard = (card: ICardListItemResponse) => {
		onSelectMethod(card);
		onClose();
	};

	return (
		<CustomModal
			title="Оберіть картку для сплати"
			className={styles.modal}
			isOpen={isOpen}
			showBackdrop
			onClose={onClose}
		>
			<div className={styles.content}>
				{allowedMethods.isRadaPay &&
					radabankCards.map((item) => (
						<PaymentMethodCardItem
							key={item.cardId}
							item={item}
							isActive={activeMethod.cardId === item.cardId}
							disabled={isWebPlatform || !allowedMethods.isRadaPay || isLoading}
							onChooseCard={handleSelectCard}
							disabledMessage={getPaymentMethodDisabledMessage(true, allowedMethods, item.cardName)}
							fee={feeAmountHostCard}
						/>
					))}
				{allowedMethods.isOtherPay &&
					savedCards.map((item) => {
						return (
							<PaymentMethodCardItem
								isActive={activeMethod.cardId === item.cardId}
								key={item.cardId}
								item={item}
								onChooseCard={handleSelectCard}
								fee={feeAmount}
							/>
						);
					})}
				{!!allowedMethods.isOtherPay && !!onPayByAnotherCard && (
					<div className={styles['another-card']}>
						<PayByAnotherCardButton fee={feeAmount} onClick={onPayByAnotherCard} disabled={isLoading} />
					</div>
				)}
			</div>
		</CustomModal>
	);
};
