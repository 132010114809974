import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Capacitor } from '@capacitor/core';
import { GooglePay } from '@fresha/capacitor-plugin-googlepay';
import { PaymentData, PaymentDataRequest } from '@fresha/capacitor-plugin-googlepay/dist/types';
import GooglePayButton from '@google-pay/button-react';
import { Tooltip, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import {
	getIsUserHasHostCard,
	getSavedCardsWithoutRadabankSelector,
	getSortedCardsSelector,
} from 'store/cards/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { CustomButton, CustomModal } from 'components/shared';
import {
	DEFAULT_GOOGLE_PAY_CONFIG,
	DEFAULT_GOOGLE_PAY_DEFAULT_TRANSACTION_INFO,
	FIREBASE_EVENT_ANALYTICS_BUTTON,
} from 'utils/constants';
import { PAY_TOKEN_TYPE, PAYMENT_PROVIDER, ROUTER_URL } from 'utils/enums';
import { getAmountTitle, getErrorMessage, getPaymentMethodDisabledMessage } from 'utils/helpers';
import { useDevice, useOpen } from 'utils/hooks';
import { FirebaseAnalytics, HostCardMessageService } from 'utils/services';
import { ICardListItemResponse, IDefaultModalProps, IErrorResponse, PaymentMethods, StringOrNull } from 'utils/types';
import { PaymentActiveMethod } from './ActiveMethod';
import { PayByAnotherCardButton } from './AnotherCardButton';
import { ApplePayButton } from './ApplePayButton';
import { ChoosePaymentMethodModal } from './ChooseMethodModal';
import { PaymentMethodFeeWrapper } from './FeeWrapper';
import styles from './index.module.scss';

interface PaymentMethodModalProps extends IDefaultModalProps {
	totalAmount: number;
	totalFeeAmount?: number;
	totalHostCardFeeAmount?: number;
	isShowPaymentData?: boolean;
	onPayByCard: (cardId: string, provider: StringOrNull) => Promise<void>;
	onPayByAnotherCard?: () => Promise<void>;
	onPayByWallet: (token: string, payTokenType: PAY_TOKEN_TYPE) => Promise<void>;
	onBeforeGooglePay?: () => void;
	pageName: string;
	allowedMethods: Partial<PaymentMethods>;
}

export const PaymentMethodModal: FC<PaymentMethodModalProps> = ({
	totalAmount,
	onPayByCard,
	onPayByAnotherCard,
	isShowPaymentData,
	onPayByWallet,
	totalFeeAmount = 0,
	onBeforeGooglePay,
	pageName,
	allowedMethods,
	totalHostCardFeeAmount = 0,
	...props
}) => {
	const dispatch = useAppDispatch();
	const history = useHistory();

	const { isOpen, handleOpen, handleClose } = useOpen();
	const { isMobile } = useDevice();

	const userCards = useAppSelector(getSortedCardsSelector);
	const savedCards = useAppSelector(getSavedCardsWithoutRadabankSelector);
	const isUserHasHostCard = useAppSelector(getIsUserHasHostCard);

	const [activeHostCardMessage, setActiveHostCardMessage] = useState({ text: '', index: 0 });
	const [activeMethod, setActiveMethod] = useState<ICardListItemResponse | null>(() => {
		return userCards?.[0] || null;
	});
	const [isLoading, setIsLoading] = useState(false);

	const totalPrice = useMemo(() => (totalAmount + totalFeeAmount).toFixed(2), [totalAmount, totalFeeAmount]);

	const googlePayConfig = useMemo(
		(): PaymentDataRequest | google.payments.api.PaymentDataRequest => ({
			...DEFAULT_GOOGLE_PAY_CONFIG,
			transactionInfo: {
				...DEFAULT_GOOGLE_PAY_DEFAULT_TRANSACTION_INFO,
				totalPrice,
			},
		}),
		[totalPrice]
	);

	useEffect(() => {
		if (Capacitor.isNativePlatform()) {
			GooglePay.initialize({ environment: 'PRODUCTION' }).catch((err) => {
				console.error(err);
			});
		}
		handleUpdateActiveHostCardMessage();
	}, []);

	const handleUpdateActiveHostCardMessage = async () => {
		try {
			const activeMessageIndex = await HostCardMessageService.getStoredMessageIndex();
			const activeMessage = await HostCardMessageService.getActiveMessage();

			setActiveHostCardMessage({ index: activeMessageIndex + 1, text: activeMessage });
		} catch (error) {
			console.error('handleUpdateActiveHostCardMessage error:', error);
		}
	};

	const handlePayByWallet = (data: PaymentData): void => {
		onPayByWallet(btoa(data.paymentMethodData.tokenizationData.token), PAY_TOKEN_TYPE.GOOGLE);
	};

	const handlePayByApple = async (token: string, payTokenType: PAY_TOKEN_TYPE) => {
		FirebaseAnalytics.logEvent(
			pageName,
			`${FIREBASE_EVENT_ANALYTICS_BUTTON.PAY_BY_APPLE}v${activeHostCardMessage.index}`
		);
		onPayByWallet(token, payTokenType);
	};

	const handleClickPayByGoogle = async (event: Event) => {
		try {
			onBeforeGooglePay?.();
			FirebaseAnalytics.logEvent(
				pageName,
				`${FIREBASE_EVENT_ANALYTICS_BUTTON.PAY_BY_GOOGLE}v${activeHostCardMessage.index}`
			);

			if (Capacitor.isNativePlatform()) {
				event.preventDefault();
				const isReadyToPay = await GooglePay.isReadyToPay(googlePayConfig);
				if (isReadyToPay.result) {
					const data = await GooglePay.loadPaymentData(googlePayConfig as PaymentDataRequest);
					handlePayByWallet(data);
				}
			}
		} catch (error) {
			event.preventDefault();
			const errorMessage = getErrorMessage(error);
			if (errorMessage !== 'Method not implemented.') {
				dispatch(showToast({ message: errorMessage }));
			}
		}
	};

	const handlePayByAnotherCard = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				pageName,
				`${FIREBASE_EVENT_ANALYTICS_BUTTON.PAY_BY_OTHER_CARD}v${activeHostCardMessage.index}`
			);

			if (onPayByAnotherCard) {
				setIsLoading(true);
				await onPayByAnotherCard();
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handlePayByCard = async ({ cardId, provider }: ICardListItemResponse): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				pageName,
				`${FIREBASE_EVENT_ANALYTICS_BUTTON.PAY_BY_CARD}v${activeHostCardMessage.index}`
			);
			setIsLoading(true);
			await onPayByCard(cardId, provider);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleGooglePayError = (error: Error | google.payments.api.PaymentsError) => {
		console.error(getErrorMessage(error as AxiosError<IErrorResponse>));
	};

	const handleChangeActiveMethod = (method: ICardListItemResponse) => {
		setActiveMethod(method);
	};

	const handleSetAvailableMethod = () => {
		if (allowedMethods.isRadaPay) {
			setActiveMethod(userCards?.[0] ?? null);
			return;
		}

		if (allowedMethods.isOtherPay) {
			setActiveMethod(savedCards?.[0] ?? null);
		}
	};

	const handleConfirmPayment = () => {
		if (!activeMethod) return;
		handlePayByCard(activeMethod);
	};

	const handleClickCreateCard = (): void => {
		FirebaseAnalytics.logEvent(
			pageName,
			`${FIREBASE_EVENT_ANALYTICS_BUTTON.CREATE_CARD_RADABANK}v${activeHostCardMessage.index}`
		);
		if (Capacitor.isNativePlatform()) {
			history.push(ROUTER_URL.RADABANK_CARD);
		} else {
			history.push(ROUTER_URL.PROFILE, { initialSlide: 1 });
		}
	};

	const isWebPlatform = Capacitor.getPlatform() === 'web';

	const isRadabankCardActive = activeMethod?.provider === PAYMENT_PROVIDER.RADABANK;

	const isUserHasCards = !!userCards.length;

	const disabledHintMessage = useMemo(
		() =>
			activeMethod
				? getPaymentMethodDisabledMessage(isRadabankCardActive, allowedMethods, activeMethod?.cardName || '')
				: '',
		[activeMethod, allowedMethods]
	);

	const { isOpen: isTooltipOpen, handleOpen: handleOpenTooltip, handleClose: handleCloseTooltip } = useOpen();

	const handleClickDisabledTooltip = () => {
		if (!disabledHintMessage) return;
		handleOpenTooltip();
	};

	const isAvailableCardSelected =
		!!activeMethod &&
		((isRadabankCardActive && !!allowedMethods.isRadaPay) || (!isRadabankCardActive && !!allowedMethods.isOtherPay));

	const isOnlyRadaPayAllowed = allowedMethods.isRadaPay && !allowedMethods.isOtherPay;

	return (
		<CustomModal
			{...props}
			onWillPresent={handleSetAvailableMethod}
			className={classNames(styles.modal, { [styles.hide]: isOpen, [styles.mobile]: isMobile })}
			title="Оберіть спосіб оплати"
		>
			<div className={styles.content}>
				{isShowPaymentData && isAvailableCardSelected && (
					<div className={styles.detail__list}>
						<div className={styles.detail}>
							<Typography fontWeight={700} variant="body1">
								Сума
							</Typography>
							<Typography fontWeight={700} variant="body1" whiteSpace="nowrap">
								{getAmountTitle(totalAmount)}
							</Typography>
						</div>
					</div>
				)}
				{allowedMethods.isOtherPay &&
					Capacitor.getPlatform() !== 'android' &&
					(window.ApplePaySession || Capacitor.getPlatform() === 'ios') && (
						<PaymentMethodFeeWrapper className={styles.apple__fee} fee={totalFeeAmount}>
							<ApplePayButton onSubmit={handlePayByApple} amount={totalPrice} />
						</PaymentMethodFeeWrapper>
					)}
				{allowedMethods.isOtherPay && (Capacitor.getPlatform() === 'web' || Capacitor.getPlatform() === 'android') && (
					<PaymentMethodFeeWrapper fee={totalFeeAmount}>
						<GooglePayButton
							buttonColor="black"
							buttonRadius={40}
							onClick={handleClickPayByGoogle}
							className={styles.button_gPay}
							environment="PRODUCTION"
							buttonType="plain"
							paymentRequest={googlePayConfig as google.payments.api.PaymentDataRequest}
							onLoadPaymentData={handlePayByWallet}
							onError={handleGooglePayError}
						/>
					</PaymentMethodFeeWrapper>
				)}
				{allowedMethods.isOtherPay && (
					<Typography className={styles.divider} component="span">
						або
					</Typography>
				)}
				{isUserHasCards && activeMethod && (
					<ChoosePaymentMethodModal
						onPayByAnotherCard={onPayByAnotherCard ? handlePayByAnotherCard : undefined}
						isLoading={isLoading}
						activeMethod={activeMethod}
						onSelectMethod={handleChangeActiveMethod}
						feeAmount={totalFeeAmount}
						feeAmountHostCard={totalHostCardFeeAmount}
						allowedMethods={allowedMethods}
						isOpen={isOpen}
						onClose={handleClose}
					/>
				)}
				{(!isUserHasCards || !savedCards.length) && !!allowedMethods.isOtherPay && onPayByAnotherCard && (
					<PayByAnotherCardButton fee={totalFeeAmount} onClick={handlePayByAnotherCard} disabled={isLoading} />
				)}

				{isAvailableCardSelected && (
					<PaymentActiveMethod
						activeMethod={activeMethod}
						onOpen={handleOpen}
						fee={isRadabankCardActive ? totalHostCardFeeAmount : totalFeeAmount}
					/>
				)}
				{isUserHasCards && isAvailableCardSelected && (
					<Tooltip
						open={isWebPlatform ? undefined : !!disabledHintMessage && isTooltipOpen}
						onClose={handleCloseTooltip}
						disableInteractive
						title={disabledHintMessage}
						leaveTouchDelay={!isWebPlatform ? 4000 : undefined}
					>
						<div onClick={handleClickDisabledTooltip}>
							<CustomButton
								onClick={handleConfirmPayment}
								disabled={!!disabledHintMessage || isLoading}
								isLoading={isLoading}
								label="Сплатити"
							/>
						</div>
					</Tooltip>
				)}
				{!isRadabankCardActive && (
					<div>
						{isOnlyRadaPayAllowed && (
							<Typography textAlign="center">
								На жаль, деякі з обраних сервісів, можуть бути сплачені лише карткою HOSTCARD
							</Typography>
						)}

						{!isOnlyRadaPayAllowed && (
							<Typography variant="body2" textAlign="center">
								{activeHostCardMessage.text} <strong>{getAmountTitle(totalHostCardFeeAmount)}</strong>
							</Typography>
						)}
						{!isUserHasHostCard && (
							<Typography className={styles['create-host-card']} onClick={handleClickCreateCard} textAlign="center">
								Оформити картку
							</Typography>
						)}
					</div>
				)}
			</div>
		</CustomModal>
	);
};
