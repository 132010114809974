import { FC } from 'react';
import classNames from 'classnames';
import { CardPreview } from 'components/shared';
import { Checkbox } from 'components/shared/Checkbox';
import { ICardListItemResponse } from 'utils/types';
import { PaymentMethodFeeWrapper } from '../../FeeWrapper';
import styles from './index.module.scss';

interface PaymentMethodCardItemWithFeeContentProps {
	item: ICardListItemResponse;
	fee?: number;
	isActive?: boolean;
	className?: string;
}

export const PaymentMethodCardItemWithFeeContent: FC<PaymentMethodCardItemWithFeeContentProps> = ({
	item,
	fee = 0,
	isActive = false,
	className = '',
}) => {
	return (
		<PaymentMethodFeeWrapper className={classNames(styles.fee__wrapper, className)} fee={fee}>
			<CardPreview icon={isActive && <Checkbox checked />} data={item} />
		</PaymentMethodFeeWrapper>
	);
};
