import { FC } from 'react';
import { MenuItem, Tooltip } from '@mui/material';
import { ReactComponent as DownIcon } from 'assets/icons/arrow-down.svg';
import { ICardListItemResponse } from 'utils/types';
import { PaymentMethodCardItemWithFeeContent } from '../PaymentMethodCardItem/FeeItem';
import styles from './index.module.scss';

interface PaymentActiveMethodProps {
	activeMethod: ICardListItemResponse;
	disabled?: boolean;
	disabledMessage?: string;
	fee?: number;
	isActive?: boolean;
	onOpen: () => void;
}

export const PaymentActiveMethod: FC<PaymentActiveMethodProps> = ({
	activeMethod,
	disabledMessage = '',
	disabled,
	onOpen,
	...props
}) => {
	return (
		<MenuItem onClick={onOpen} className={styles.wrapper}>
			<Tooltip disableInteractive title={disabledMessage}>
				<div className={styles.tooltip}>
					<PaymentMethodCardItemWithFeeContent className={styles.content} item={activeMethod} {...props} />
					<DownIcon className={styles.arrow} />
				</div>
			</Tooltip>
		</MenuItem>
	);
};
