import { Capacitor } from '@capacitor/core';
import { PaymentMethods } from 'utils/types';

export const getPaymentMethodDisabledMessage = (
	isRadabankCardActive: boolean,
	allowedMethods: Partial<PaymentMethods>,
	cardName: string
) => {
	const isWebPlatform = Capacitor.getPlatform() === 'web';

	switch (true) {
		case isWebPlatform && isRadabankCardActive:
			return getCardUnavailableInWebMessage(cardName);
		case (!allowedMethods.isOtherPay && !isRadabankCardActive) || (!allowedMethods.isRadaPay && isRadabankCardActive):
			return 'Неможливо сплатити обраною карткою. Оберіть інший спосіб оплати';
		default:
			return '';
	}
};

export const getCardUnavailableInWebMessage = (cardName: string) =>
	`Оплата карткою ${cardName} з метою безпеки доступна лише в мобільному додатку`;
