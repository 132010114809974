import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import styles from './index.module.scss'

interface FooterColumnProps {
	title: string;
}

export const FooterColumn: FC<PropsWithChildren<FooterColumnProps>> = ({ title, children }) => (
	<div className={styles.column}>
		<Typography color="#FFC89A" fontWeight={700}>
			{title}
		</Typography>
		{children}
	</div>
);
