import { FC, HTMLProps } from 'react';
import classNames from 'classnames';
import { getIsCurrentCardBlockedSelector, useAppSelector } from 'store';
import styles from './index.module.scss';

type RadabankCardPreviewProps = HTMLProps<HTMLDivElement> & {
	isMultiCard?: boolean;
	isShowBlockedOpacity?: boolean;
}

export const RadabankCardPreview: FC<RadabankCardPreviewProps> = ({
	className,
	isMultiCard = false,
	isShowBlockedOpacity = true,
	children,
	...props
}) => {
	const isCardBlocked = useAppSelector(getIsCurrentCardBlockedSelector);

	return (
		<div
			{...props}
			className={classNames(styles.wrapper, 'radabank-card-preview', className, {
				[styles.blocked]: isShowBlockedOpacity && isCardBlocked,
				[styles.multicard]: isMultiCard,
			})}
		>
			{children}
		</div>
	);
};
