import { FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import {
	getRadabankCurrentCardSelector,
	getRadabankInternalCardsList,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { RadabankOnboardingContent } from 'components/RadabankCard';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { RADABANK_NPOSHTA_CARD_STATUS, ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

interface RadabankOrderPlasticCardStatusContentProps {
	statusId: string;
}

const OrderPlasticCardStatusesMap: Record<string, { title: string; description: string }> = {
	[RADABANK_NPOSHTA_CARD_STATUS.DISABLED]: {
		title: 'Перевипуск неможливий',
		description: 'Зверніться у підтримку',
	},
	[RADABANK_NPOSHTA_CARD_STATUS.IN_PROGRESS]: {
		title: 'Картка виготовляється',
		description: 'Очікуйте на повідомлення',
	},
	[RADABANK_NPOSHTA_CARD_STATUS.DELIVERING]: {
		title: 'Картка вже прямує до вас',
		description: 'Перевірте додаток Нової Пошти',
	},
	[RADABANK_NPOSHTA_CARD_STATUS.CAN_ACTIVATE]: {
		title: 'Картка отримана!',
		description: 'Тепер ви можете її активувати',
	},
	[RADABANK_NPOSHTA_CARD_STATUS.ACTIVATED]: {
		title: 'Картку скоро буде активовано',
		description: 'Процес активації може зайняти деякий час',
	},
	default: {
		title: '',
		description: '',
	},
};

export const RadabankOrderPlasticCardStatusContent: FC<RadabankOrderPlasticCardStatusContentProps> = ({ statusId }) => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const handleClickBack = useGoBackRadabank();

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [isActivated, setIsActivated] = useState(false);

	const { title, description } = useMemo(() => {
		if (isActivated) return OrderPlasticCardStatusesMap[RADABANK_NPOSHTA_CARD_STATUS.ACTIVATED];
		return OrderPlasticCardStatusesMap?.[statusId] || OrderPlasticCardStatusesMap.default;
	}, [statusId, isActivated]);

	const handleClickHome = async () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ORDER_PLASTIC_CARD_STATUS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_HOME
		);

		if (isActivated) {
			try {
				dispatch(setAppLoading(true));
				await dispatch(
					getRadabankInternalCardsList({
						userId: (profile as IUserProfileResponse).userId,
						shouldSetCurrentCard: false,
					})
				);
			} catch (error) {
				dispatch(showToast({ message: getErrorMessage(error) }));
			} finally {
				dispatch(setAppLoading(false));
			}
		}
		history.replace(ROUTER_URL.RADABANK_CARD);
	};

	const handleClickActivate = async () => {
		try {
			if (currentCard) {
				dispatch(setAppLoading(true));
				await RadabankService.activateNPoshtaInternalCard({
					cardid: currentCard.id,
					userId: (profile as IUserProfileResponse).userId,
				});
				setIsActivated(true);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.ORDER_PLASTIC_CARD_STATUS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	const isShowActivationButton = statusId === RADABANK_NPOSHTA_CARD_STATUS.CAN_ACTIVATE && !isActivated;

	return (
		<div className="host-card-page-color-primary">
			<ArrowBackButton onClick={handleGoBack} color="white" />
			<RadabankOnboardingContent title={title} description={description} />
			{isShowActivationButton && <CustomButton label="Активувати" color="clear" onClick={handleClickActivate} />}
			<CustomButton
				className={classNames(styles.button_main, {
					[styles.button_main_margin]: isShowActivationButton,
				})}
				label="На головну"
				color="secondary"
				onClick={handleClickHome}
			/>
		</div>
	);
};
