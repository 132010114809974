import { FC, FocusEvent, FormEvent, KeyboardEvent, useEffect, useMemo, useRef } from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { DIGIT } from 'utils/constants';
import { focusToNextInput, focusToPrevInput } from './helpers';
import styles from './index.module.scss';

export interface OtpInputProps {
	value: string;
	length?: number;
	onChange: (value: string) => void;
	isValid: boolean;
	onEnterPress?: () => void;
	isShouldFocus?: boolean;
}

export const OtpInput: FC<OtpInputProps> = ({ value, length = 4, onChange, onEnterPress, isValid, isShouldFocus = true }) => {
	const firstInput = useRef<HTMLInputElement>(null);

	const valueItems = useMemo(() => {
		const valueArray = value.split('');

		return Array(length)
			.fill('')
			.map((_, i) => {
				const char = valueArray[i];

				return DIGIT.test(char) ? char : '';
			});
	}, [value, length]);

	useEffect(() => {
		if(!firstInput.current) return;

		if (isShouldFocus) {
			firstInput.current.focus();
		} else {
			firstInput.current.blur();
		}
	}, [isShouldFocus]);

	const handleChangeInput = (e: FormEvent<HTMLInputElement>, idx: number) => {
		const targetValue = e.currentTarget.value;

		const isTargetValueDigit = DIGIT.test(targetValue);

		if (!isTargetValueDigit && !targetValue) {
			onChange(value.slice(0, value.length - 1));
			return;
		}

		const nextInputEl = e.currentTarget.nextElementSibling as HTMLInputElement | null;

		// only delete digit if next input element has no value
		if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') return;

		if (targetValue.length === 1) {
			// typing case
			const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);

			onChange(newValue);

			if (!isTargetValueDigit) {
				focusToPrevInput(e.currentTarget);
				return;
			}

			focusToNextInput(e.currentTarget);
		} else {
			onChange(targetValue);

			// paste case
			if (targetValue.length === length) {
				e.currentTarget.blur();
			}
		}
	};

	const inputOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const target = e.currentTarget as HTMLInputElement;
		const { key } = e;

		if (key === 'Enter') {
			onEnterPress?.();
			return;
		}

		if (key === 'ArrowRight' || key === 'ArrowDown') {
			e.preventDefault();
			return focusToNextInput(target);
		}

		if (key === 'ArrowLeft' || key === 'ArrowUp') {
			e.preventDefault();
			return focusToPrevInput(target);
		}

		// keep the selection range position
		// if the same digit was typed
		target.setSelectionRange(0, target.value.length);

		if (e.key !== 'Backspace' || target.value !== '') return;

		focusToPrevInput(target);
	};

	const inputOnFocus = (e: FocusEvent<HTMLInputElement>) => {
		const { target } = e;

		// keep focusing back until previous input
		// element has value
		const prevInputEl = target.previousElementSibling as HTMLInputElement | null;

		if (prevInputEl && prevInputEl.value === '') return prevInputEl.focus();

		target.setSelectionRange(0, target.value.length);
	};

	return (
		<div className={styles.wrapper}>
			<div className={classNames(styles.otp__wrapper, { [styles.otp__wrapper_invalid]: !isValid })}>
				{valueItems.map((digit, idx) => (
					<input
						key={idx}
						ref={!idx ? firstInput : undefined}
						className={styles.input}
						type="text"
						inputMode="numeric"
						autoComplete="one-time-code"
						pattern="\d{1}"
						maxLength={length}
						value={digit}
						onKeyDown={inputOnKeyDown}
						onFocus={inputOnFocus}
						onInput={(e) => handleChangeInput(e, idx)}
					/>
				))}
			</div>
			{!isValid && (
				<Typography variant="caption" color="--color-error-500" textAlign="center" marginTop="var(--spacing-2)">
					Невірний код підтвердження
				</Typography>
			)}
		</div>
	);
};
