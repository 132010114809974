export const ENV_VARIABLE = {
	PORTMONE_PROVIDER_NAFTOGAZ_ID: process.env.REACT_APP_PORTMONE_PROVIDER_NAFTOGAZ_ID,
	PORTMONE_PROVIDER_NAFTOGAZ_CODE: process.env.REACT_APP_PORTMONE_PROVIDER_NAFTOGAZ_CODE,
	RB_REGION_KYIV_ID: process.env.REACT_APP_RADABANK_REGION_KYIV_ID,
	RB_DISTRICT_KYIV_ID: process.env.REACT_APP_RADABANK_DISTRICT_KYIV_ID,
	RB_CITYTYPE_CITY_ID: process.env.REACT_APP_RADABANK_CITYTYPE_CITY_ID,
	RB_CITY_KYIV_ID: process.env.REACT_APP_RADABANK_CITY_KYIV_ID,
	RB_NBU_LIMITS_DOC: process.env.REACT_APP_RADABANK_NBU_LIMITS_DOC,
	RB_HOSTPAY_IBAN: process.env.REACT_APP_RADABANK_HOSTPAY_IBAN,
	CARD_BANNER_QR_CODE_URL: process.env.REACT_APP_CARD_BANNER_QR_CODE_URL,
};
