import { ComponentProps, FC, useState } from 'react';
import { IonModal } from '@ionic/react';
import classNames from 'classnames';
import { useDevice } from 'utils/hooks';
import { IDefaultModalProps } from 'utils/types';
import { ModalHeader } from '../ModalHeader';
import styles from './index.module.scss';

interface CustomModalProps extends ComponentProps<typeof IonModal>, IDefaultModalProps {
	isMobileView?: boolean;
	classNameHeader?: string;
	isFullHeight?: boolean;
	isCloseHeader?: boolean;
	title?: string;
}

export const CustomModal: FC<CustomModalProps> = ({
	children,
	onClose,
	title,
	breakpoints,
	initialBreakpoint,
	className,
	classNameHeader = '',
	isMobileView = true,
	isFullHeight = false,
	isCloseHeader = true,
	onDidDismiss,
	onWillPresent,
	...props
}) => {
	const { isMobile } = useDevice();
	const [isHideBackdrop, setIsHideBackdrop] = useState(false);

	const handleHideBackdrop = () => {
		setIsHideBackdrop(true);
	};

	const handleShowBackdrop = () => {
		setIsHideBackdrop(false);
	};

	const handleDidDismiss = (event: any) => {
		onDidDismiss?.(event);
		onClose();
	}

	const handleWillPresent = (event: any) => {
		onWillPresent?.(event);
		handleShowBackdrop();
	}

	return (
		<IonModal
			onWillDismiss={handleHideBackdrop}
			onWillPresent={handleWillPresent}
			{...props}
			onDidDismiss={handleDidDismiss}
			// If we need to close a full height modal by swipe to bottom, we need change undefined to [1]
			// eslint-disable-next-line no-nested-ternary
			breakpoints={isMobile && isMobileView ? (isFullHeight ? undefined : breakpoints || [0, 1]) : undefined}
			initialBreakpoint={isMobile && isMobileView ? initialBreakpoint || 1 : undefined}
			className={classNames(className, {
				'full-height-modal': isFullHeight,
				// In order to prevent flickering on IOS
				[styles['hide-backdrop']]: isHideBackdrop && !isFullHeight && isMobileView,
			})}
		>
			{!isCloseHeader ? (
				children
			) : (
				<div>
					<ModalHeader
						className={classNameHeader}
						title={title}
						isFullHeight={isMobile && isFullHeight}
						onClose={onClose}
					/>
					{children}
				</div>
			)}
		</IonModal>
	);
};
