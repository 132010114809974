import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Typography } from '@mui/material';
import {
	// getIsCurrentCardBlockedSelector,
	// getIsCurrentCardBlurredSelector,
	getRadabankCurrentCardSelector,
	getRadabankTransactionByIdSelector,
	showToast,
	useAppSelector,
} from 'store';
import { RadabankSectionHeader } from 'components/RadabankCard';
import { CustomButton } from 'components/shared';
import { ENV_VARIABLE, FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import {
	checkIsValidCardPan,
	checkIsValidIban,
	encryptData,
	getAmountTitle,
	getErrorMessage,
	getRepeatedTransactionDetails,
} from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import styles from './styles.module.scss';

export const RadabankTransactionDetailsPage: FC = () => {
	const { transactionId } = useParams<{ transactionId: string }>();

	const dispatch = useDispatch();

	const history = useHistory();

	const handleClickBack = useGoBackRadabank();

	const transaction = useAppSelector((state) => getRadabankTransactionByIdSelector(state, transactionId));
	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	// const isCurrentCardBlocked = useAppSelector(getIsCurrentCardBlockedSelector);
	// const isCurrentCardBlurred = useAppSelector(getIsCurrentCardBlurredSelector);

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.TRANSACTION_DETAILS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	const handleRepeatTransaction = async () => {
		if (!transaction) return;

		const { merchantkey } = transaction;
		try {
			const isIban = checkIsValidIban(merchantkey);

			const repeatedTransactionDetails = getRepeatedTransactionDetails(isIban, transaction);

			const encryptedTransactionDetails = encryptData(JSON.stringify(repeatedTransactionDetails));

			const tab = Number(isIban);

			const search = new URLSearchParams([
				['transaction', encryptedTransactionDetails],
				['tab', `${tab}`],
			]);

			history.push({ pathname: ROUTER_URL.RADABANK_CARD_OPERATION, search: search.toString() });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	// const handleReturnTransaction = async () => {
	// 	if (!transaction || !userId) return;

	// 	try {
	// 		const { operidret } = transaction;
	// 		const { data } = await RadabankService.getReturnTransferData({ operid: operidret, userId });

	// 		const returnedTransactionDetails = {
	// 			ibanrec: data.ibanrec,
	// 			amount: convertCoinsToUAH(data.amount),
	// 			destination: data.destination,
	// 	  }

	// 	const encryptedTransactionDetails = encryptData(JSON.stringify(returnedTransactionDetails));

	// 	// : {
	// 	// 		card: data.cardrec,
	// 	// 		amount: convertCoinsToUAH(data.amount),
	// 	// 		comment: data.destination,
	// 	//   };
	// 	} catch (error) {
	// 		dispatch(showToast({ message: getErrorMessage(error) }));
	// 	}
	// };

	if (!transaction || !currentCard) return null;

	const formattedDsTextInfo = transaction.dstextinfo.replace(/chr\(10\)|\n/g, '<br />');

	const { merchantkey } = transaction;
	// const { merchantkey, operidret, mustreturn } = transaction;

	const isValidIban = checkIsValidIban(merchantkey) && merchantkey !== ENV_VARIABLE.RB_HOSTPAY_IBAN;

	const isValidCardPan = checkIsValidCardPan(merchantkey);

	const isValidMerchantKey = merchantkey && (isValidCardPan || isValidIban);

	// const isCanReturn = mustreturn === RADABANK_BOOLEAN_VALUE.TRUE && !!operidret;

	return (
		<div className={styles.transaction}>
			<div className={styles.transaction__header}>
				<RadabankSectionHeader title="Деталі платежу" onClickBack={handleGoBack} />
				<img
					src={`${process.env.REACT_APP_RADABANK_CATEGORY_IMAGE_URL}/${transaction.merchantpicture}`}
					alt=""
					className={styles.transaction__icon}
				/>
				<Typography variant="caption" color="var(--color-neutral-500)">
					{transaction.categoryname}
				</Typography>
			</div>

			<div className={styles.transaction__date_amount}>
				<Typography color="var(--color-neutral-500)">{transaction.dateonline}</Typography>

				<Typography variant="h4" fontWeight="600">
					{getAmountTitle(transaction.amount, transaction.currencydoc)}
				</Typography>
			</div>

			<div className={styles.transaction__destination_merchant}>
				<Typography>{transaction.merchantname}</Typography>
				{formattedDsTextInfo ? (
					<Typography component="p" dangerouslySetInnerHTML={{ __html: formattedDsTextInfo }} />
				) : (
					<Typography>{transaction.destination}</Typography>
				)}
			</div>
			<div className={styles.transaction__actions}>
				{isValidMerchantKey && transaction.amount < 0 && (
					<CustomButton
						disabled
						// disabled={isCurrentCardBlocked || isCurrentCardBlurred}
						onClick={handleRepeatTransaction}
						className={styles.button}
						label="Повторити"
					/>
				)}
				{/* {transaction.amount > 0 && isCanReturn && (
					<CustomButton onClick={handleReturnTransaction} className={styles.button} label="Зворотній переказ" />
				)} */}
			</div>
		</div>
	);
};
