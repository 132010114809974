import { FC, useCallback } from 'react';
import { MenuItem } from '@mui/material';
import { ReactComponent as DownIcon } from 'assets/icons/arrow-down.svg';
import { useOpen } from 'utils/hooks';
import { IRadabankInternalCurrentCard } from 'utils/types';
import { RadabankChooseCardForTransferModal } from '../Modal';
import { RadabankCardForTransferOption } from '../Option';
import styles from './index.module.scss';

interface RadabankChooseCardForTransferModalAnchorProps {
	cardList: IRadabankInternalCurrentCard[];
	onSelectCard: (card: IRadabankInternalCurrentCard) => void;
	selectedCard: IRadabankInternalCurrentCard;
	title?: string;
}

export const RadabankChooseCardForTransferModalAnchor: FC<RadabankChooseCardForTransferModalAnchorProps> = ({
	cardList,
	selectedCard,
	onSelectCard,
	title,
}) => {
	const { isOpen, handleClose, handleOpen } = useOpen();

	const handleSelectCard = useCallback((card: IRadabankInternalCurrentCard) => {
		onSelectCard(card);
		handleClose();
	}, []);

	return (
		<>
			{selectedCard && (
				<MenuItem className={styles.anchor} onClick={handleOpen}>
					<RadabankCardForTransferOption disableRipple className={styles.preview} card={selectedCard} />
					<DownIcon className={styles.arrow} />
				</MenuItem>
			)}
			<RadabankChooseCardForTransferModal
				title={title}
				isOpen={isOpen}
				onClose={handleClose}
				cardList={cardList}
				selectedCard={selectedCard}
				onSelectCard={handleSelectCard}
			/>
		</>
	);
};
