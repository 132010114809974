import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { getIsMultiCardActiveSelector } from 'store';
import { RadabankCardPreview } from '../CardPreview';
import { RadabankCurrentCardDetails } from '../CurrentCardDetails';

type RadabankCurrentCardPreviewProps = {
	isShowCreditDetails?: boolean;
	wrapperClassName?: string;
	detailsClassName?: string;
};

export const RadabankCurrentCardPreview: FC<PropsWithChildren<RadabankCurrentCardPreviewProps>> = ({
	isShowCreditDetails,
	wrapperClassName,
	detailsClassName,
	children,
}) => {
	const isMultiCard = useSelector(getIsMultiCardActiveSelector);

	return (
		<RadabankCardPreview className={wrapperClassName} isMultiCard={isMultiCard}>
			<RadabankCurrentCardDetails isShowCreditDetails={isShowCreditDetails} className={detailsClassName} />
			{children}
		</RadabankCardPreview>
	);
};
