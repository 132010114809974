import { FC, useCallback, useMemo, useState } from 'react';
import { getIsPossibleApartmentsStepSelector, useAppSelector } from 'store';
import { AuthAddApartment, UpdateProfileData, UserAppartmentVariant } from 'components/Auth';
import { ArrowBackButton } from 'components/shared';
import Stepper from 'components/shared/Stepper';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ADD_APARTMENT_VARIANT } from 'utils/enums';
import { useAndroidBackButton } from 'utils/hooks/use-close-on-back-button';
import { FirebaseAnalytics } from 'utils/services';
import styles from './index.module.scss';

enum PROFILE_UPDATE_STEPS {
	PROFILE_DATA,
	APARTMENT_VARIANT,
	ADD_APARTMENT,
}

const STEPS = ['Особиcті дані', 'Адреса'];

export const AuthProfileUpdatePage: FC = () => {
	const [step, setStep] = useState(PROFILE_UPDATE_STEPS.PROFILE_DATA);
	const [apartmentVariant, setApartmentVariant] = useState<null | ADD_APARTMENT_VARIANT>(null);

	const isApartmentListStep = useAppSelector(getIsPossibleApartmentsStepSelector);

	const content: JSX.Element = useMemo(() => {
		switch (step) {
			case PROFILE_UPDATE_STEPS.APARTMENT_VARIANT:
				return (
					<UserAppartmentVariant
						onChange={(value: ADD_APARTMENT_VARIANT) => {
							setApartmentVariant(value);
							setStep(PROFILE_UPDATE_STEPS.ADD_APARTMENT);
						}}
						value={apartmentVariant}
						pageName={FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.UPDATE_PROFILE}
					/>
				);

			case PROFILE_UPDATE_STEPS.ADD_APARTMENT:
				return <AuthAddApartment variant={apartmentVariant} />;

			default:
				return (
					<UpdateProfileData
						onSubmit={() => {
							setApartmentVariant(ADD_APARTMENT_VARIANT.ADDRESS);
							setStep(PROFILE_UPDATE_STEPS.ADD_APARTMENT);
						}}
					/>
				);
		}
	}, [step, apartmentVariant]);

	const handleClickBack = useCallback(() => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.UPDATE_PROFILE,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		setStep(PROFILE_UPDATE_STEPS.PROFILE_DATA);
	}, [step]);

	useAndroidBackButton(handleClickBack, step);

	return (
		<>
			<div className={styles.header}>
				{step !== PROFILE_UPDATE_STEPS.PROFILE_DATA && <ArrowBackButton onClick={handleClickBack} />}
				{!isApartmentListStep && <Stepper steps={STEPS} activeStep={step} />}
			</div>
			{content}
		</>
	);
};
