import { FC } from 'react';
import { Typography } from '@mui/material';
// import { ReactComponent as Edit } from 'assets/icons/pencil.svg';
import AvatarImg from 'assets/icons/profile.svg';
import classNames from 'classnames';
import { useAppSelector } from 'store/hooks';
import { getUserAvatarSelector, getUserReducerSelector } from 'store/user-service/selectors';
// import { useOpen } from 'utils/hooks';
// import { AvatarUpdateModal } from './AvatarUpdateModal';
import styles from './index.module.scss';

interface UserInfoProps {
	editable?: boolean;
}

export const UserInfo: FC<UserInfoProps> = ({ editable = false }) => {
	const userReducer = useAppSelector(getUserReducerSelector);
	const userAvatar = useAppSelector(getUserAvatarSelector);

	// const { isOpen, handleOpen, handleClose } = useOpen();

	return (
		<>
			<div className={styles.profile}>
				<div className={styles.profile__avatar}>
					<img
						src={userAvatar || AvatarImg}
						alt="profile"
						className={classNames(styles.profile__profileIcon, !editable && styles.profile__profileIcon__small)}
					/>
				</div>

				{/* {editable && (
					<div className={styles.profile__edit} onClick={handleOpen}>
						<Edit />
					</div>
				)} */}

				{!!(userReducer.profile?.firstName || userReducer.profile?.lastName) && (
					<div className={styles.profile__username}>
						{!!userReducer.profile?.firstName && (
							<Typography variant="subtitle2">{userReducer.profile.firstName}</Typography>
						)}
						{!!userReducer.profile?.lastName && (
							<Typography variant="subtitle2">{userReducer.profile.lastName}</Typography>
						)}
					</div>
				)}
			</div>

			{/* <AvatarUpdateModal isOpen={isOpen} onClose={handleClose} /> */}
		</>
	);
};
