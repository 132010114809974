import { createSelector } from '@reduxjs/toolkit';
import { StoreType } from 'store/root';
import { RADABANK_HOSTCARD_FORMATTED_NAME } from 'utils/constants';
import { PAYMENT_PROVIDER } from 'utils/enums';
import { ICardsReducer } from 'utils/types';

export const getCardsSelector = (store: StoreType): ICardsReducer => {
	return store.cardsStore;
};

export const getRadabankCards = (store: StoreType) => {
	const cards = store.cardsStore.list;

	return cards
		.filter((card) => card.provider === PAYMENT_PROVIDER.RADABANK)
		.sort((a, b) => {
			if (a.cardName === RADABANK_HOSTCARD_FORMATTED_NAME) {
				return -1;
			}

			if (b.cardName === RADABANK_HOSTCARD_FORMATTED_NAME) {
				return 1;
			}
			return 0;
		});
};

export const getRadabankCardsSelector = createSelector(getRadabankCards, (cards) => cards);

export const getSavedCardsWithoutRadabank = (store: StoreType) => {
	const cards = store.cardsStore.list;

	return cards.filter((card) => card.provider !== PAYMENT_PROVIDER.RADABANK);
};

export const getSavedCardsWithoutRadabankSelector = createSelector(getSavedCardsWithoutRadabank, (cards) => cards);

export const getSortedCards = (store: StoreType) => {
	const radabankCards = getRadabankCardsSelector(store);
	const savedCards = getSavedCardsWithoutRadabankSelector(store);

	return [...radabankCards, ...savedCards];
};

export const getSortedCardsSelector = createSelector(getSortedCards, (cards) => cards);

export const getIsUserHasHostCard = (store: StoreType) => {
	const radabankCards = getRadabankCardsSelector(store);

	return radabankCards.some((card) => card.cardName === RADABANK_HOSTCARD_FORMATTED_NAME);
};
