import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
	changeCurrentCard,
	getRadabankCardIdSelector,
	getRadabankInternalCardsListSelector,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { RadabankCardFlip } from 'components/RadabankCard/CardFlip';
import { getErrorMessage } from 'utils/helpers';
import { useDebounce } from 'utils/hooks';
import { TypeOrNull } from 'utils/types';
import styles from './index.module.scss';

const RADABANK_CARD_SLIDE_PERCENTAGE_WIDTH = 0.92;

const RADABANK_CARD_SLIDER_GAP_IN_PX = 16;

interface RadabankCardsSliderProps {
	initialMounted?: boolean;
}

export const RadabankCardsSlider: FC<RadabankCardsSliderProps> = ({ initialMounted = false }) => {
	const dispatch = useAppDispatch();

	const radabankCardList = useAppSelector(getRadabankInternalCardsListSelector);
	const currentCardId = useAppSelector(getRadabankCardIdSelector);

	const sliderRef = useRef<HTMLDivElement>(null);
	const isDragging = useRef<TypeOrNull<boolean>>(null);

	const [activeIndex, setActiveIndex] = useState(() => {
		const initialSlide = radabankCardList.findIndex((card) => card.id === currentCardId);

		return initialSlide === -1 ? 0 : initialSlide;
	});

	const debouncedSlide = useDebounce(activeIndex, 400);

	const handleChangeActiveCard = async (index: number) => {
		try {
			const newCardId = radabankCardList[index]?.id;
			if (newCardId) {
				dispatch(changeCurrentCard(newCardId));
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	useEffect(() => {
		handleChangeActiveCard(debouncedSlide);
	}, [debouncedSlide]);

	const initialSlide = useMemo(() => {
		const initialSlide = radabankCardList.findIndex((card) => card.id === currentCardId);

		return initialSlide === -1 ? 0 : initialSlide;
	}, []);

	const handleScrollEnd = () => {
		if (!sliderRef.current || isDragging.current) return;
		const index = Math.round(
			sliderRef.current.scrollLeft /
				(sliderRef.current.clientWidth * RADABANK_CARD_SLIDE_PERCENTAGE_WIDTH + RADABANK_CARD_SLIDER_GAP_IN_PX)
		);
		setActiveIndex(index);
	};

	const handleMouseDown = () => {
		isDragging.current = true;
	};

	const handleMouseUp = () => {
		isDragging.current = false;
		handleScrollEnd();
	};

	useEffect(() => {
		if (sliderRef.current && !initialMounted) {
			requestAnimationFrame(() => {
				sliderRef.current?.scrollTo({
					left:
						(sliderRef.current.clientWidth * RADABANK_CARD_SLIDE_PERCENTAGE_WIDTH + RADABANK_CARD_SLIDER_GAP_IN_PX) *
						initialSlide,
					behavior: 'instant',
				});
			});
		}
	}, [initialSlide]);

	if (!radabankCardList.length) return;

	return (
		<div
			onScroll={handleScrollEnd}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onTouchStart={handleMouseDown}
			onTouchEnd={handleMouseUp}
			ref={sliderRef}
			style={{ '--cards-count': radabankCardList.length } as React.CSSProperties}
			className={classNames(styles.slider, {
				[styles['slider__two-cards']]: radabankCardList.length === 2,
			})}
		>
			{radabankCardList.map((card, index) => (
				<div
					className={classNames(styles.slider__item, {
						[styles['slider__item--single']]: radabankCardList.length === 1,
					})}
					key={card.id + index}
				>
					<RadabankCardFlip card={card} disabled={activeIndex !== index} />
				</div>
			))}
		</div>
	);
};
