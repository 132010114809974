import { ReactComponent as HostLogoSvg } from 'assets/icons/host-icon.svg';
import { ReactComponent as MastercardSVG } from 'assets/icons/mastercard.svg';
import { ReactComponent as RadabankSVG } from 'assets/icons/radabank.svg';
import { ReactComponent as VisaSVG } from 'assets/icons/visa.svg';
import {
	RADABANK_HOSTCARD_FORMATTED_NAME,
	RADABANK_HOSTCARD_NAME,
	RADABANK_MULTICARD_FORMATTED_NAME,
	RADABANK_MULTICARD_NAME,
} from 'utils/constants';

export const getCreditCardIcon = (name: string, panMasked: string) => {
	switch (true) {
		case name === RADABANK_MULTICARD_NAME || name === RADABANK_MULTICARD_FORMATTED_NAME:
			return RadabankSVG;
		case name === RADABANK_HOSTCARD_NAME || name === RADABANK_HOSTCARD_FORMATTED_NAME:
			return HostLogoSvg;
		case panMasked.slice(0, 1) === '4':
			return VisaSVG;
		default:
			return MastercardSVG;
	}
};
