import { FC, useMemo } from 'react';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { ProviderAccordionGroup } from 'components/shared';
import { PROVIDER_TYPE } from 'utils/enums';
import {
	IBillReceipt,
	IProviderServiceResponse,
	ISingleReceiptPayProviderServiceRequest,
	PaymentMethodsKeys,
} from 'utils/types';
import { PaymentProviderItem } from '../PaymentProviderItem';

interface ReceiptPortmoneDefaultServiceListProps {
	selectedServiceList: ISingleReceiptPayProviderServiceRequest[];
	onChangeSelectedList: (value: ISingleReceiptPayProviderServiceRequest[]) => void;
	onChangeFee: (serviceId: string | number, amount: number, feeHostCardAmount: number) => void;
	activePaymentMethodGroup: PaymentMethodsKeys;
}

export const ReceiptPortmoneDefaultServiceList: FC<ReceiptPortmoneDefaultServiceListProps> = ({
	selectedServiceList,
	onChangeSelectedList,
	onChangeFee,
	activePaymentMethodGroup,
}) => {
	const receipt: IBillReceipt = useAppSelector(getBillReceiptSelector);

	const serviceList = useMemo(() => {
		const portmoneList = receipt.data?.serviceProviderData[PROVIDER_TYPE.PORTMONE] || [];
		const hezList = receipt.data?.serviceProviderData[PROVIDER_TYPE.HEZ] || [];
		const servicesList = [...portmoneList, ...hezList];

		const payload = servicesList?.length
			? servicesList.filter((item) => item.providerAccountId).map((item) => [item])
			: [];

		return payload;
	}, [receipt.data?.id, activePaymentMethodGroup]);

	if (!receipt.data) return null;
	return (
		<ProviderAccordionGroup marginBottom="var(--spacing-3)">
			{serviceList.map((portmoneService: IProviderServiceResponse[]) => {
				const [firstProviderService = null] = portmoneService;
				return (
					<PaymentProviderItem
						activePaymentMethodGroup={activePaymentMethodGroup}
						key={`${firstProviderService?.id ?? firstProviderService?.code}`}
						serviceList={portmoneService}
						onChange={onChangeSelectedList}
						selectedServiceList={selectedServiceList}
						onChangeFee={onChangeFee}
					/>
				);
			})}
		</ProviderAccordionGroup>
	);
};
