import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { getServicesByCategory } from 'store/bill/actions';
import { resetOtherServicesList } from 'store/bill/reducer';
import { getServicesSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { Navbar } from 'components/Navbar';
import { ServiceBillRequestFields } from 'components/OtherServices';
import { PaymentMethodModal } from 'components/PaymentMethodModal';
import { CustomButton, Loader } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_PAGE, SERVICES_DEFAULT_REQUEST_BODY } from 'utils/constants';
import { LOCALE, PAY_TOKEN_TYPE, PAYMENT_PROVIDER, PROVIDER_TYPE, ROUTER_URL } from 'utils/enums';
import {
	convertCoinsToUAH,
	convertUAHToCoins,
	filterEmptyAmountBillCreateServices,
	findPortmoneProviderAmountFieldValue,
	getAmountTitle,
	getData3DS,
	getErrorMessage,
	getPortmoneServiceReturnUrl,
	getServiceBillRequestKeyCombination,
	getServiceByIdRoute,
	getServicePaymentStatusRoute,
	getServicesByCategoryRoute,
	getUserFullName,
} from 'utils/helpers';
import { useOpen } from 'utils/hooks';
import { BillService, CardService, HostCardMessageService, RadabankService } from 'utils/services';
import {
	IBillCreateRequestArrayDataItem,
	ICardRequestResponse,
	IGetServiceDataResponse,
	IOtherServicePaymentRequest,
	IOtherServiceProviderServiceFieldsData,
	IPaymentResponse,
	IServiceBillRequestData,
	IServiceBillRequestTariffItems,
	IServicesItemResponse,
	ServiceFeeEntry,
	ServiceFeeRecord,
	StringOrNull,
	TypeOrNull,
} from 'utils/types';
import styles from './index.module.scss';

export const ServiceProviderPage: FC = () => {
	const history = useHistory();

	const { serviceId, categoryId } = useParams<{ serviceId: string; categoryId: string }>();

	const dispatch = useAppDispatch();

	const { isOpen, handleOpen, handleClose } = useOpen();

	const services = useAppSelector(getServicesSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [currentService, setCurrentService] = useState<IServicesItemResponse | null>(null);
	const [currentServiceData, setCurrentServiceData] = useState<IGetServiceDataResponse | null>(null);
	const [currentServiceFeeMap, setCurrentServiceFeeMap] = useState<{ fee: number; feeHostCard: number }>({
		fee: 0,
		feeHostCard: 0,
	});
	const [totalAmount, setTotalAmount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [serviceRequestData, setServiceRequestData] = useState<IOtherServicePaymentRequest | null>(null);
	const [isShowDetails, setIsShowDetails] = useState(false);
	const [billRequestData, setBillRequestData] = useState<IServiceBillRequestData | null>(null);

	// used for multibills to pay as separate bills like billtype THIRD
	const [selectedServices, setSelectedServices] = useState<IBillCreateRequestArrayDataItem[]>([]);
	// fee for services of multibills
	const [feeList, setFeeList] = useState<TypeOrNull<ServiceFeeRecord>>(null);

	const isMultipleBillsData = Array.isArray(billRequestData);

	const getPaymentRequestData = (reqData: IOtherServicePaymentRequest): IOtherServicePaymentRequest => {
		return {
			...reqData,
			serviceProviderData: isMultipleBillsData
				? filterEmptyAmountBillCreateServices(selectedServices).map((service) => {
						const serviceFieldsDataKeyCombination = getServiceBillRequestKeyCombination(service.dataObject);
						const serviceFieldsData = serviceFieldsDataKeyCombination.reduce((acc, keyCombination) => {
							const value = (service.tariffItem?.[keyCombination.reqKeyName] as IServiceBillRequestTariffItems)?._;
							return value ? { ...acc, [keyCombination.fieldValueName]: value } : acc;
						}, {});
						return {
							code: service.services.code,
							serviceId: service.services.id,
							incomeBalance: `${convertUAHToCoins(service.tariffItem.amount)}`,
							fee: (feeList?.[service.services.id]?.fee ?? 0).toString(),
							providerType: PROVIDER_TYPE.PORTMONE,
							providerService: service.services.name,
							billId: service.tariffItem.id,
							serviceFieldsData: {
								...serviceFieldsData,
							},
						};
				  })
				: reqData.serviceProviderData.map((item) => {
					if(billRequestData && !('error' in billRequestData)) {
						return {
							...item,
							code: billRequestData.services.code,
							serviceId: billRequestData.services.id,
							providerService: billRequestData.services.name,
							billId: billRequestData.tariffItem?.id,
					  }
					}

					return item;
					}),
		};
	};

	useEffect(() => {
		return () => {
			dispatch(resetOtherServicesList());
		};
	}, []);

	useEffect(() => {
		if (!services.data.tableData.length) {
			getServices();
		} else if (!currentService) {
			const currentService = services.data.tableData.find((item: IServicesItemResponse) => item.id === serviceId);
			if (currentService) {
				setCurrentService(currentService);
			}
		}
	}, [services.data.tableData.length]);

	useEffect(() => {
		if (currentService && profile) {
			getCurrentServiceData(currentService.id, currentService.servicesContractsMap[0].contractId);

			const payload: IOtherServicePaymentRequest = {
				clientName: getUserFullName(profile),
				clientPhone: profile.phone,
				userId: profile.userId,
				returnUrl: getPortmoneServiceReturnUrl(categoryId, serviceId),
				serviceProviderData: [
					{
						code: currentService.code,
						serviceId: currentService.id,
						incomeBalance: convertUAHToCoins(0).toString(),
						fee: convertUAHToCoins(0).toString(),
						providerType: PROVIDER_TYPE.PORTMONE,
						providerService: currentService.name,
						serviceFieldsData: {},
					},
				],
			};

			setServiceRequestData(payload);
		}
	}, [profile, currentService]);

	useEffect(() => {
		if (totalAmount && serviceRequestData) {
			setServiceRequestData({
				...serviceRequestData,
				serviceProviderData: [
					{
						...serviceRequestData.serviceProviderData[0],
						incomeBalance: totalAmount.toString(),
						fee: currentServiceFeeMap.fee.toString(),
					},
				],
			});
		}
	}, [totalAmount, currentServiceFeeMap]);

	useEffect(() => {
		if (serviceRequestData) {
			setTotalAmount(+findPortmoneProviderAmountFieldValue(serviceRequestData));
		}
	}, [serviceRequestData?.serviceProviderData[0]?.serviceFieldsData]);

	const handleChangeServiceProviderData = (serviceFieldsData: IOtherServiceProviderServiceFieldsData) => {
		if (serviceRequestData) {
			const payload: IOtherServicePaymentRequest = {
				...serviceRequestData,
				serviceProviderData: [
					{
						...serviceRequestData.serviceProviderData[0],
						serviceFieldsData,
					},
				],
			};
			setServiceRequestData(payload);
		}
	};

	const getServices = async (): Promise<void> => {
		try {
			setIsLoading(true);
			await dispatch(
				getServicesByCategory({
					options: {
						...SERVICES_DEFAULT_REQUEST_BODY.options,
						serviceId: [serviceId],
						serviceCategoryId: [categoryId],
					},
					offset: 0,
					limit: 1,
					locale: LOCALE.UK,
				})
			).unwrap();
		} catch (error) {
			history.replace(ROUTER_URL.NOT_FOUND);
		} finally {
			setIsLoading(false);
		}
	};

	const getCurrentServiceData = async (serviceId: string, contractId: string): Promise<void> => {
		try {
			setIsLoading(true);

			const response: AxiosResponse<IGetServiceDataResponse> = await BillService.getServiceData({
				serviceId,
				contractId,
			});
			setCurrentServiceData(response.data);
		} catch (error) {
			history.replace(ROUTER_URL.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const getServiceFee = async (amount: number): Promise<void> => {
		try {
			const response = await BillService.getServiceFee(
				amount,
				(currentService as IServicesItemResponse).code,
				PROVIDER_TYPE.PORTMONE
			);
			setCurrentServiceFeeMap({ ...response.data });
			setTotalAmount(amount);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handlePayByCard = async (cardFromId: string, provider: StringOrNull): Promise<void> => {
		if (serviceRequestData) {
			let reqBody = { ...getPaymentRequestData(serviceRequestData), data3DS2: getData3DS(), cardFromId };

			if (provider === PAYMENT_PROVIDER.RADABANK) {
				const radabankData = await RadabankService.getInternalReqPayload({ userId: serviceRequestData.userId });
				const deviceData = await RadabankService.getReqPayload({});

				reqBody = {
					...reqBody,
					payToken: btoa(JSON.stringify({ ...radabankData, userId: undefined, ...deviceData })),
					payTokenType: PAY_TOKEN_TYPE.RADABANK,
					serviceProviderData: serviceRequestData.serviceProviderData.map((item) => ({
						...item,
						fee: isMultipleBillsData
							? (feeList?.[item.serviceId]?.fee ?? 0).toString()
							: `${currentServiceFeeMap.feeHostCard}`,
					})),
				};
			}

			const response: AxiosResponse<IPaymentResponse[]> = await BillService.otherServicePay(reqBody);
			history.push(getServicePaymentStatusRoute(categoryId, serviceId, response.data[0].billId));
		}
	};

	const handlePayByWallet = async (payToken: string, payTokenType: PAY_TOKEN_TYPE): Promise<void> => {
		try {
			if (serviceRequestData) {
				const response: AxiosResponse<IPaymentResponse[]> = await BillService.otherServicePay({
					...getPaymentRequestData(serviceRequestData),
					payToken,
					payTokenType,
					data3DS2: getData3DS(),
				});
				history.push(getServicePaymentStatusRoute(categoryId, serviceId, response.data[0].billId));
			}
		} catch (err) {
			dispatch(showToast({ message: getErrorMessage(err) }));
		}
	};

	const handlePayByAnotherCard = async (): Promise<void> => {
		if (serviceRequestData) {
			if (isMultipleBillsData && filterEmptyAmountBillCreateServices(selectedServices).length > 1) {
				const createCardResponse: AxiosResponse<ICardRequestResponse> = await CardService.createRequest({
					userId: profile?.userId as string,
					returnUrl: `${process.env.REACT_APP_URL}${getServiceByIdRoute(categoryId, serviceId)}`,
				});

				// in an app-url-litener we close the browser for ios
				if (Capacitor.isNativePlatform()) {
					await Browser.open({ url: createCardResponse.data.link });
				} else {
					window.location.href = createCardResponse.data.link;
				}
				return;
			}
			const response: AxiosResponse<IPaymentResponse[]> = await BillService.otherServicePay(
				getPaymentRequestData(serviceRequestData)
			);
			const redirectUrl = response.data[0].link;

			if (Capacitor.isNativePlatform()) {
				await Browser.open({ url: redirectUrl });
			} else {
				window.location.href = redirectUrl;
			}
		}
	};

	const handleSubmit = async (): Promise<void> => {
		try {
			handleOpen();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const getServiceDetailsData = async (): Promise<void> => {
		try {
			if (isMultipleBillsData) {
				handleOpen();
				return;
			}
			setIsLoading(true);
			const totalAmount = +findPortmoneProviderAmountFieldValue(serviceRequestData as IOtherServicePaymentRequest);
			await getServiceFee(totalAmount);
			await HostCardMessageService.setNextActiveMessage();
			setIsShowDetails(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangeFee = useCallback(({ fee, feeHostCard, serviceId }: ServiceFeeEntry) => {
		setFeeList((prev) => ({ ...prev, [serviceId]: { fee, feeHostCard } }));
	}, []);

	const handleGetBillRequestData = (value: TypeOrNull<IServiceBillRequestData>) => {
		setBillRequestData(value);

		if (Array.isArray(value)) {
			setSelectedServices(
				value.filter(
					(item) => !('error' in item) && Number(item.tariffItem.amount) > 0
				) as IBillCreateRequestArrayDataItem[]
			);
		}
	};

	const handleChangeSelectedServices = (service: IBillCreateRequestArrayDataItem) => {
		setSelectedServices((prev) => {
			const isAlreadySelected = prev.some((item) => item.tariffItem.id === service.tariffItem.id);

			if (isAlreadySelected) {
				return prev.filter((item) => item.tariffItem.id !== service.tariffItem.id);
			}

			return [...prev, service];
		});
	};

	const multiplePaymentTotalAmount = useMemo(
		() =>
			filterEmptyAmountBillCreateServices(selectedServices).reduce(
				(acc, curr) => acc + Number(curr.tariffItem?.amount),
				0
			),
		[selectedServices]
	);

	const multiplePaymentFee = useMemo(
		() =>
			filterEmptyAmountBillCreateServices(selectedServices).reduce(
				(acc, curr) => acc + Number(feeList?.[curr.services.id]?.fee),
				0
			),
		[selectedServices, feeList]
	);

	const multiplePaymentHostCardFee = useMemo(
		() =>
			filterEmptyAmountBillCreateServices(selectedServices).reduce(
				(acc, curr) => acc + Number(feeList?.[curr.services.id]?.feeHostCard),
				0
			),
		[selectedServices, feeList]
	);

	return (
		<>
			<section className={classNames('page', styles.wrapper)}>
				{!!(currentService && currentServiceData) && (
					<div className="services-tab-content">
						<ServiceBillRequestFields
							onChangeFee={handleChangeFee}
							selectedServicesToPay={selectedServices}
							serviceData={currentServiceData}
							onChange={handleChangeServiceProviderData}
							onSubmit={getServiceDetailsData}
							serviceItemData={currentService}
							onClickBack={() =>
								isShowDetails ? setIsShowDetails(false) : history.push(getServicesByCategoryRoute(categoryId))
							}
							onChangeSelectedServices={handleChangeSelectedServices}
							onGetBillRequestData={handleGetBillRequestData}
						/>
						{!!isShowDetails && (
							<>
								<div className={styles.details}>
									<div className={styles.details__item}>
										<Typography variant="subtitle1">Комісія:</Typography>
										<Typography variant="subtitle1">
											{getAmountTitle(convertCoinsToUAH(currentServiceFeeMap.fee))}
										</Typography>
									</div>
									<div className={styles.details__item}>
										<Typography variant="subtitle1">Комісія при сплаті HostCard:</Typography>
										<Typography variant="subtitle1">
											{getAmountTitle(convertCoinsToUAH(currentServiceFeeMap.feeHostCard))}
										</Typography>
									</div>
								</div>
								<CustomButton label="Сплатити" onClick={handleSubmit} disabled={!totalAmount} />
							</>
						)}
					</div>
				)}
			</section>
			{(isShowDetails || isMultipleBillsData) && (
				<PaymentMethodModal
					isShowPaymentData
					pageName={FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SERVICES}
					isOpen={isOpen}
					onClose={handleClose}
					totalHostCardFeeAmount={convertCoinsToUAH(
						isMultipleBillsData ? multiplePaymentHostCardFee : currentServiceFeeMap.feeHostCard
					)}
					totalFeeAmount={convertCoinsToUAH(isMultipleBillsData ? multiplePaymentFee : currentServiceFeeMap.fee)}
					totalAmount={isMultipleBillsData ? multiplePaymentTotalAmount : convertCoinsToUAH(totalAmount)}
					onPayByCard={handlePayByCard}
					onPayByAnotherCard={handlePayByAnotherCard}
					onPayByWallet={handlePayByWallet}
					allowedMethods={currentService?.paymentMethods ?? {}}
				/>
			)}
			<Navbar />
			{isLoading && <Loader />}
		</>
	);
};
