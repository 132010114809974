import { FC } from 'react';
import { MenuItem } from '@mui/material';
import { ICardListItemResponse } from 'utils/types';
import { PaymentMethodCardItemContent } from './Content';
import styles from './index.module.scss';

export interface PaymentMethodCardItemProps {
	item: ICardListItemResponse;
	disabled?: boolean;
	onChooseCard: (card: ICardListItemResponse) => void;
	disabledMessage?: string;
	fee?: number;
	isActive?: boolean;
}

export const PaymentMethodCardItem: FC<PaymentMethodCardItemProps> = ({
	item,
	disabled = false,
	onChooseCard,
	disabledMessage = '',
	fee = 0,
	isActive = false,
}) => {
	const handleChooseCard = () => {
		onChooseCard(item);
	};

	return (
		<MenuItem
			className={styles.item}
			onClick={handleChooseCard}
		>
			<PaymentMethodCardItemContent
				item={item}
				disabledMessage={disabled ? disabledMessage : ''}
				fee={fee}
				isActive={isActive}
			/>
		</MenuItem>
	);
};
