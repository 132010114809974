import { FC } from 'react';
import styles from './index.module.scss';

interface FooterContactsListProps {
	contactsList: string[];
}

export const FooterContactsList: FC<FooterContactsListProps> = ({ contactsList }) => {
	return (
		<ul className={styles.contacts}>
			{contactsList.map((phone, index) => (
				<li key={phone}>
					<a href={`tel:${phone.replace(/\D+/g, '')}`}>{phone}</a>
					{index < contactsList.length - 1 && <span>,</span>}
				</li>
			))}
		</ul>
	);
};
