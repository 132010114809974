import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { customHistory, RADABANK_CARD_COINS_KEYS, RADABANK_OVERDRAFT_COINS_KEYS } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { convertKeysFromCoinsToUAH, getErrorResponse, getInfoSprSelectOptions } from 'utils/helpers';
import { RadabankService } from 'utils/services';
import {
	IRadabankActivateBlurredCardRequest,
	IRadabankApprovePaymentsBasketRequest,
	IRadabankBudgetCreatePaymentRequest,
	IRadabankBudgetCreatePaymentResponse,
	IRadabankClientRequestOverRequest,
	IRadabankCreatePaymentRequest,
	IRadabankCreatePaymentResponse,
	IRadabankGetStatusPaymentByIdRequest,
	IRadabankGetStatusPaymentByIdResponse,
	IRadabankInternalCardCurrentRestDataRequest,
	IRadabankInternalCardCurrentRestDataResponse,
	IRadabankInternalClientPhoneRequest,
	IRadabankInternalClientPhoneResponse,
	IRadabankInternalCurrentRuleDeleteRequest,
	IRadabankInternalCurrentRulesRequest,
	IRadabankInternalGetOperationsListRequest,
	IRadabankInternalGetOperationsListResponse,
	IRadabankInternalListCurrentCardResponse,
	IRadabankInternalSetSmsCardRequest,
	IRadabankInternalSmsCardRequest,
	IRadabankInternalVerifyActivateCodeRequest,
	IRadabankInternalVerifyCoBrandCardRequest,
	IRadabankNPoshtaInternalCardStatusRequest,
	IRadabankTransferBetweenOwnCardsRequest,
	IRadabankTransferCreatePaymentRequest,
	IRadabankTransferCreatePaymentResponse,
} from 'utils/types';
import { openConfirmRadabankPaymentModal } from './reducer';

export const getRadabankInternalClientData = createAsyncThunk(
	'radabankCardSlice/getRadabankInternalClientData',
	async (reqBody: IRadabankInternalClientPhoneRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IRadabankInternalClientPhoneResponse> =
				await RadabankService.authCheckInternalClientPhone({
					...reqBody,
					// format should be: 0900000000, our format is 380900000000
					userphone: reqBody.userphone.slice(2),
				});
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getRadabankInternalCardsList = createAsyncThunk(
	'radabankCardSlice/getRadabankInternalCardsList',
	async (
		{ userId, shouldSetCurrentCard = true }: { userId: string; shouldSetCurrentCard?: boolean },
		{ rejectWithValue }
	) => {
		try {
			const { data }: AxiosResponse<IRadabankInternalListCurrentCardResponse> =
				await RadabankService.getListCurrentCard(userId);

			const { data: blockedCardsData }: AxiosResponse<IRadabankInternalListCurrentCardResponse> =
				await RadabankService.getListClosedCard(userId);

			const blockedCards = blockedCardsData?.cards
				? [...blockedCardsData.cards.sort((a, b) => Number(a.blocklevel) - Number(b.blocklevel))]
				: [];

			const cards = [...data.cards, ...blockedCards];

			if (!cards.length) {
				customHistory.replace(ROUTER_URL.RADABANK_CARD_INTERNAL_CREATE_CARD_SUBMITION);
			}
			return {
				...data,
				cards: cards.map((card) => convertKeysFromCoinsToUAH(card, RADABANK_CARD_COINS_KEYS)),
				shouldSetCurrentCard,
			};
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getRadabankInternalOperationsList = createAsyncThunk(
	'radabankCardSlice/getRadabankInternalOperationsList',
	async (reqBody: IRadabankInternalGetOperationsListRequest, { rejectWithValue }) => {
		try {
			const { data }: AxiosResponse<IRadabankInternalGetOperationsListResponse> =
				await RadabankService.getOperationsList(reqBody);
			const operations = data.opers.map((operation) =>
				convertKeysFromCoinsToUAH(operation, ['amountonline', 'amount', 'rest'])
			);
			return { ...data, opers: operations, cardId: reqBody.cardid };
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getRadabankStatementsByPeriod = createAsyncThunk(
	'radabankCardSlice/getRadabankStatementsByPeriod',
	async (reqBody: IRadabankInternalGetOperationsListRequest, { rejectWithValue }) => {
		try {
			const { data }: AxiosResponse<IRadabankInternalGetOperationsListResponse> =
				await RadabankService.getOperationsList(reqBody);
			const operations = data.opers.map((operation) =>
				convertKeysFromCoinsToUAH(operation, ['amountonline', 'amount', 'rest'])
			);
			return { ...data, opers: operations };
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const createRadabankPayment = createAsyncThunk(
	'radabankCardSlice/createRadabankPayment',
	async (
		{ pagePathname, ...reqBody }: { pagePathname: string } & IRadabankCreatePaymentRequest,
		{ rejectWithValue, dispatch }
	) => {
		try {
			const { data }: AxiosResponse<IRadabankCreatePaymentResponse> = await RadabankService.createCardPayment(reqBody);

			const response = await RadabankService.basketPayment({
				userId: reqBody.userId,
				id: [data.requestid],
				count: '1',
			});

			if (Number(response.data.countsms) > 0 && response.data.opersms.length) {
				dispatch(openConfirmRadabankPaymentModal({ paymentIds: response.data.opersms, pagePathname }));
			}
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const createRadabankTransferPayment = createAsyncThunk(
	'radabankCardSlice/createRadabankTransferPayment',
	async (
		{
			pagePathname,
			...reqBody
		}: { pagePathname: string } & (
			| IRadabankTransferCreatePaymentRequest
			| IRadabankBudgetCreatePaymentRequest
			| IRadabankTransferBetweenOwnCardsRequest
		),
		{ rejectWithValue, dispatch }
	) => {
		try {
			const { data }: AxiosResponse<IRadabankTransferCreatePaymentResponse | IRadabankBudgetCreatePaymentResponse> =
				await RadabankService.createTransferPayment(reqBody);

			const response = await RadabankService.basketPayment({
				userId: reqBody.userId,
				id: [data.requestid],
				count: '1',
			});

			if (Number(response.data.countsms) > 0 && response.data.opersms.length) {
				dispatch(openConfirmRadabankPaymentModal({ paymentIds: response.data.opersms, pagePathname }));
			}

			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const approveRadabankPaymentsBasket = createAsyncThunk(
	'radabankCardSlice/approveRadabankPaymentsBasket',
	async (reqBody: IRadabankApprovePaymentsBasketRequest, { rejectWithValue }) => {
		try {
			const { data } = await RadabankService.approvePaymentsBasket(reqBody);

			return data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getRadabankStatusPaymentById = createAsyncThunk(
	'radabankCardSlice/getRadabankStatusPaymentById',
	async (reqBody: IRadabankGetStatusPaymentByIdRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IRadabankGetStatusPaymentByIdResponse> = await RadabankService.getStatusPaymentById(
				reqBody
			);
			return { requestid: reqBody.requestid, status: response.data.final };
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getRadabankOverdraftData = createAsyncThunk(
	'radabankCardSlice/getRadabankOverdraftData',
	async (reqBody: IRadabankInternalCardCurrentRestDataRequest, { rejectWithValue }) => {
		try {
			const { data }: AxiosResponse<IRadabankInternalCardCurrentRestDataResponse> =
				await RadabankService.getCardCurrentRestData(reqBody);

			return convertKeysFromCoinsToUAH(data, RADABANK_OVERDRAFT_COINS_KEYS);
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateRadabankOverdraftData = createAsyncThunk(
	'radabankCardSlice/updateRadabankOverdraftData',
	async (reqBody: IRadabankClientRequestOverRequest, { rejectWithValue }) => {
		try {
			const dto = { ...reqBody, confirmSetLimit: undefined };
			await RadabankService.internalClientRequestOver(dto);
			return reqBody;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getRadabankCardCurrentRules = createAsyncThunk(
	'radabankCardSlice/getRadabankCardCurrentRules',
	async (reqBody: IRadabankInternalCurrentRulesRequest, { rejectWithValue }) => {
		try {
			const response = await RadabankService.getCurrentRules(reqBody);
			return response.data.rules;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const deleteRadabankCardCurrentRule = createAsyncThunk(
	'radabankCardSlice/deleteRadabankCardCurrentRule',
	async (reqBody: IRadabankInternalCurrentRuleDeleteRequest, { rejectWithValue }) => {
		try {
			await RadabankService.deleteCurrentRule(reqBody);
			return reqBody;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getRadabankCardSmsPhonesList = createAsyncThunk(
	'radabankCardSlice/getRadabankCardSmsPhonesList',
	async (reqBody: IRadabankInternalSmsCardRequest, { rejectWithValue }) => {
		try {
			const response = await RadabankService.getInternalSmsCard(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const setRadabankCardSmsPhone = createAsyncThunk(
	'radabankCardSlice/setRadabankCardSmsPhone',
	async (reqBody: IRadabankInternalSetSmsCardRequest, { rejectWithValue }) => {
		try {
			await RadabankService.setInternalCardSms(reqBody);
			return reqBody;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getRadabankInfoStatus = createAsyncThunk(
	'radabankCardSlice/getRadabankInfoStatus',
	async (userId: string, { rejectWithValue }) => {
		try {
			const { data } = await RadabankService.checkInfoStatus(userId);
			return data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getRadabankInfoSprData = createAsyncThunk(
	'radabankCardSlice/getRadabankInfoSprData',
	async (userId: string, { rejectWithValue }) => {
		try {
			const { data } = await RadabankService.getInfoSprData(userId);
			return getInfoSprSelectOptions(data);
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getRadabankInternalInfoSprData = createAsyncThunk(
	'radabankCardSlice/getRadabankInternalInfoSprData',
	async (userId: string, { rejectWithValue }) => {
		try {
			const { data } = await RadabankService.getInternalSprData(userId);
			return getInfoSprSelectOptions(data);
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const verifyInternalActivateCode = createAsyncThunk(
	'radabankCardSlice/verifyInternalActivateCode',
	async (dto: IRadabankInternalVerifyActivateCodeRequest, { rejectWithValue }) => {
		try {
			const { data } = await RadabankService.internalVerifyActivateCode(dto);

			return data.username;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const verifyCobrandCard = createAsyncThunk(
	'radabankCardSlice/verifyCobrandCard',
	async (dto: IRadabankInternalVerifyCoBrandCardRequest, { rejectWithValue }) => {
		try {
			const { data } = await RadabankService.verifyCoBrandCard(dto);

			return data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const checkRadabankPlasticCardStatus = createAsyncThunk(
	'radabankCardSlice/checkRadabankPlasticCardStatus',
	async (dto: IRadabankNPoshtaInternalCardStatusRequest, { rejectWithValue }) => {
		try {
			const { data } = await RadabankService.getNPoshtaInternalCardStatus(dto);

			return data.statusid;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const activateRadabankBlurredCard = createAsyncThunk(
	'radabankCardSlice/activateRadabankBlurredCard',
	async (dto: IRadabankActivateBlurredCardRequest, { rejectWithValue }) => {
		try {
			const { data } = await RadabankService.activateBlurredCard(dto);
			return { ...data, cardId: dto.cardid };
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);
