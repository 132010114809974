import { FC, useState } from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { getRadabankCardIdSelector, getRadabankInternalActiveCardsListSelector, useAppSelector } from 'store';
import { Navbar } from 'components/Navbar';
import { RadabankChooseCardForTransferModalAnchor, RadabankSectionHeader } from 'components/RadabankCard';
import { CustomButton } from 'components/shared';
import { NumberInput } from 'components/shared/NumberInput';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT,
} from 'utils/constants';
import { convertUAHToCoins, sortRadabankCardsCurrentCardFirst } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import { IRadabankInternalCurrentCard, RadabankTransferBetweenOwnCardsData, TypeOrNull } from 'utils/types';
import { radabankTopUpValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

interface RadabankCardTransferBetweenOwnCardsFormProps {
	onSubmit: (data: RadabankTransferBetweenOwnCardsData) => void;
}

export const RadabankCardTransferBetweenOwnCardsForm: FC<RadabankCardTransferBetweenOwnCardsFormProps> = ({
	onSubmit,
}) => {
	const handleClickBack = useGoBackRadabank();

	const activeCardsList = useAppSelector(getRadabankInternalActiveCardsListSelector);
	const currentCardId = useAppSelector(getRadabankCardIdSelector);

	const [amount, setAmount] = useState('');
	const [isValid, setIsValid] = useState(false);
	const [isTouched, setIsTouched] = useState(false);

	const [selectedCards, setSelectedCards] = useState<{
		cardFrom: TypeOrNull<IRadabankInternalCurrentCard>;
		cardTo: TypeOrNull<IRadabankInternalCurrentCard>;
	}>(() => {
		const [first = null, second = null] = sortRadabankCardsCurrentCardFirst(activeCardsList, currentCardId);
		return {
			cardFrom: second,
			cardTo: first,
		};
	});

	const handleChangeAmountValue = async (value: string): Promise<void> => {
		setAmount(value);

		try {
			await radabankTopUpValidationSchema.validate(value);
			setIsValid(true);
		} catch (error) {
			setIsValid(false);
		}
	};

	const handleBlurInput = () => {
		setIsTouched(true);
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_TOP_UP_FROM_OWN,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	const handleSubmit = () => {
		const { cardFrom, cardTo } = selectedCards;
		if (cardFrom && cardTo) {
			onSubmit({ amount, cardFrom, cardTo });
		}
	};

	const handleChangeCardFrom = (card: IRadabankInternalCurrentCard) => {
		setSelectedCards((prev) => ({ ...prev, cardFrom: card }));
	};

	const handleChangeCardTo = (card: IRadabankInternalCurrentCard) => {
		setSelectedCards((prev) => ({ ...prev, cardTo: card }));
	};

	if (!selectedCards.cardFrom || !selectedCards.cardTo) return <Navbar />;

	return (
		<div className={classNames('page', styles.page)}>
			<RadabankSectionHeader title="На свою картку" onClickBack={handleGoBack} />
			<div className={styles.content}>
				<div className={styles.cards}>
					<Typography
						display="inline-block"
						variant="caption"
						color="var(--color-neutral-600)"
						marginBottom="var(--spacing-1)"
					>
						Відправник
					</Typography>
					<RadabankChooseCardForTransferModalAnchor
						title="Оберіть картку відправника"
						onSelectCard={handleChangeCardFrom}
						cardList={activeCardsList}
						selectedCard={selectedCards.cardFrom}
					/>
					<Typography
						display="inline-block"
						variant="caption"
						color="var(--color-neutral-600)"
						marginTop="var(--spacing-3)"
						marginBottom="var(--spacing-1)"
					>
						Отримувач
					</Typography>
					<RadabankChooseCardForTransferModalAnchor
						title="Оберіть картку отримувача"
						onSelectCard={handleChangeCardTo}
						cardList={activeCardsList}
						selectedCard={selectedCards.cardTo}
					/>
				</div>

				<NumberInput
					label="Сума"
					value={amount}
					onChange={handleChangeAmountValue}
					onBlur={handleBlurInput}
					error={!isValid && isTouched}
					max={RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT}
				/>
			</div>
			<CustomButton onClick={handleSubmit} label="Далі" disabled={!convertUAHToCoins(amount) || !isValid} />
		</div>
	);
};
