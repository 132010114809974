import { FC, HTMLProps } from 'react';
import { getRadabankCurrentCardSelector, useAppSelector } from 'store';
import { RadabankCardDetails } from '../CardDetails';

interface RadabankCurrentCardDetailsProps extends HTMLProps<HTMLDivElement> {
	isShowCreditDetails?: boolean;
}

export const RadabankCurrentCardDetails: FC<RadabankCurrentCardDetailsProps> = (props) => {
	const cardData = useAppSelector(getRadabankCurrentCardSelector);

	return <RadabankCardDetails cardData={cardData} {...props} />;
};
