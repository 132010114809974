export enum STORAGE_KEY {
	DEVICE_FP = 'deviceFP',
	SESSION_ID = 'sessionId',
	SESSION_ID_PAYMENT = 'sessionIdPayment',
	PHONE = 'phone',
	VERIFICATION_ID = 'verificationId',
	VERIFICATION_ID_DATE = 'verificationIdDate',
	VERIFICATION_CODE = 'verificationCode',
	APP_LAST_BACKGROUND_TIME = 'appLastBackgroundTime',
	BIOMETRIC_TYPE = 'biometricType',
	USER_PASSWORD = 'U_P',
	IS_WAS_LOGGED_IN = 'isWasLoggedIn',
	RECEIPT_SELECTED_LIST = 'receiptSelectedList',
	IS_PUSH_ENABLED = 'isPushEnabled',
	IS_USER_PUMB = 'isUserPumb',
	HOST_CARD_MESSAGE_INDEX = 'hostCardMessageIndex',
	// Radabank
	RADABANK_AUTH_INFO = 'bankAuthInfo',
	RADABANK_AUTH_INTERNAL = 'bankAuthInternal',
	RADABANK_TOKEN_EXP_DATE = 'bankTokenExpDate',
	RADABANK_CREATE_CO_BRAND_CARD_REQUEST_ID = 'bankCreateCoBrandCardRequestId',
	RADABANK_NPOSHTA_CREATE_REQUEST_ID = 'bankNPoshtaCreateRequestId',
	RADABANK_INFO_ANKETA_DATA = 'bankInfoAnketaData',
	LAST_LOGGED_IN_USER_ID = 'lastLoggedInUserId',

	LAST_SUBSCRIBED_TO_PUSH_USER_ID = 'lastSubscribedToPushUserId',
}
