import { FC } from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import classNames from 'classnames';
import { SingleReceiptProviderItem } from 'components/Receipt';
import { ProviderAccordionGroup } from 'components/shared';
import { sortProvidersObjByType } from 'utils/helpers';
import { IReceiptResponse } from 'utils/types';
import styles from './index.module.scss';

interface ApartmentRadioItemProps {
	apartment: IReceiptResponse;
	isSelected: boolean;
}

const ApartmentRadioItem: FC<ApartmentRadioItemProps> = ({ apartment, isSelected }) => {
	return (
		<FormControlLabel
			className={classNames(styles.item, { [styles.selected]: isSelected })}
			control={<Radio />}
			value={apartment.apartmentAccountId}
			label={
				<ProviderAccordionGroup>
					{sortProvidersObjByType(apartment.serviceProviderData).map((services) => (
						<SingleReceiptProviderItem key={services[0]?.providerType} serviceList={services} />
					))}
				</ProviderAccordionGroup>
			}
		/>
	);
};

export default ApartmentRadioItem;
