import { HOST_CARD_MESSAGES } from 'utils/constants';
import { STORAGE_KEY } from 'utils/enums';
import { StorageService } from './storage.service';

class HostCardMessageService {
	private static availableMessages = HOST_CARD_MESSAGES;

	static async getStoredMessageIndex(): Promise<number> {
		try {
			const indexFromStorage = (await StorageService.get<number>(STORAGE_KEY.HOST_CARD_MESSAGE_INDEX)) || 0;
			return this.isValidIndex(indexFromStorage) ? indexFromStorage : 0;
		} catch {
			return 0;
		}
	}

	private static async updateStoredMessageIndex(index: number) {
		await StorageService.set(STORAGE_KEY.HOST_CARD_MESSAGE_INDEX, index);
	}

	private static isValidIndex(index: number): boolean {
		return index >= 0 && index < this.availableMessages.length;
	}

	static async getActiveMessage(): Promise<string> {
		const index = await this.getStoredMessageIndex();
		return this.availableMessages[index];
	}

	static async setNextActiveMessage(): Promise<void> {
		const currentIndex = await this.getStoredMessageIndex();
		const nextIndex = (currentIndex + 1) % this.availableMessages.length;
		await this.updateStoredMessageIndex(nextIndex);
	}
}

export { HostCardMessageService };
