import { FC, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/system/reducer';
import { DataHint, DataHintModalContent, ProviderAccordionGroup } from 'components/shared';
import {
	EMPTY_RECEIPT_HINT_DATA,
	EMPTY_RECEIPT_HINT_MODAL_DATA,
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	MAX_LENGTH_SENT_TO_BILL_PROVIDERS,
	WAITING_PROVIDER_DATA_HINT_DATA,
	WAITING_PROVIDER_DATA_HINT_MODAL_DATA,
} from 'utils/constants';
import { DATE_FORMAT, PROVIDER_TYPE } from 'utils/enums';
import { formatDateView, getCurrentReceiptMonth, getTotalFeeTitle, sortProvidersObjByType } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import {
	IBillReceipt,
	IProviderServiceResponse,
	ISingleReceiptPayProviderServiceRequest,
	PaymentMethodsKeys,
} from 'utils/types';
import { IHint } from 'utils/types/hint.interface';
import { PaymentProviderItem } from '../PaymentProviderItem';
import { ReceiptProvidersListPreview } from '../ReceiptProvidersListPreview';

const RECEIPT_TITLE = 'Єдина квитанція';

interface ReceiptProviderServiceListProps {
	selectedServiceList: ISingleReceiptPayProviderServiceRequest[];
	onChangeSelectedList: (value: ISingleReceiptPayProviderServiceRequest[]) => void;
	defaultServiceList?: IProviderServiceResponse[][];
	pageName: string;
	onChangeFee?: (serviceId: string | number, amount: number, feeHostCardAmount: number) => void;
	activePaymentMethodGroup?: PaymentMethodsKeys;
}

export const ReceiptProviderServiceList: FC<ReceiptProviderServiceListProps> = ({
	selectedServiceList,
	onChangeSelectedList,
	defaultServiceList,
	pageName,
	onChangeFee,
	activePaymentMethodGroup,
}) => {
	const dispatch = useAppDispatch();

	const receipt: IBillReceipt = useAppSelector(getBillReceiptSelector);
	const [hintProps, setHintProps] = useState<null | IHint>(null);

	const serviceList = useMemo(() => {
		let serviceListPayload = defaultServiceList ?? [];
		if (receipt.data?.id) {
			serviceListPayload = sortProvidersObjByType(receipt.data.serviceProviderData);
		}
		return serviceListPayload.map((item) => ([...item]));
	}, [defaultServiceList, receipt.data?.id, activePaymentMethodGroup]);

	const updateHintProps = () => {
		const hasService = !!serviceList.length;

		const providersLength = receipt.data?.parsingInfo.sentToBillProviders?.length || 0;

		const isHezParsed = receipt.data?.parsingInfo.sentToBillProviders.includes(PROVIDER_TYPE.HEZ.toLowerCase());

		const isProviderDataParsed = providersLength >= MAX_LENGTH_SENT_TO_BILL_PROVIDERS;
		const currentReceiptMonth = +getCurrentReceiptMonth();

		const isDataForPreviousMonth =
			currentReceiptMonth > Number(receipt.data?.month) ||
			(currentReceiptMonth < Number(receipt.data?.month) && Number(receipt.data?.year) < Number(dayjs().year()));

		if ((hasService && isProviderDataParsed) || isDataForPreviousMonth) {
			setHintProps(null);
		} else {
			setHintProps(
				(hasService && providersLength && providersLength < MAX_LENGTH_SENT_TO_BILL_PROVIDERS) || isHezParsed
					? WAITING_PROVIDER_DATA_HINT_DATA
					: EMPTY_RECEIPT_HINT_DATA
			);
		}
	};

	useEffect(() => {
		if (!receipt.data) return;
		updateHintProps();
	}, [serviceList]);

	const handleOpenModal = () => {
		const props = serviceList.length ? WAITING_PROVIDER_DATA_HINT_MODAL_DATA : EMPTY_RECEIPT_HINT_MODAL_DATA;
		FirebaseAnalytics.logEvent(
			pageName,
			serviceList.length
				? FIREBASE_EVENT_ANALYTICS_BUTTON.QUESTION_PROVIDER_DATA
				: FIREBASE_EVENT_ANALYTICS_BUTTON.QUESTION_EMPTY_SR
		);
		dispatch(
			openModal({
				content: <DataHintModalContent {...props} />,
			})
		);
	};

	const date = receipt.data
		? dayjs()
				.set('month', Number(receipt.data?.month) - 1)
				.set('year', Number(receipt.data?.year))
		: '';

	const title = date ? `${RECEIPT_TITLE} за ${formatDateView(date, DATE_FORMAT.RECEIPT_VIEW)}` : RECEIPT_TITLE;

	if (!receipt.data && !defaultServiceList?.length) return null;

	return (
		<ReceiptProvidersListPreview title={title} chipTitle={getTotalFeeTitle(0)}>
			<ProviderAccordionGroup>
				{serviceList.map((providerServiceList: IProviderServiceResponse[]) => (
					<PaymentProviderItem
						activePaymentMethodGroup={activePaymentMethodGroup}
						onChangeFee={onChangeFee}
						key={providerServiceList[0].providerType}
						serviceList={providerServiceList}
						onChange={onChangeSelectedList}
						selectedServiceList={selectedServiceList}
					/>
				))}
			</ProviderAccordionGroup>
			{!!hintProps && <DataHint {...hintProps} onOpenModal={handleOpenModal} />}
		</ReceiptProvidersListPreview>
	);
};
