import { FC, useState } from 'react';
import { useLocation } from 'react-router';
import {
	closeConfirmRadabankPaymentModal,
	getRadabankApprovePaymentModalSelector,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { ApprovePaymentModal } from 'components/RadabankCard';

export const ApproveRadabankPaymentModal: FC = () => {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	const [isPresented, setIsPresented] = useState(false);

	const { isOpen, paymentIds, pagePathname } = useAppSelector(getRadabankApprovePaymentModalSelector);

	const handleDidPresent = () => {
		setIsPresented(true);
	};

	const handleClose = () => {
		dispatch(closeConfirmRadabankPaymentModal());
		setIsPresented(false);
	};

	if (pagePathname !== pathname) return null;

	return (
		<ApprovePaymentModal
			paymentIds={paymentIds}
			isOpen={isOpen}
			onClose={handleClose}
			onDidPresent={handleDidPresent}
			isPresented={isPresented}
		/>
	);
};
