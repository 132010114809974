import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Typography } from '@mui/material';
import { ReactComponent as Failed } from 'assets/icons/archive-empty.svg';
import { getBillsList } from 'store/bill/actions';
import { resetBillsList } from 'store/bill/reducer';
import { getApartmentsSelector, getBillsListSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { BillsListItem } from 'components/Bills';
import { ReceiptPageHeader } from 'components/Receipt';
import { Loader, Observer, ScrollToLayoutTopButtonContainer } from 'components/shared';
import { DEFAULT_PAGINATED_BILLS_LIST_REQUEST_BODY } from 'utils/constants';
import { PAYMENT_TYPE, ROUTER_URL } from 'utils/enums';
import { decryptData, getErrorMessage, getReceiptRoute } from 'utils/helpers';
import { IBillsListItemResponse, IBillsListRequest, IPaginatedRequest } from 'utils/types';
import styles from './index.module.scss';

export const ReceiptBillsListPage: FC = () => {
	const dispatch = useAppDispatch();

	const billsList = useAppSelector(getBillsListSelector);
	const profile = useAppSelector(getUserProfileSelector);
	const apartments = useAppSelector(getApartmentsSelector);

	const history = useHistory();
	const location = useLocation();
	const params = useParams<{ apartmentAccountId: string }>();

	const [reqBody, setReqBody] = useState<IPaginatedRequest<IBillsListRequest>>({
		...DEFAULT_PAGINATED_BILLS_LIST_REQUEST_BODY,
	});

	useEffect(() => {
		return () => {
			dispatch(resetBillsList());
		};
	}, []);

	useEffect(() => {
		const apartmentAccountId = decryptData(params.apartmentAccountId);
		if (!apartmentAccountId) {
			history.replace(ROUTER_URL.ERROR);
		} else if (profile) {
			setReqBody({
				...DEFAULT_PAGINATED_BILLS_LIST_REQUEST_BODY,
				options: {
					...DEFAULT_PAGINATED_BILLS_LIST_REQUEST_BODY.options,
					apartmentAccountId: +apartmentAccountId,
					userId: [profile.userId],
					paymentType: PAYMENT_TYPE.EK,
				},
			});
		}
	}, [profile?.userId]);

	useEffect(() => {
		if (reqBody.options.apartmentAccountId && reqBody.options.userId?.length) {
			handleGetBillsList(reqBody);
		}
	}, [reqBody]);

	const handleGetBillsList = async (reqBody: IPaginatedRequest<IBillsListRequest>): Promise<void> => {
		try {
			await dispatch(getBillsList(reqBody)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleObserve = (): void => {
		setReqBody({ ...reqBody, offset: reqBody.offset + reqBody.limit });
	};

	const handleClickBack = (): void => {
		const apartmentAccountId = decryptData(params.apartmentAccountId);

		let url = getReceiptRoute(params.apartmentAccountId);

		if (!apartmentAccountId) {
			url = ROUTER_URL.ERROR;
		} else {
			const isApartmentWithSingleReceipt = !!apartments.list.find(
				(item) => item.apartmentAccountId === +apartmentAccountId
			)?.singleReceipt;

			if (!isApartmentWithSingleReceipt) {
				url = ROUTER_URL.PROFILE;
			}
		}

		history.push(url);
	};

	return (
		<div className="page">
			<div className="receipt-page-content">
				<ReceiptPageHeader title="Архів платежів" onBackClick={handleClickBack} />
				<ScrollToLayoutTopButtonContainer>
					<div className={styles.content__list}>
						{billsList.data.tableData.length ? (
							<>
								{billsList.data.tableData.map((item: IBillsListItemResponse) => (
									<BillsListItem
										key={item.id}
										data={item}
										onClick={() => history.push(`${location.pathname}/${item.id}`)}
										title={`HOST ID - ${item.payerData.apartmentAccountId}`}
										description={`Платіж - ${item.shortenedId}`}
									/>
								))}
								{billsList.data.tableData.length !== billsList.data.listData.totalRows && (
									<Observer onObserve={handleObserve} isLoading={billsList.isLoading} />
								)}
							</>
						) : (
							!billsList.isLoading && (
								<div className={styles.content__list_empty}>
									<Failed className={styles.content__list_empty__icon} />
									<Typography color="--color-neutral-300" variant="h5" marginTop="var(--spacing-7)">
										Архів порожній
									</Typography>
									<Typography textAlign="center" color="--color-neutral-300" variant="body1" marginTop="var(--spacing-4)">
										{/* eslint-disable-next-line react/no-unescaped-entities */}
										Сплачуйте послуги, квитанції з'являться тут
									</Typography>
									<Typography textAlign="center" color="--color-neutral-300" variant="body1" marginTop="var(--spacing-4)">
										А сплачувати карткою HostCard дешевше ;-)
									</Typography>
								</div>
							)
						)}
					</div>
				</ScrollToLayoutTopButtonContainer>
				{billsList.isLoading && !billsList.data.tableData.length && <Loader />}
			</div>
		</div>
	);
};
