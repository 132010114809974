import { FC } from 'react';
import { Typography } from '@mui/material';
import styles from './index.module.scss';

export const HostIdDetailsModalContent: FC = () => {
	return (
		<div className={styles.content}>
			<Typography mb="8px" variant="subtitle1">
				Що таке HostID?
			</Typography>
			<Typography color="var(--color-neutral-500)" variant="body2">
				HostID – це унікальний ідентифікатор адреси. Може знадобитися при зверненні до нашого контакт-центру
			</Typography>
		</div>
	);
};
