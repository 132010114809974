import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Typography } from '@mui/material';
import {
	createRadabankTransferPayment,
	getUserIdSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import {
	RadabankCardForTransferOption,
	RadabankOperationDetailRow,
	RadabankSectionHeader,
} from 'components/RadabankCard';
import { RadabankOperationFeeDetails } from 'components/RadabankCard/OperationFeeDetails';
import { CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { convertCoinsToUAH, getAmountTitle, getErrorMessage } from 'utils/helpers';
import { useAndroidBackButton } from 'utils/hooks/use-close-on-back-button';
import { FirebaseAnalytics } from 'utils/services';
import { IRadabankFeeResponse, RadabankTransferBetweenOwnCardsData } from 'utils/types';
import styles from './index.module.scss';

interface OperationDetailsProps {
	onGoBack: () => void;
	operationData: RadabankTransferBetweenOwnCardsData;
	feeData: IRadabankFeeResponse;
}

export const OperationDetails: FC<OperationDetailsProps> = ({ onGoBack, operationData, feeData }) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { pathname } = useLocation();

	const userId = useAppSelector(getUserIdSelector) || '';

	const handleCreatePayment = async () => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_TOP_UP_FROM_OWN_DETAILS,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CREATE_PAYMENT
			);
			dispatch(setAppLoading(true));

			const data = await dispatch(
				createRadabankTransferPayment({
					userId,
					cardid: operationData.cardFrom.id,
					cardidrec: operationData.cardTo.id,
					amount: operationData.amount,
					pagePathname: pathname,
				})
			).unwrap();

			if (Number(data.countsms) > 0 && data.opersms.length) {
				return;
			}

			history.push(ROUTER_URL.RADABANK_CARD_OPERATION_BANNER);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_TOP_UP_FROM_OWN_DETAILS,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		onGoBack();
	};

	useAndroidBackButton(handleGoBack);

	return (
		<div className="host-card-page-color-white">
			<RadabankSectionHeader title="На свою картку" onClickBack={handleGoBack} />
			<div className={styles.content}>
				<div className={styles.cards}>
					<Typography
						variant="caption"
						display="inline-block"
						color="var(--color-neutral-600)"
						marginBottom="var(--spacing-1)"
					>
						Відправник
					</Typography>
					<RadabankCardForTransferOption
						isHideAmount
						disableRipple
						className={styles.preview}
						card={operationData.cardFrom}
						isSelected
					/>
					<Typography
						variant="caption"
						display="inline-block"
						color="var(--color-neutral-600)"
						marginTop="var(--spacing-4)"
						marginBottom="var(--spacing-1)"
					>
						Отримувач
					</Typography>
					<RadabankCardForTransferOption
						isHideAmount
						disableRipple
						className={styles.preview}
						card={operationData.cardTo}
						isSelected
					/>
				</div>
				<div>
					<RadabankOperationDetailRow
						title="Сума переказу"
						description={getAmountTitle(convertCoinsToUAH(operationData.amount), 'UAH')}
					/>
					<RadabankOperationFeeDetails fee={feeData.clientfee} currency="UAH" isShowInfo={!!feeData.message2} />
				</div>
			</div>
			<CustomButton label="Сплатити" onClick={handleCreatePayment} />
		</div>
	);
};
