const allowedPaymentMethods: google.payments.api.PaymentMethodSpecification[] = [
	{
		type: 'CARD',
		parameters: {
			allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
			allowedCardNetworks: ['MASTERCARD', 'VISA'],
		},
		tokenizationSpecification: {
			type: 'PAYMENT_GATEWAY',
			parameters: {
				gateway: process.env.REACT_APP_GOOGLE_PAY_TOKENIZATION_GATEWAY as string,
				gatewayMerchantId: process.env.REACT_APP_GOOGLE_PAY_TOKENIZATION_GATEWAY_MERCHANT_ID as string,
			},
		},
	},
];

export const DEFAULT_GOOGLE_PAY_DEFAULT_TRANSACTION_INFO: Pick<
	google.payments.api.TransactionInfo,
	'currencyCode' | 'countryCode' | 'totalPriceStatus' | 'checkoutOption'
> = {
	totalPriceStatus: 'FINAL',
	currencyCode: 'UAH',
	countryCode: 'UA',
	checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE'
};

export const DEFAULT_GOOGLE_PAY_CONFIG: Omit<google.payments.api.PaymentDataRequest, 'transactionInfo'> = {
	apiVersion: 2,
	apiVersionMinor: 0,
	allowedPaymentMethods,
	merchantInfo: {
		merchantId: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_ID as string,
		merchantName: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_NAME,
	},
};

export const GOOGLE_PAY_MAX_AMOUNT_SERVICES = 9999;

export const GOOGLE_PAY_MAX_AMOUNT_PORTMONE = 5000;
