import { FC } from 'react';
import { Typography } from '@mui/material';
import { FooterContactsList } from './ContactsList';
import { FooterColumn } from './FooterColumn';
import styles from './index.module.scss';

const CONTACTS_LIST = ['0-800-33-2077', '095-77-211-77', '098-77-211-77'];

export const Footer: FC = () => {
	return (
		<footer className={styles.footer}>
			<div className={styles.content}>
				<FooterColumn title="Контактний телефон">
					<FooterContactsList contactsList={CONTACTS_LIST} />
				</FooterColumn>
				<FooterColumn title="Служба підтримки HOST">
					<a href="mailto:support@finansoua.com.ua">support@finansoua.com.ua</a>
				</FooterColumn>
				<FooterColumn title="Графік роботи">
					<Typography fontWeight={700} color="inherit">
						Понеділок - п&apos;ятниця з 10:00 до 17:00
					</Typography>
				</FooterColumn>
			</div>
		</footer>
	);
};
