import { ComponentProps, FC, forwardRef, ReactNode } from 'react';
import { IonButton, IonSpinner } from '@ionic/react';
import classNames from 'classnames';

interface CustomButtonProps extends ComponentProps<typeof IonButton> {
	label?: string;
	startIcon?: ReactNode;
	endIcon?: ReactNode;
	isLoading?: boolean;
}

export const CustomButton: FC<CustomButtonProps> = forwardRef(
	({
 label, startIcon, endIcon, isLoading, disabled, color, children, ...props
}, ref) => (
		<IonButton {...props} color={color} disabled={disabled || isLoading} ref={ref}>
			<div
				className={classNames('ion-button-content', {
					'ion-button-content-secondary': color === 'secondary',
					'ion-button-content-danger': color === 'danger',
				})}
			>
				{startIcon}
				{!!isLoading && <IonSpinner name="crescent" />}
				{label}
				{children}
				{endIcon}
			</div>
		</IonButton>
	)
);
