import { FC, HTMLProps, MouseEvent } from 'react';
import { Clipboard } from '@capacitor/clipboard';
import { IonSpinner } from '@ionic/react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { showToast, useAppDispatch } from 'store';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { DATE_FORMAT } from 'utils/enums';
import { formatDateView, getErrorMessage, getRadabankNumberView } from 'utils/helpers';
import { useLongPress } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import { IRadabankInternalCurrentCard } from 'utils/types';
import styles from './index.module.scss';

interface RadabankCardPreviewSecuredProps extends HTMLProps<HTMLDivElement> {
	cvv: string;
	onGetCvv: (cardId: string) => Promise<void>;
	isLoading: boolean;
	cardData: IRadabankInternalCurrentCard;
	isMulticard: boolean;
}

export const RadabankCardPreviewSecured: FC<RadabankCardPreviewSecuredProps> = ({
	className,
	cvv,
	onGetCvv,
	isLoading,
	cardData,
	isMulticard,
}) => {
	const dispatch = useAppDispatch();

	const handleCopyToClipboard = async () => {
		try {
			await Clipboard.write({ string: cardData?.fullnumber });
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_MAIN,
				FIREBASE_EVENT_ANALYTICS_BUTTON.COPY_CARD_NUMBER
			);
			dispatch(showToast({ message: 'Номер картки скопійовано до буферу', color: 'success' }));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const { onTouchStart, onTouchEnd, onClick } = useLongPress(handleCopyToClipboard, 500);

	const handleClickGetCvv = (event: MouseEvent): void => {
		event.stopPropagation();
		if (!cvv && cardData) {
			onGetCvv(cardData.id);
		}
	};

	return (
		!!cardData && (
			<div
				className={classNames(styles.content, 'radabank-card-preview', className, { [styles.multicard]: isMulticard })}
			>
				<Typography
					onTouchStart={onTouchStart}
					onTouchEnd={onTouchEnd}
					onClick={onClick}
					padding="10px 5px 10px 0"
					variant="h5"
					marginBottom="var(--spacing-3)"
				>
					{getRadabankNumberView(cardData.fullnumber)}
				</Typography>
				<div className={styles.content__row}>
					<Typography variant="subtitle2">
						{formatDateView(dayjs(cardData.expdate, DATE_FORMAT.RADABANK_CARD_EXP_DATE), DATE_FORMAT.CARD)}
					</Typography>
					<Typography className={styles.cvv} variant="subtitle2" onClick={handleClickGetCvv}>
						CVV {isLoading ? <IonSpinner className={styles.loader} name="dots" /> : `${cvv || '***'}`}
					</Typography>
				</div>
			</div>
		)
	);
};
