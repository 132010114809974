import { FC, useEffect, useRef, useState } from 'react';
import { RefresherEventDetail } from '@ionic/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {
	getAppLoadingSelector,
	getIsCurrentCardBlockedSelector,
	getIsCurrentCardLoading,
	getRadabankCurrentCardSelector,
	getRadabankFlattenedOperationsListSelector,
	getRadabankInternalCardsList,
	getRadabankInternalOperationsList,
	getRadabankOperationsPaymentsIdListSelector,
	getRadabankOperationsSelector,
	getRadabankStatusPaymentById,
	getRadabanlInternalIsLoadingCardsListSelector,
	getUserProfileSelector,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { Navbar } from 'components/Navbar';
import { RadabankCardWithOperations, RadabankOperationsList } from 'components/RadabankCard';
import { Loader } from 'components/shared';
import { RADABANK_OPERATIONS_LIST_DAYS_COUNT } from 'utils/constants';
import { DATE_FORMAT, RADABANK_BOOLEAN_VALUE, RADABANK_PAYMENT_STATUS } from 'utils/enums';
import { convertDateFormat, getErrorMessage, getRadabankOperationsListDateStart } from 'utils/helpers';
import { IUserProfileResponse } from 'utils/types';
import styles from './index.module.scss';

const RADABANK_PAYMENT_PING_DURATION = 60000;
const RADABANK_PAYMENT_PING_INTERVAL = 5000;

export const RadabankMainPage: FC = () => {
	const dispatch = useAppDispatch();

	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const counterRef = useRef(0);

	const profile = useAppSelector(getUserProfileSelector);
	const cardData = useAppSelector(getRadabankCurrentCardSelector);
	const paymentsIdList = useAppSelector(getRadabankOperationsPaymentsIdListSelector);
	const isAppLoading = useAppSelector(getAppLoadingSelector);
	const isLoadingCardsList = useAppSelector(getRadabanlInternalIsLoadingCardsListSelector);
	const cardOperations = useAppSelector(getRadabankOperationsSelector);
	const operationsList = useAppSelector(getRadabankFlattenedOperationsListSelector);

	const isCardFrozen = useAppSelector(getIsCurrentCardBlockedSelector);
	const isCurrentCardLoading = useAppSelector(getIsCurrentCardLoading);

	const [canLoad, setCanLoad] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const isFirstLoad = useRef(true);
	const dateEnd = useRef(
		operationsList.length
			? dayjs(convertDateFormat(operationsList.at(-1)?.dateapprove, DATE_FORMAT.RADABANK_REQUEST))
			: dayjs()
	);

	const isPaymentAllowed = cardData?.add_payment === RADABANK_BOOLEAN_VALUE.TRUE;

	useEffect(() => {
		if (paymentsIdList.length && profile?.userId && cardData?.id && !intervalRef.current) {
			counterRef.current = 0;
			intervalRef.current = setInterval(() => {
				handleCheckPaymentsStatus(profile.userId, cardData.id, paymentsIdList);
			}, RADABANK_PAYMENT_PING_INTERVAL);
		} else if (!paymentsIdList.length && intervalRef.current) {
			handleResetInterval();
		}

		return () => {
			handleResetInterval();
		};
	}, [paymentsIdList.length, profile?.userId, cardData?.id]);

	useEffect(() => {
		if (cardData?.id) {
			if (!Object.values(cardOperations.list).flat().length) {
				isFirstLoad.current = true;
				dateEnd.current = dayjs();
				setCanLoad(true);
				handleGetOperationsList(cardData.id);
			} else {
				handleUpdateCurrentDateOperationsList(cardData.id);
			}
		}

		return () => {
			setIsLoading(false);
		};
	}, [cardData?.id]);

	const handleResetInterval = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
			counterRef.current = 0;
		}
	};

	const handleCheckPaymentsStatus = async (userId: string, cardId: string, paymentsIdList: string[]): Promise<void> => {
		if (counterRef.current >= RADABANK_PAYMENT_PING_DURATION / RADABANK_PAYMENT_PING_INTERVAL) {
			handleResetInterval();
			return;
		}
		try {
			counterRef.current += 1;
			const promises = paymentsIdList.map((item) =>
				dispatch(getRadabankStatusPaymentById({ userId, requestid: item })).unwrap()
			);
			const promisesResponseList = await Promise.allSettled(promises);
			const isShouldUpdateData = promisesResponseList.some(
				(item) =>
					item.status === 'fulfilled' &&
					(item.value.status === RADABANK_PAYMENT_STATUS.SUCCESS ||
						item.value.status === RADABANK_PAYMENT_STATUS.REJECTED)
			);
			if (isShouldUpdateData) {
				await dispatch(
					getRadabankInternalCardsList({
						userId: (profile as IUserProfileResponse).userId,
						shouldSetCurrentCard: false,
					})
				).unwrap();
				await dispatch(
					getRadabankInternalOperationsList({
						userId: (profile as IUserProfileResponse).userId,
						datestart: dayjs().format(DATE_FORMAT.RADABANK_REQUEST),
						datefinish: dayjs().format(DATE_FORMAT.RADABANK_REQUEST),
						cardid: cardId,
					})
				).unwrap();
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleGetOperationsList = async (cardId: string): Promise<void> => {
		if (isLoading || !cardData) {
			return;
		}

		const cardCreationDate = dayjs(cardData.docdate, DATE_FORMAT.RADABANK_REQUEST);

		if (dateEnd.current.isBefore(cardCreationDate) || dateEnd.current.isSame(cardCreationDate)) {
			setCanLoad(false);
			return;
		}

		try {
			setIsLoading(true);

			let dateFinish = dateEnd.current;

			if (!isFirstLoad.current) {
				dateFinish = dateEnd.current.subtract(RADABANK_OPERATIONS_LIST_DAYS_COUNT + 1, 'days');
			}

			const isStartDateEarlierThanCardCreationDate =
				dateFinish.diff(cardCreationDate, 'days') < RADABANK_OPERATIONS_LIST_DAYS_COUNT;

			const formattedDateFinish = dateFinish.format(DATE_FORMAT.RADABANK_REQUEST);

			const startDate = isStartDateEarlierThanCardCreationDate
				? cardData.docdate
				: getRadabankOperationsListDateStart(formattedDateFinish);

			await dispatch(
				getRadabankInternalOperationsList({
					cardid: cardId,
					userId: (profile as IUserProfileResponse).userId,
					datestart: startDate,
					datefinish: formattedDateFinish,
				})
			).unwrap();

			dateEnd.current = isStartDateEarlierThanCardCreationDate ? cardCreationDate : dateFinish;
			isFirstLoad.current = false;

			if (dateEnd.current.isBefore(cardCreationDate) || dateEnd.current.isSame(cardCreationDate)) {
				setCanLoad(false);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleUpdateCurrentDateOperationsList = async (cardId: string): Promise<void> => {
		try {
			const currentDate = dayjs().format(DATE_FORMAT.RADABANK_REQUEST);
			await dispatch(
				getRadabankInternalOperationsList({
					cardid: cardId,
					userId: (profile as IUserProfileResponse).userId,
					datestart: currentDate,
					datefinish: currentDate,
				})
			).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleRefreshThePage = async (e: CustomEvent<RefresherEventDetail>) => {
		try {
			if (profile?.userId) {
				await dispatch(getRadabankInternalCardsList({ userId: profile.userId, shouldSetCurrentCard: false })).unwrap();
				await handleUpdateCurrentDateOperationsList(cardData?.id ?? '');
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			e.detail.complete();
		}
	};

	return (
		<>
			{!!cardData && (
				<div className={classNames('page', styles.wrapper)}>
					<RadabankCardWithOperations
						onRefresh={handleRefreshThePage}
						isFrozen={isCardFrozen}
						isPaymentAllowed={isPaymentAllowed}
					/>
					<RadabankOperationsList
						canLoad={canLoad}
						isLoading={cardOperations.isLoading}
						cardOperations={cardOperations.list}
						onObserve={handleGetOperationsList}
					/>
				</div>
			)}
			<Navbar />
			{((!isAppLoading && isLoadingCardsList && !cardData) || isCurrentCardLoading) && <Loader />}
		</>
	);
};
