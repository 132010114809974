import { FC } from 'react';
import { Tooltip } from '@mui/material';
import { ICardListItemResponse } from 'utils/types';
import { PaymentMethodCardItemWithFeeContent } from '../FeeItem';
import styles from './index.module.scss';

interface PaymentMethodCardItemContentProps {
	item: ICardListItemResponse;
	disabledMessage?: string;
	fee?: number;
	isActive?: boolean;
}

export const PaymentMethodCardItemContent: FC<PaymentMethodCardItemContentProps> = ({
	item,
	disabledMessage = '',
	fee = 0,
	isActive = false,
}) => {
	return (
		<Tooltip disableInteractive title={disabledMessage}>
			<div className={styles.tooltip}>
				<PaymentMethodCardItemWithFeeContent fee={fee} item={item} isActive={isActive} />
			</div>
		</Tooltip>
	);
};
