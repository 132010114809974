import { FC } from 'react';
import { List } from '@mui/material';
import { IRadabankInternalCurrentCard } from 'utils/types';
import { RadabankCardForTransferOption } from '../Option';
import styles from './index.module.scss';

interface RadabankCardForTransferListProps {
	selectedCardId: string;
	cardList: IRadabankInternalCurrentCard[];
	onSelectCard: (card: IRadabankInternalCurrentCard) => void;
}

export const RadabankCardForTransferList: FC<RadabankCardForTransferListProps> = ({
	selectedCardId,
	onSelectCard,
	cardList,
}) => {
	return (
		<List className={styles.list}>
			{cardList.map((card) => (
				<RadabankCardForTransferOption
					key={card.id}
					card={card}
					isSelected={selectedCardId === card.id}
					onSelect={onSelectCard}
				/>
			))}
		</List>
	);
};
