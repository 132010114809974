import { FC, ReactNode } from 'react';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@mui/material';
import classNames from 'classnames';
import styles from './index.module.scss';

interface ProviderAccordionProps extends Omit<AccordionProps, 'children' | 'title'> {
	title: ReactNode;
	children: ReactNode;
	disabledClassName?: string;
	headerClassName?: string;
}

export const ProviderAccordion: FC<ProviderAccordionProps> = ({
	title,
	children,
	className = '',
	disabledClassName = '',
	headerClassName = '',
	disabled,
	...rest
}) => {
	return (
		<Accordion
			{...rest}
			className={classNames(styles.wrapper, className, {
				[disabledClassName]: disabled,
				[styles.disabled]: disabled,
			})}
			disabled={disabled}
			disableGutters
		>
			<AccordionSummary className={classNames(styles.header, headerClassName)}>{title}</AccordionSummary>
			<AccordionDetails className={styles.details}>{children}</AccordionDetails>
		</Accordion>
	);
};
