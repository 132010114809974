import {
	RADABANK_BOOLEAN_VALUE,
	RADABANK_CLIENT_REQUEST_OVER_TYPE,
	RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST,
} from 'utils/enums';
import {
	IRadabankBudgetCreatePaymentRequest,
	IRadabankClientRequestOverRequest,
	IRadabankCreatePaymentRequest,
	IRadabankInfoSetAnketaRequest,
	IRadabankInternalAddRuleRequest,
	IRadabankInternalCardCurrentRestDataResponse,
	IRadabankNPoshtaCreateRequest,
	IRadabankTransferCreatePaymentRequest,
} from 'utils/types';

export const RADABANK_SURVEY_LETTER_INITIAL_VALUES: Omit<
	IRadabankInfoSetAnketaRequest,
	'userId' | 'infotoken' | 'infocode'
> = {
	secret: '',
	isfop: RADABANK_BOOLEAN_VALUE.FALSE,
	isnotar: RADABANK_BOOLEAN_VALUE.FALSE,
	email: '',
	criminal: RADABANK_BOOLEAN_VALUE.FALSE,
	work_place: '',
	work_dolg: '',
	amount_in: '',
	amount_out: '',
	// format should be 0900000000
	contact_phone: '',
	contact_lastname: '',
	contact_firstname: '',
	contact_middlename: '',
	contact_person_ext: '',
	contact_live: RADABANK_BOOLEAN_VALUE.FALSE,
	nalog_resident_usa: RADABANK_BOOLEAN_VALUE.FALSE,
	nalog_resident_other: RADABANK_BOOLEAN_VALUE.FALSE,
	connect_agressor: RADABANK_BOOLEAN_VALUE.FALSE,
	adr_input: RADABANK_BOOLEAN_VALUE.FALSE,
	adr_region_id: '',
	adr_district_id: '',
	adr_citytype_id: '',
	adr_city_id: '',
	adr_streettype_id: '',
	adr_street: '',
	adr_house: '',
	adr_flat: '',
	// from InfoSprData
	social_status: '',
	marital_status: '',
	edication: '',
	term_work: '',
	contact_person: '',
	source_income: '',
	scope_enterprise: '',
	// Поточні витрати та рахунки
	reason_credit: '7',

	have_flat: RADABANK_BOOLEAN_VALUE.FALSE,
	have_house: RADABANK_BOOLEAN_VALUE.FALSE,
	have_car: RADABANK_BOOLEAN_VALUE.FALSE,
	have_land: RADABANK_BOOLEAN_VALUE.FALSE,
	have_deposit: RADABANK_BOOLEAN_VALUE.FALSE,
	amount_add: '',
};

export const RADABANK_CARD_CREATE_PAYMENT_INITIAL_VALUES: IRadabankCreatePaymentRequest = {
	userId: '',
	card: '',
	cardid: '',
	amount: '',
	comment: '',
};

export const RADABANK_TRANSFER_CREATE_PAYMENT_INITIAL_VALUES: IRadabankTransferCreatePaymentRequest = {
	userId: '',
	cardid: '',
	amount: '',
	ibanrec: '',
	namerec: '',
	innrec: '',
	destination: '',
	comment: '',
	countryrec: '',
	docseriarec: '',
	docnumberrec: '',
};

export const RADABANK_BUDGET_CREATE_PAYMENT_INITIAL_VALUES: IRadabankBudgetCreatePaymentRequest = {
	userId: '',
	cardid: '',
	amount: '',
	ibanrec: '',
	namerec: '',
	innrec: '',
	budgetvidid: '1',
	budgetcode: '',
	budgetadd: '',
};

export const RADABANK_NPOSHTA_CREATE_REQUEST_INITIAL_VALUES: IRadabankNPoshtaCreateRequest = {
	typerequest: RADABANK_NPOSHTA_DIVISION_TYPE_REQUEST.DEPARTMENT,
	userId: '',
	cardid: '',
	cardidsend: '',
	accountidsend: '',
	cityref: '',
	citydeliveryref: '',
	citypresent: '',
	cityarea: '',
	cityregion: '',
	citytypecode: '',
	citydescription: '',
	divref: '',
	// streetref: '',
	// streetpresent: '',
	// streetcityref: '',
	// streetdescription: '',
	// streettype: '',
	// streettypedescription: '',
	house: '',
	flat: '',
	comment: '',
};

export const RADABANK_CLIENT_REQUEST_OVER_INITIAL_VALUES: IRadabankClientRequestOverRequest = {
	userId: '',
	cardid: '',
	typerequest: RADABANK_CLIENT_REQUEST_OVER_TYPE.OPEN,
	amount: '',
	criminal: RADABANK_BOOLEAN_VALUE.FALSE,
	amount_out: '',
	contact_phone: '',
	contact_lastname: '',
	contact_firstname: '',
	contact_middlename: '',
	// from InfoSprData
	social_status: '',
	term_work: '',
	contact_person: '',
	maritial_status: '',
	education: '',
	contact_live: RADABANK_BOOLEAN_VALUE.FALSE,
	email: '',
	nalog_resident_other: RADABANK_BOOLEAN_VALUE.FALSE,
	connect_agressor: RADABANK_BOOLEAN_VALUE.FALSE,
	scope_enterprise: '',
	work_place: '',
	work_dolg: '',
	isnotar: RADABANK_BOOLEAN_VALUE.FALSE,
	isfop: RADABANK_BOOLEAN_VALUE.FALSE,
	amount_in: '',
	amount_add: '',
	source_income: '',
	have_flat: RADABANK_BOOLEAN_VALUE.FALSE,
	have_house: RADABANK_BOOLEAN_VALUE.FALSE,
	have_car: RADABANK_BOOLEAN_VALUE.FALSE,
	have_land: RADABANK_BOOLEAN_VALUE.FALSE,
	have_deposit: RADABANK_BOOLEAN_VALUE.FALSE,

	// helpers fields
	confirmSetLimit: RADABANK_BOOLEAN_VALUE.FALSE,
};

export const RADABANK_ADD_RULE_INITIAL_VALUES: IRadabankInternalAddRuleRequest = {
	userId: '',
	cardid: '',
	datestart: '',
	datefinish: '',
	countryid: '',
	currid: '',
};

export const RADABANK_ADD_PHONE_MANUAL_INITIAL_VALUES = {
	phone: '',
};

export const RADABANK_OTP_TITLE = 'Згідно з законодавством маємо перевірити ваш номер ще раз. Введіть код із SMS';

export const RADABANK_CONFIRM_PAYMENT_OTP_TITLE = 'Будь ласка, підтвердьте ваш платіж. Введіть код із SMS';

export const RADABANK_OPERATIONS_LIST_DAYS_COUNT = 7;

export const RADABANK_PIN_CODE_LENGTH = 4;

export const RADABANK_IBAN_MFO = '306500';

export const RADABANK_CARD_COINS_KEYS = [
	'overdraft',
	'debt',
	'limitcashamount',
	'limitposamount',
	'overamount',
	'pcrest',
	'rest',
	'availamount',
	'availonline',
	'amountmin',
	'amounthold',
	'cardrestmine',
	'amountother',
];

export const RADABANK_OPERATION_COINS_KEYS = ['amountonline', 'amount', 'rest'];

export const RADABANK_OVERDRAFT_COINS_KEYS: (keyof IRadabankInternalCardCurrentRestDataResponse)[] = [
	'availamount',
	'overamount',
	'overamountmin',
	'overamountmax',
	'overdraft',
	'amountother',
	'cardrestmine',
	'overamountgrace',
];

export const OPTIONAL_SOCIAL_STATUSES = ['2', '3', '4', '7', '12', '15'];

export const RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT = 24999.99;

export const RADABANK_MIN_ALLOWED_TRANSACTION_AMOUNT = 1;

export const RADABANK_MULTICARD_NAME = 'MULTIcard UAH';

export const RADABANK_MULTICARD_FORMATTED_NAME = 'MULTICARD';

export const RADABANK_HOSTCARD_NAME = 'HOST card';

export const RADABANK_HOSTCARD_FORMATTED_NAME = 'HOSTCARD';

export const RADANAK_CARDS_FORMATTED_NAMES: Record<string, string> = {
	[RADABANK_HOSTCARD_NAME]: RADABANK_HOSTCARD_FORMATTED_NAME,
	[RADABANK_MULTICARD_NAME]: RADABANK_MULTICARD_FORMATTED_NAME,
};
