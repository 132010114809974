import { ChangeEvent, FocusEvent, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, InputAdornment, MenuItem, TextField } from '@mui/material';
import { ReactComponent as Clear } from 'assets/icons/clear.svg';
import { ADD_ADDRESS_FIELD_NAME } from 'utils/enums';
import { getIsDisabledApartmentAddressChoice } from 'utils/helpers';
import { useAutofocus } from 'utils/hooks/use-autofocus';
import { ApartmentAddressItemResponse } from 'utils/types';
import { DisabledChoiceAutocompleteNote } from '../DisabledChoiceAutocompleteNote';
import styles from './index.module.scss';

interface ApartmentNativeAutocompleteProps<T> {
	value: T;
	options: T[];
	onChange: (value: T, name: string, isShouldCloseModal: boolean) => void;
	helperText?: string;
	isError?: boolean;
	isLoading?: boolean;
	isOpenModal?: boolean;
	name: string;
	onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
	onClear: () => void;
	debouncedValue?: string;
	minLength?: number;
	portmoneLimit?: number;
}

export const ApartmentNativeAutocomplete = <T extends ApartmentAddressItemResponse<unknown, unknown>>({
	onChange,
	options,
	value,
	isLoading,
	helperText,
	isError,
	name,
	onClear,
	onInputChange,
	isOpenModal,
	debouncedValue,
	minLength = 1,
	portmoneLimit,
	...rest
}: ApartmentNativeAutocompleteProps<T>) => {
	const inputRef = useAutofocus(isOpenModal);

	const [inputValue, setInputValue] = useState(value?.title ?? '');
	const [optionsList, setOptionsList] = useState(options);

	const isDisabledChoice = useMemo(
		() => getIsDisabledApartmentAddressChoice(options, portmoneLimit),
		[options, portmoneLimit]
	);

	useEffect(() => {
		if (name !== ADD_ADDRESS_FIELD_NAME.STREET) {
			setOptionsList(options.filter((item: T) => item.title?.toLowerCase().includes(inputValue?.toLowerCase())));
		}
	}, [name, inputValue]);

	useEffect(() => {
		setOptionsList(options);
	}, [options]);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
		onInputChange(event);

		if (!event.target.value) {
			handleClearInput();
		}
	};

	const handleSelectItem = (name: string, item: T, isShouldCloseModal: boolean) => {
		setInputValue(item.title ?? '');
		onChange(item, name, isShouldCloseModal);
	};

	const handleClearInput = () => {
		onClear();
		setInputValue('');
	};

	const selectOptions = isDisabledChoice ? options : optionsList;

	const isShowEmptyStateWhenDebounce =
		debouncedValue === undefined || (debouncedValue.length >= minLength && debouncedValue === inputValue);

	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<TextField
					{...rest}
					name={name}
					inputRef={inputRef}
					type="text"
					id={`native-${name}`}
					value={inputValue}
					onChange={handleInputChange}
					helperText={isError ? helperText : ''}
					error={isError}
					InputProps={{
						endAdornment: isLoading ? (
							<InputAdornment position="end">
								<CircularProgress color="inherit" size={20} />
							</InputAdornment>
						) : (
							inputValue && <Clear onClick={handleClearInput} />
						),
					}}
					sx={{
						'& .MuiFormHelperText-root': {
							position: 'absolute',
							bottom: '-20px',
						},
					}}
				/>
			</div>
			<Box className={styles.list}>
				{isDisabledChoice && <DisabledChoiceAutocompleteNote />}
				{selectOptions.map((item) => (
					<MenuItem
						disabled={isDisabledChoice && item.title !== inputValue}
						key={item.title}
						onClick={() => handleSelectItem(name, item, false)}
					>
						{item.title}
					</MenuItem>
				))}
				{!!inputValue && isShowEmptyStateWhenDebounce && !optionsList.length && !isLoading && (
					<MenuItem className={styles.empty_state}>Результатів не знайдено</MenuItem>
				)}
			</Box>
		</div>
	);
};
