/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import { IRadabankReducer, RadabankConfirmPaymentModal } from 'utils/types';
import { radabankInitialState } from './reducer';

export const resetRadabankReducerOperation = () => {
	return { ...radabankInitialState };
};

export const updateInfoStatusIdRadabankReducerOperation = (
	state: IRadabankReducer,
	{ payload }: PayloadAction<string>
) => {
	if (state.radabankInternalClient.data) {
		state.radabankInternalClient.data.infostatusid = payload;
	}
};

export const resetRadabankReducerOperationsOperation = (state: IRadabankReducer) => {
	if (state.radabankInternalClient) {
		state.currentCard.operations = radabankInitialState.currentCard.operations;
	}
};

export const resetRadabankStatementsOperation = (state: IRadabankReducer) => {
	state.currentCard.statement = radabankInitialState.currentCard.statement;
};

export const changeCurrentCardOperation = (state: IRadabankReducer, { payload }: PayloadAction<string>) => {
	const newSelectedCard = state.cards.list.find((card) => card.id === payload);

	if (newSelectedCard && newSelectedCard.id !== state.currentCard.id) {
		state.currentCard = { ...radabankInitialState.currentCard, isLoading: true, id: newSelectedCard.id };
	}
};

export const openConfirmRadabankPaymentModalOperation = (
	state: IRadabankReducer,
	{ payload }: PayloadAction<Partial<RadabankConfirmPaymentModal>>
) => {
	state.confirmPaymentModal = { ...state.confirmPaymentModal, ...payload, isOpen: true };
};

export const closeConfirmRadabankPaymentModalOperation = (
	state: IRadabankReducer,
) => {
	state.confirmPaymentModal = { ...radabankInitialState.confirmPaymentModal, isOpen: false };
};
