import { FC, HTMLProps, MouseEvent, useState } from 'react';
import { useHistory } from 'react-router';
import { IconButton } from '@mui/material';
import { ReactComponent as SettingsSVG } from 'assets/icons/tabs/settings.svg';
import classNames from 'classnames';
import {
	activateRadabankBlurredCard,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE, RADABANK_MULTICARD_NAME } from 'utils/constants';
import { RADABANK_BOOLEAN_VALUE, RADABANK_CARD_BLOCK_LEVEL, ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IRadabankInternalCurrentCard, IUserProfileResponse } from 'utils/types';
import { RadabankCardDetails } from '../CardDetails';
import { RadabankCardPreview } from '../CardPreview';
import { RadabankCardPreviewSecured } from '../CardPreviewSecured';
import styles from './index.module.scss';

type RadabankCardFlipProps = HTMLProps<HTMLDivElement> & {
	card: IRadabankInternalCurrentCard;
};

export const RadabankCardFlip: FC<RadabankCardFlipProps> = ({ card, className, children, disabled, ...props }) => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);

	const isCardBlurred = card.blour === RADABANK_BOOLEAN_VALUE.TRUE;
	const isCardBlocked = card && card.blocklevel !== RADABANK_CARD_BLOCK_LEVEL.ACTIVE;

	const [cvv, setCvv] = useState('');
	const [cvvIsLoading, setCvvIsLoading] = useState(false);
	const [isFlipped, setIsFlipped] = useState(false);

	const handleClickCard = () => {
		if (disabled) {
			return;
		}
		const analyticsEvent = isFlipped
			? FIREBASE_EVENT_ANALYTICS_BUTTON.FLIP_CARD_FRONT
			: FIREBASE_EVENT_ANALYTICS_BUTTON.FLIP_CARD_BACK;
		FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_MAIN, analyticsEvent);
		setIsFlipped(!isFlipped);
	};

	const handleClickSettings = (event: MouseEvent) => {
		event.stopPropagation();
		if (disabled) return;
		history.push(ROUTER_URL.RADABANK_CARD_SETTINGS);
	};

	const handleGetCVV = async (cardId: string): Promise<void> => {
		try {
			setCvvIsLoading(true);
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_MAIN,
				FIREBASE_EVENT_ANALYTICS_BUTTON.GET_CVV
			);
			const response = await RadabankService.getCvvCode({
				cardid: cardId,
				userId: (profile as IUserProfileResponse).userId,
			});
			setCvv(response.data.vcode);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setCvvIsLoading(false);
		}
	};

	const handleActivateBlurredCard = async (e: MouseEvent) => {
		e.stopPropagation();
		try {
			dispatch(setAppLoading(true));
			await dispatch(
				activateRadabankBlurredCard({
					cardid: card.id,
					userId: (profile as IUserProfileResponse).userId,
				})
			).unwrap();
			dispatch(showToast({ message: 'Картку успішно активовано!', color: 'success' }));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const isMultiCard = card.name === RADABANK_MULTICARD_NAME;

	return (
		<div
			{...props}
			className={classNames(styles.card, className, {
				[styles.card_flipped]: isFlipped,
				[styles.card_blurred]: isCardBlurred,
				[styles.card_blocked]: isCardBlocked,
			})}
			onClick={handleClickCard}
		>
			<div className={styles.card__inner}>
				{isCardBlurred && (
					<CustomButton onClick={handleActivateBlurredCard} className={styles.card__activate} label="Активувати" />
				)}
				<div
					className={classNames(styles.card__inner_front, {
						[styles.blurred]: isCardBlurred,
					})}
				>
					<RadabankCardPreview isShowBlockedOpacity={false} isMultiCard={isMultiCard} className={styles.card__preview}>
						<RadabankCardDetails cardData={card} isShowCreditDetails={!isFlipped} />
						<IconButton
							onClick={handleClickSettings}
							className={classNames(styles.settings, {
								[styles.multicard]: isMultiCard,
							})}
						>
							<SettingsSVG />
						</IconButton>
					</RadabankCardPreview>
				</div>
				<div className={styles.card__inner_back}>
					<RadabankCardPreviewSecured
						isMulticard={isMultiCard}
						cardData={card}
						cvv={cvv}
						onGetCvv={handleGetCVV}
						isLoading={cvvIsLoading}
					/>
				</div>
			</div>
		</div>
	);
};
