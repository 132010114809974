import { FC, memo, useEffect } from 'react';
import { Checkbox, Typography } from '@mui/material';
import { showToast, useAppDispatch } from 'store';
import { ServiceTariffItem } from 'components/Receipt/PaymentProviderItem/EditServiceAmountToPay/ServiceTariffItem';
import { PROVIDER_TYPE } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { BillService } from 'utils/services';
import { IServiceBillRequestTariffItem, ServiceFeeEntry } from 'utils/types';
import styles from './index.module.scss';

interface ServiceFieldsTariffItemProps {
	tariffItem: IServiceBillRequestTariffItem;
	onSelect: (serviceTariffItem: IServiceBillRequestTariffItem) => void;
	isSelected: boolean;
	isSelectionAllowed?: boolean;
	onChangeFee: (feeEntry: ServiceFeeEntry) => void;
}

export const ServiceFieldsTariffItem: FC<ServiceFieldsTariffItemProps> = memo(
	({ tariffItem, onSelect, isSelected, isSelectionAllowed = false, onChangeFee }) => {
		const dispatch = useAppDispatch();

		useEffect(() => {
			if (tariffItem.amount) {
				getServiceFee(tariffItem.amount);
			}
		}, [tariffItem.amount]);

		const getServiceFee = async (amount: number): Promise<void> => {
			try {
				if (tariffItem.service) {
					const { data } = await BillService.getServiceFee(
						amount,
						tariffItem.service.code,
						PROVIDER_TYPE.PORTMONE,
						true
					);
					onChangeFee?.({ ...data, serviceId: tariffItem.service.id });
				}
			} catch (error) {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		};

		const handleSelectService = () => {
			onSelect(tariffItem);
		};
		return (
			<>
				<div className={styles.header}>
					<Typography variant="subtitle1" alignSelf="flex-start" marginBottom="var(--spacing-3)">
						{tariffItem.payee._}:
					</Typography>
					{isSelectionAllowed && <Checkbox onChange={handleSelectService} checked={isSelected} />}
				</div>
				{!!tariffItem.description && (
					<Typography sx={{ alignSelf: 'flex-start' }} variant="caption" marginBottom="var(--spacing-2)">
						{tariffItem.description}
					</Typography>
				)}
				{tariffItem.list.map((tariffItemListItem, tariffItemIndex) => (
					<ServiceTariffItem key={`${tariffItemListItem.name}-${tariffItemIndex}`} data={tariffItemListItem} />
				))}
			</>
		);
	}
);
