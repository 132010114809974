import { useState } from 'react';
import { TypeOrNull } from 'utils/types';

export const useOpen = <T>(isOpenDefault = false, initialData: TypeOrNull<T> = null) => {
	const [isOpen, setIsOpen] = useState(isOpenDefault);
	const [isPresented, setIsPresented] = useState(false);
	const [data, setData] = useState<TypeOrNull<T>>(initialData);

	const handleOpen = () => {
		setIsOpen(true);
	};
	const handleOpenWithData = (values: TypeOrNull<T>) => {
		setData(values);
		handleOpen();
	}

	const handleClose = () => {
		setIsOpen(false);
		setIsPresented(false);
		setData(initialData)
	};

	const handleDidPresent = () => {
		setIsPresented(true);
	};

	return { isOpen, handleClose, handleOpen, handleOpenWithData, handleDidPresent, isPresented, data };
};
