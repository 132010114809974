import { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Browser } from '@capacitor/browser';
import { AxiosResponse } from 'axios';
import { getApartmentList } from 'store/bill/actions';
import { resetReceipt } from 'store/bill/reducer';
import { getApartmentsSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import PaymentResult from 'components/PaymentResult';
import { Loader } from 'components/shared';
import { DELAY_STATUS_GET } from 'utils/constants';
import { PAYMENT_STATUS, PROVIDER_TYPE, ROUTER_URL } from 'utils/enums';
import {
	checkBillStatusIsReturned,
	confirm3DS,
	convertCoinsToUAH,
	decryptData,
	getApartmentTitle,
	getBillIsNeedConfirm3DS,
	getErrorMessage,
	getReceiptBillsListRoute,
	getReceiptRoute,
} from 'utils/helpers';
import { BillService } from 'utils/services';
import {
	IApartmentResponse,
	IBillApartments,
	IBillsReportResponse,
	ICheckPaymentStatusResponse,
	IUserProfileResponse,
} from 'utils/types';

type PaymentStatusPageParams = {
	// as hash
	apartmentAccountId: string;
	billId: string;
};

export const PaymentStatusPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const params = useParams<PaymentStatusPageParams>();

	const profile: IUserProfileResponse | null = useAppSelector(getUserProfileSelector);
	const apartments: IBillApartments = useAppSelector(getApartmentsSelector);

	const isBrowserOpen = useRef(false);

	const [billsStatusList, setBillsStatusList] = useState<ICheckPaymentStatusResponse[]>([]);
	const [billIdsList, setBillIdsList] = useState<string[]>([]);
	const [billData, setBillData] = useState<IBillsReportResponse | null>(null);
	const [currentApartment, setCurrentApartment] = useState<IApartmentResponse | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [totalAmount, setTotalAmount] = useState(0);

	useEffect(() => {
		dispatch(resetReceipt());
		if (params.billId) {
			getBillInfo(params.billId);
		} else {
			handleRedirectToError();
		}
	}, []);

	useEffect(() => {
		if (profile?.userId) {
			if (apartments.list.length) {
				const apartmentAccountId = decryptData(params.apartmentAccountId);
				const currentApartment = apartments.list.find((item) => item.apartmentAccountId === +apartmentAccountId);
				if (currentApartment) {
					setCurrentApartment(currentApartment);
				} else {
					history.push(ROUTER_URL.NOT_FOUND);
					dispatch(showToast({ message: 'Помешкання не знайдено' }));
				}
			} else {
				getUserApartments(profile.userId);
			}
		}
	}, [profile, apartments.list.length]);

	useEffect(() => {
		let interval: NodeJS.Timeout | null = null;

		if (currentApartment) {
			if (billIdsList.length === billsStatusList.length && billsStatusList.every(checkBillStatusIsReturned)) {
				setIsLoading(false);
			} else {
				if (!isLoading) {
					setIsLoading(true);
				}

				const confirm3DSBill = getBillIsNeedConfirm3DS(billsStatusList);
				if (confirm3DSBill?.returnUrl_3DS && !isBrowserOpen.current) {
					confirm3DS(confirm3DSBill.returnUrl_3DS);
					isBrowserOpen.current = true;
				}
				if (billIdsList.length) {
					interval = setInterval(() => {
						checkPaymentStatus(billIdsList);
					}, DELAY_STATUS_GET);
				}
			}
		}

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [billIdsList.length, billsStatusList, currentApartment]);

	useEffect(() => {
		if (billsStatusList.every(checkBillStatusIsReturned)) {
			const totalAmount = billsStatusList
				.filter((item) => item.status === PAYMENT_STATUS.PAID)
				.reduce((sum, billStatusResponse) => sum + convertCoinsToUAH(billStatusResponse.amount), 0);
			setTotalAmount(totalAmount);
		}
	}, [billsStatusList]);

	useEffect(() => {
		handleAddBrowserListeners();

		return () => {
			handleRemoveBrowserListeners();
		};
	}, []);

	const handleAddBrowserListeners = async () => {
		try {
			await Browser.addListener('browserFinished', () => {
				isBrowserOpen.current = false;
			});
		} catch (error) {
			console.error('handleAddBrowserListeners error', error);
		}
	};

	const handleRemoveBrowserListeners = async () => {
		try {
			await Browser.removeAllListeners();
		} catch (error) {
			console.error('handleRemoveBrowserListeners error', error);
		}
	};

	const handleRedirectToError = () => {
		dispatch(showToast({ message: 'Платіж не знайдено. Перевірте, будь ласка, архів платежів' }));
		history.push(ROUTER_URL.NOT_FOUND);
	};

	const getBillInfo = async (billId: string): Promise<void> => {
		try {
			const response: AxiosResponse<IBillsReportResponse> = await BillService.getBillsReport(billId);
			const billIdsList = [response.data.id, ...response.data.relatedBills.map((item) => item.id)];
			setBillData(response.data);
			setBillIdsList(billIdsList);
		} catch (error) {
			handleRedirectToError();
		}
	};

	const getUserApartments = async (userId: string): Promise<void> => {
		try {
			await dispatch(getApartmentList(userId)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
			history.push(ROUTER_URL.ERROR);
		}
	};

	const checkPaymentStatus = async (billIdsList: string[]): Promise<void> => {
		try {
			const promiseList = billIdsList.map((item) =>
				BillService.checkPaymentStatus({
					billId: item,
				})
			);
			const responseList = await Promise.all(promiseList);
			setBillsStatusList(responseList.map((item) => item.data));
		} catch (error) {
			handleRedirectToError();
		}
	};

	return (
		<div className="page">
			{currentApartment && !!billsStatusList.length && (
				<PaymentResult
					isLoading={isLoading}
					description={getApartmentTitle(currentApartment)}
					rejectedUrl={getReceiptRoute(params.apartmentAccountId)}
					archiveUrl={getReceiptBillsListRoute(params.apartmentAccountId)}
					totalAmount={totalAmount}
					isSuccess={billsStatusList.some((item) => item.status === PAYMENT_STATUS.PAID)}
					isOnlySingleReceipt={
						billData?.subBills.every((item) => item.provider !== PROVIDER_TYPE.PORTMONE) &&
						billData?.relatedBills.every((item) => item.subBills[0].provider !== PROVIDER_TYPE.PORTMONE)
					}
				/>
			)}
			{((!apartments.list.length && apartments.isLoading) || !billsStatusList.length) && <Loader />}
		</div>
	);
};
