import { FC, useState } from 'react';
import {
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { Navbar } from 'components/Navbar';
import { RadabankCardTransferBetweenOwnCardsForm } from 'components/RadabankCard/TransferBetweenOwnCards/FormContent';
import { OperationDetails } from 'components/RadabankCard/TransferBetweenOwnCards/OperationDetails';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { convertUAHToCoins, getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IRadabankFeeResponse, RadabankTransferBetweenOwnCardsData, TypeOrNull } from 'utils/types';

export const RadabankCardTransferBetweenOwnCardsPage: FC = () => {
	const dispatch = useAppDispatch();
	const [isShowDetails, setIsShowDetails] = useState(false);
	const [feeData, setFeeData] = useState<TypeOrNull<IRadabankFeeResponse>>(null);
	const [operationData, setOperationData] = useState<TypeOrNull<RadabankTransferBetweenOwnCardsData>>(null);

	const currentCard = useAppSelector(getRadabankCurrentCardSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const handleSubmit = async ({ cardFrom, cardTo, amount }: RadabankTransferBetweenOwnCardsData) => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_TOP_UP_FROM_OWN,
				FIREBASE_EVENT_ANALYTICS_BUTTON.NEXT
			);
			if (cardFrom.id === cardTo.id) {
				dispatch(showToast({ message: 'Картки відправника і отримувача співпадають. Перевірте обрані картки' }));
				return;
			}
			dispatch(setAppLoading(true));

			const amountInCoins = `${convertUAHToCoins(amount)}`;

			const response = await RadabankService.getCardFee({
				userId: profile?.userId || '',
				cardid: cardFrom.id,
				card: cardTo.fullnumber,
				amount: amountInCoins,
			});
			setFeeData(response.data);
			setIsShowDetails(true);
			setOperationData({ cardFrom, cardTo, amount: amountInCoins });
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleCloseDetails = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_TOP_UP_FROM_OWN,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		setIsShowDetails(false);
	};

	if (!currentCard) return <Navbar />;

	return (
		<>
			<RadabankCardTransferBetweenOwnCardsForm onSubmit={handleSubmit} />
			{isShowDetails && !!feeData && !!operationData && (
				<OperationDetails feeData={feeData} operationData={operationData} onGoBack={handleCloseDetails} />
			)}
		</>
	);
};
