import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import {
	approveRadabankPaymentsBasket,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { ConfirmOTPCode } from 'components/Auth';
import { ArrowBackButton, CustomButton, CustomModal } from 'components/shared';
import { RADABANK_CONFIRM_PAYMENT_OTP_TITLE, RADABANK_OTP_LENGTH } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { getErrorMessage } from 'utils/helpers';
import { useAndroidBackButton } from 'utils/hooks/use-close-on-back-button';
import styles from './index.module.scss';

interface ApprovePaymentModalProps {
	isOpen: boolean;
	onClose: () => void;
	onDidPresent?: () => void;
	isPresented?: boolean;
	paymentIds: string[];
}

export const ApprovePaymentModal: FC<ApprovePaymentModalProps> = ({
	isOpen,
	onClose,
	onDidPresent,
	isPresented,
	paymentIds,
}) => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const profile = useAppSelector(getUserProfileSelector);

	const [otp, setOtp] = useState('');

	const handleChangeOTP = (value: string): void => {
		setOtp(value);
	};
	const handleCloseModal = () => {
		onClose();
		setOtp('');
	};

	const handleSubmit = async () => {
		try {
			dispatch(setAppLoading(true));
			await dispatch(
				approveRadabankPaymentsBasket({
					id: paymentIds,
					otp,
					userId: profile?.userId || '',
				})
			).unwrap();
			onClose();
			history.push(ROUTER_URL.RADABANK_CARD_OPERATION_BANNER);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	useAndroidBackButton(handleCloseModal);

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={handleCloseModal}
			onDidPresent={onDidPresent}
			isFullHeight
			isCloseHeader={false}
			animated
		>
			<div className={styles.content}>
				<ArrowBackButton onClick={onClose} />
				<div className="auth-content">
					<ConfirmOTPCode
						title={RADABANK_CONFIRM_PAYMENT_OTP_TITLE}
						phone={profile?.phone ?? ''}
						value={otp}
						onChange={handleChangeOTP}
						isValid
						length={RADABANK_OTP_LENGTH}
						onSubmit={handleSubmit}
						isShouldFocus={isPresented}
					/>
				</div>
				<CustomButton label="Продовжити" onClick={handleSubmit} disabled={otp.length !== RADABANK_OTP_LENGTH} />
			</div>
		</CustomModal>
	);
};
