import {
	FC,
	ForwardedRef,
	forwardRef,
	HTMLAttributes,
	KeyboardEvent,
	Ref,
	useEffect,
	useState,
} from 'react';
import { IonSpinner } from '@ionic/react';
// MUI
import { Autocomplete, AutocompleteProps, Box, MenuItem, TextField } from '@mui/material';
// Assets
import { ReactComponent as DownIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as Clear } from 'assets/icons/clear.svg';
import classNames from 'classnames';
import { getIsDisabledApartmentAddressChoice } from 'utils/helpers';
import { DisabledChoiceAutocompleteNote } from '../DisabledChoiceAutocompleteNote';
import s from './index.module.scss';

interface AddressAutocompleteProps<T> extends Omit<AutocompleteProps<T, any, any, any, any>, 'renderInput'> {
	options: T[];
	isClear?: boolean;
	renderOptions?: any;
	label?: string;
	name?: string;
	isError?: boolean;
	helperText?: string;
	inputRef?: Ref<HTMLElement>;
	inputProps?: any;
	placeholder?: string;
	portmoneLimit?: number;
}

export const AddressAutocomplete: FC<AddressAutocompleteProps<any>> = ({
	placeholder = '',
	isClear = false,
	label,
	name,
	isError,
	helperText,
	loading = false,
	inputRef = null,
	value,
	disabled,
	inputProps,
	getOptionLabel,
	options,
	portmoneLimit,
	filterOptions,
	readOnly,
	...rest
}) => {
	const [isShowClear, setIsShowClear] = useState(false);
	const [inputValue, setInputValue] = useState(getOptionLabel?.(value) ?? '');

	useEffect(() => {
		setIsShowClear((!!inputValue && !loading) || (isClear !== undefined ? isClear : !!value || !!inputValue));
	}, [isClear, value, loading, disabled, !!inputValue]);

	const additionalInputProps =
		// eslint-disable-next-line no-nested-ternary
		!loading && ((!value && !inputValue) || readOnly)
			? { endAdornment: <DownIcon className={classNames(s.spinner, s.arrow)} /> }
			: loading
			? { endAdornment: <IonSpinner className={classNames('spinner-icon', s.spinner)} name="crescent" /> }
			: {};

	const handlePreventEnterClickWithoutOptions = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && !options.length) {
			e.stopPropagation();
		}
	};

	const isDisabledChoice = getIsDisabledApartmentAddressChoice(options, portmoneLimit);

	return (
		<Autocomplete
			className={s.autocomplete}
			options={options}
			onInputChange={(_, value) => setInputValue(value)}
			autoHighlight={!isDisabledChoice}
			{...rest}
			readOnly={readOnly}
			getOptionLabel={getOptionLabel}
			value={value}
			renderInput={({ InputProps: { className, ...InputProps }, ...params }) => (
				<TextField
					InputProps={{
						className: classNames(s.input, className, {
							[s.disabled]: disabled,
						}),
						...InputProps,
						...inputProps,
						...additionalInputProps,
						onKeyDown: handlePreventEnterClickWithoutOptions,
					}}
					name={name}
					helperText={!disabled && helperText}
					error={!disabled && isError}
					placeholder={placeholder}
					label={label}
					inputRef={inputRef}
					{...params}
					value={inputValue}
				/>
			)}
			filterOptions={isDisabledChoice ? (options) => options : filterOptions}
			renderOption={(props, option, state) => {
				const isOptionDisabled = isDisabledChoice && state.inputValue.toLowerCase() !== option.title.toLowerCase();

				const { key, ...otherProps } = props;
				return (
					<MenuItem
						key={key}
						{...otherProps}
						selected={state.selected}
						aria-disabled={props['aria-disabled'] || isOptionDisabled}
						disabled={isOptionDisabled}
					>
						{getOptionLabel?.(option)}
					</MenuItem>
				);
			}}
			ListboxComponent={ListboxComponent}
			ListboxProps={{ 'aria-disabled': isDisabledChoice }}
			disabled={disabled}
			limitTags={3}
			disableClearable={!isShowClear || disabled}
			clearIcon={<Clear />}
			freeSolo
		/>
	);
};

const ListboxComponent = forwardRef<HTMLUListElement, HTMLAttributes<HTMLElement>>(
	({ children, ...props }, ref: ForwardedRef<HTMLUListElement>) => {
		return (
			<Box component="ul" {...props} aria-disabled={false} ref={ref}>
				{!!props['aria-disabled'] && <DisabledChoiceAutocompleteNote />}
				{children}
			</Box>
		);
	}
);
