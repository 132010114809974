import { FC, HTMLProps } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-down.svg';
import classNames from 'classnames';
import { RADABANK_MULTICARD_NAME } from 'utils/constants';
import { RADABANK_CARD_BLOCK_LEVEL, ROUTER_URL } from 'utils/enums';
import { getAmountTitle } from 'utils/helpers';
import { IRadabankInternalCurrentCard, TypeOrNull } from 'utils/types';
import styles from './index.module.scss';

interface RadabankCardDetailsProps extends HTMLProps<HTMLDivElement> {
	isShowCreditDetails?: boolean;
	cardData: TypeOrNull<IRadabankInternalCurrentCard>;
}

export const RadabankCardDetails: FC<RadabankCardDetailsProps> = ({
	className,
	cardData,
	isShowCreditDetails = false,
}) => {
	const history = useHistory();

	const isMultiCard = cardData?.name === RADABANK_MULTICARD_NAME;

	const isBlocked = cardData && cardData.blocklevel !== RADABANK_CARD_BLOCK_LEVEL.ACTIVE;

	const handleNavigateToCreditDetails = () => {
		if (isShowCreditDetails) {
			history.push(ROUTER_URL.RADABANK_CARD_OVERDRAFT_DETAILS);
		}
	};

	const cardCreditLimit = Number(cardData?.overdraft);

	const cardAmountOther = isBlocked ? 0 : Number(cardData?.amountother);

	const cardCreditAmount = cardCreditLimit - cardAmountOther;

	return (
		cardData && (
			<div
				className={classNames(styles.content, className, {
					[styles.multicard]: isMultiCard,
				})}
			>
				<Typography color="inherit" variant="h5">
					{getAmountTitle(cardData.availamount)}
				</Typography>
				<div className={classNames(styles.row_amount, { [styles.own_credit]: isShowCreditDetails })}>
					<Typography color="inherit" variant="body2" whiteSpace="nowrap">
						Власні кошти
					</Typography>
					<Typography color="inherit" variant="body2" whiteSpace="nowrap">
						{getAmountTitle(cardData.cardrestmine)}
					</Typography>
				</div>
				<div
					className={classNames(styles.row_amount, { [styles.credit]: isShowCreditDetails })}
					onClick={handleNavigateToCreditDetails}
				>
					<Typography color="inherit" fontWeight="700" variant="body2" whiteSpace="nowrap">
						{isBlocked ? 'Кредитний ліміт' : 'Кредитні кошти'}
					</Typography>
					<Typography color="inherit" fontWeight="700" variant="body2" whiteSpace="nowrap">
						{getAmountTitle(cardCreditAmount)}
					</Typography>
					{isShowCreditDetails && <ArrowIcon className={styles.arrow} />}
				</div>
				<Typography color="inherit" variant="subtitle2" marginTop="auto">
					{cardData.cardholder}
				</Typography>
			</div>
		)
	);
};
