import { FC, memo, useMemo } from 'react';
import { MenuItem, Typography } from '@mui/material';
import classNames from 'classnames';
import { getAmountTitle, getCreditCardIcon, getRadabankFormattedCardName } from 'utils/helpers';
import { IRadabankInternalCurrentCard } from 'utils/types';
import styles from './index.module.scss';

interface RadabankCardForTransferOptionProps {
	card: IRadabankInternalCurrentCard;
	isSelected?: boolean;
	onSelect?: (card: IRadabankInternalCurrentCard) => void;
	className?: string;
	disableRipple?: boolean;
	isHideAmount?: boolean;
}

export const RadabankCardForTransferOption: FC<RadabankCardForTransferOptionProps> = memo(
	({ card, onSelect, isSelected = false, className = '', disableRipple, isHideAmount = false }) => {
		const handleSelectCard = () => {
			onSelect?.(card);
		};

		const CardIcon = useMemo(() => getCreditCardIcon(card.name, card.number), [card.name, card.number]);

		return (
			<MenuItem
				className={classNames(styles.item, className, {
					[styles.active]: isSelected,
				})}
				onClick={handleSelectCard}
				disableTouchRipple={disableRipple}
				disableRipple={disableRipple}
			>
				<div className={styles.item__icon}>
					<CardIcon />
				</div>
				<div className={styles.item__details}>
					<Typography variant="subtitle2">{getRadabankFormattedCardName(card.name)}</Typography>
					{!isHideAmount && <Typography variant="body2">{getAmountTitle(card.availamount)}</Typography>}
				</div>
				<Typography variant="body2" className={styles.item__pan}>
					**** {card.number.slice(-4)}
				</Typography>
			</MenuItem>
		);
	}
);
