/* eslint-disable no-unused-expressions */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { CustomButton, Loader } from 'components/shared';
import {
	DEFAULT_ADD_SERVICE_VALUE,
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	PORTMONE_AMOUNT_FIELD_NAME,
} from 'utils/constants';
import { BILL_TYPE, ERROR_CODE, ERROR_MESSAGE, LOCALE, ROUTER_URL } from 'utils/enums';
import {
	checkIsAmountServiceField,
	convertUAHToCoins,
	findServiceProviderAccountIdValue,
	flatServiceBillRequestData,
	getAmountTitle,
	getContractNumberFieldName,
	getCreateBillRequestServiceFieldsData,
	getCreateBillServiceRequestError,
	getErrorMessage,
	getErrorResponse,
	getServicesFieldsList,
	getServicesOtherPortmoneAmountFromTariffItems,
	getServiceTariffItemsList,
	sortServicesFieldsByFieldsNames,
} from 'utils/helpers';
import { BillService, FirebaseAnalytics } from 'utils/services';
import {
	IAddServiceFieldValueRequest,
	IBillCreateRequestArrayDataItem,
	ICreateBillRequest,
	IGetServiceDataResponse,
	IOtherServiceProviderServiceFieldsData,
	IServiceBillError,
	IServiceBillRequestData,
	IServiceBillRequestTariffItem,
	IServiceFieldResponse,
	IServicesItemResponse,
	IUpdateServiceFieldValueRequest,
	IUserProfileResponse,
	ServiceFeeEntry,
} from 'utils/types';
import { ProviderServiceDetails } from '../ServiceDetails';
import { ProviderServiceFieldsList } from '../ServiceFieldsList';
import { ServiceFieldsTariffItem } from '../ServiceFieldsTariffItem';
import { ProviderServiceHeader } from '../ServiceHeader';

interface ServiceBillRequestFieldsProps {
	serviceData: IGetServiceDataResponse;
	serviceItemData: IServicesItemResponse;
	onChange: (value: IOtherServiceProviderServiceFieldsData) => void;
	onSubmit: () => void;
	onClickBack: () => void;
	onGetBillRequestData?: (value: IServiceBillRequestData | null) => void;
	selectedServicesToPay?: IBillCreateRequestArrayDataItem[];
	onChangeSelectedServices: (service: IBillCreateRequestArrayDataItem) => void;
	onChangeFee: (feeEntry: ServiceFeeEntry) => void;
}

const isValidBillRequestData = (
	billRequestData: IServiceBillRequestData | null
): billRequestData is IBillCreateRequestArrayDataItem =>
	!!billRequestData && !Array.isArray(billRequestData) && !('error' in billRequestData);

export const ServiceBillRequestFields: FC<ServiceBillRequestFieldsProps> = ({
	serviceData,
	onChange,
	serviceItemData,
	onSubmit,
	onClickBack,
	onGetBillRequestData,
	selectedServicesToPay,
	onChangeSelectedServices,
	onChangeFee,
}) => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const profile = useSelector(getUserProfileSelector);

	const [serviceFieldsList, setServiceFieldsList] = useState<IServiceFieldResponse[]>([]);
	const [serviceFieldsValues, setServiceFieldsValues] = useState<IAddServiceFieldValueRequest[]>([]);
	const [visibleFieldsList, setVisibleFieldsList] = useState<IServiceFieldResponse[]>([]);
	const [billRequestData, setBillRequestData] = useState<IServiceBillRequestData | null>(null);
	const [isValid, setIsValid] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isShowDetails, setIsShowDetails] = useState(false);

	const isMultipleBillsData = Array.isArray(billRequestData);

	const isFormDisabled =
		!isValid ||
		isLoading ||
		(isMultipleBillsData && !selectedServicesToPay?.filter((item) => Number(item.tariffItem.amount) > 0).length);

	const tariffItemsList: IServiceBillRequestTariffItem[] = useMemo(() => {
		if (Array.isArray(billRequestData)) {
			return flatServiceBillRequestData(billRequestData).map((item) => ({
				payee: item.tariffItem.payee,
				list: getServiceTariffItemsList(item.tariffItem.tariff_items),
				description: item.tariffItem.description,
				service: item.serviceInfo,
				amount: convertUAHToCoins(item.tariffItem.amount),
			}));
		}
		if (
			billRequestData &&
			!('error' in billRequestData) &&
			billRequestData.tariffItem?.tariff_items &&
			Object.keys(billRequestData.tariffItem.tariff_items).length
		) {
			return [
				{
					payee: billRequestData.tariffItem.payee,
					list: getServiceTariffItemsList(billRequestData.tariffItem.tariff_items),
					description: billRequestData.tariffItem.description,
				},
			];
		}
		return [];
	}, [billRequestData]);

	useEffect(() => {
		if (serviceData.serviceId) {
			getCurrentServiceFields(serviceData.serviceId);
		}
	}, [serviceData.serviceId]);

	useEffect(() => {
		if (serviceFieldsList.length && !billRequestData) {
			const visibleServiceFieldsListPayload = getServicesFieldsList(serviceFieldsList, serviceData.dataObject);
			setVisibleFieldsList(visibleServiceFieldsListPayload);
		}
	}, [serviceFieldsList.length, billRequestData]);

	useEffect(() => {
		if (onGetBillRequestData) {
			onGetBillRequestData(billRequestData);
		}
	}, [billRequestData]);

	useEffect(() => {
		const serviceProviderFieldsData = serviceFieldsValues.reduce(
			(acc, item) => ({
				...acc,
				[item.name]: checkIsAmountServiceField(item) ? convertUAHToCoins(item.value).toString() : item.value,
			}),
			{} as IOtherServiceProviderServiceFieldsData
		);
		if (Object.keys(serviceProviderFieldsData).length) {
			onChange(serviceProviderFieldsData);
		}
	}, [serviceFieldsValues]);

	const getServiceFieldsValuesList = (
		serviceFieldsList: IServiceFieldResponse[],
		portmoneAmountToPay?: string
	): IAddServiceFieldValueRequest[] =>
		serviceFieldsList.map((item) => {
			const existsFieldValue = serviceFieldsValues.find((serviceFieldValue) => serviceFieldValue.name === item.name);
			const payload = existsFieldValue || {
				...DEFAULT_ADD_SERVICE_VALUE,
				serviceId: serviceData.serviceId,
				name: item.name,
				userId: (profile as IUserProfileResponse).userId,
			};
			if (checkIsAmountServiceField(item) && portmoneAmountToPay) {
				payload.value = portmoneAmountToPay;
			}
			return payload;
		});

	const getCurrentServiceFields = async (serviceId: string): Promise<void> => {
		try {
			setIsLoading(true);
			const fieldsResponse: AxiosResponse<IServiceFieldResponse[]> = await BillService.getServiceFields({
				serviceId,
				locale: LOCALE.UK,
			});
			const serviceFieldsList = getServicesFieldsList(fieldsResponse.data, serviceData.dataObject);
			setServiceFieldsList(sortServicesFieldsByFieldsNames(fieldsResponse.data));
			setServiceFieldsValues(getServiceFieldsValuesList(serviceFieldsList));
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			if (errorResponse?.errorData?.code === ERROR_CODE.SERVICE_FIELDS_NOT_FOUND) {
				dispatch(showToast({ message: ERROR_MESSAGE.SERVICE_FIELDS_NOT_FOUND }));
			}
			dispatch(showToast({ message: getErrorMessage(error) }));
			history.replace(ROUTER_URL.NOT_FOUND);
		} finally {
			setIsLoading(false);
		}
	};

	const createBillRequest = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const contractNumber = findServiceProviderAccountIdValue(serviceFieldsValues, serviceData);
			if (contractNumber) {
				const reqBody: ICreateBillRequest = {
					payeeId: serviceData.dataObject.payeeId,
					serviceFieldsData: getCreateBillRequestServiceFieldsData(serviceData.dataObject, serviceFieldsValues),
					billType: serviceData.billType,
				};
				const response = await BillService.createBillRequest(reqBody);

				if (Array.isArray(response.data) && response.data.every((item) => !Array.isArray(item) && 'error' in item)) {
					const [firstItem] = response.data as IServiceBillError[];

					if (Array.isArray(firstItem)) return;

					dispatch(showToast({ message: firstItem.error as string }));
					return;
				}

				if (
					serviceData.billType === BILL_TYPE.FIRST ||
					(serviceData.billType === BILL_TYPE.SECOND &&
						!Array.isArray(response.data) &&
						(response.data as IBillCreateRequestArrayDataItem)?.tariffItem.tariff_items &&
						!Object.keys((response.data as IBillCreateRequestArrayDataItem)?.tariffItem.tariff_items).length)
				) {
					serviceData.sumFrom && (serviceFieldsList[1].minAmount = serviceData.sumFrom / 100);
					serviceData.sumTo && (serviceFieldsList[1].maxAmount = serviceData.sumTo / 100);
					setVisibleFieldsList(serviceFieldsList);
					const amount = (response.data as IBillCreateRequestArrayDataItem)?.tariffItem.amount;
					setServiceFieldsValues(getServiceFieldsValuesList(serviceFieldsList, Number(amount) ? amount : ''));
				}
				if (
					(serviceData.billType === BILL_TYPE.SECOND &&
						!Array.isArray(response.data) &&
						(response.data as IBillCreateRequestArrayDataItem)?.tariffItem.tariff_items &&
						Object.keys((response.data as IBillCreateRequestArrayDataItem)?.tariffItem).length) ||
					serviceData.billType === BILL_TYPE.THIRD
				) {
					const serviceFieldsValues = getServiceFieldsValuesList(serviceFieldsList);
					const fieldsValuesList = serviceFieldsValues.find(checkIsAmountServiceField)
						? serviceFieldsValues
						: [
								...serviceFieldsValues,
								{
									...DEFAULT_ADD_SERVICE_VALUE,
									serviceId: serviceData.serviceId,
									name: PORTMONE_AMOUNT_FIELD_NAME,
									userId: (profile as IUserProfileResponse).userId,
								},
						  ];
					const servicesFieldsValuesPayload: IAddServiceFieldValueRequest[] = fieldsValuesList.map((item) =>
						checkIsAmountServiceField(item)
							? {
									...item,
									value: getServicesOtherPortmoneAmountFromTariffItems(response.data).toFixed(2),
							  }
							: item
					);
					setServiceFieldsValues(servicesFieldsValuesPayload);
				}
				if (
					serviceData.billType === BILL_TYPE.SECOND &&
					!Array.isArray(response.data) &&
					(response.data as IBillCreateRequestArrayDataItem)?.tariffItem.amount &&
					!Number((response.data as IBillCreateRequestArrayDataItem)?.tariffItem?.amount) &&
					!Object.keys((response.data as IBillCreateRequestArrayDataItem)?.tariffItem.tariff_items).length
				) {
					dispatch(
						showToast({
							message: `${ERROR_MESSAGE.SERVICE_BILL_REQUEST_PAID_ALREADY}, або сплатіть довільну суму`,
							duration: 5000,
							color: 'light',
						})
					);
				}
				setBillRequestData(response.data);
			}
		} catch (error) {
			dispatch(showToast({ message: getCreateBillServiceRequestError(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleSubmit = (): void => {
		if (isFormDisabled) return;

		if (!billRequestData) {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SERVICES,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONTINUE
			);
			createBillRequest();
		} else {
			if (!isMultipleBillsData) {
				setIsShowDetails(true);
			}
			onSubmit();
		}
	};

	const handleClickBack = (): void => {
		if (isShowDetails || !billRequestData) {
			setIsShowDetails(false);
			onClickBack();
		} else {
			const visibleServiceFieldsListPayload = getServicesFieldsList(serviceFieldsList, serviceData.dataObject);
			setVisibleFieldsList(visibleServiceFieldsListPayload);
			setServiceFieldsValues(getServiceFieldsValuesList(visibleServiceFieldsListPayload));
			setBillRequestData(null);
		}
	};

	const handleSelectService = useCallback(
		(serviceTariffItem: IServiceBillRequestTariffItem) => {
			if (!Array.isArray(billRequestData)) {
				return;
			}

			const serviceByPayee = billRequestData.find(
				(item) => !('error' in item) && item.tariffItem.payee._ === serviceTariffItem.payee._
			) as IBillCreateRequestArrayDataItem | undefined;

			if (!serviceByPayee) return;

			onChangeSelectedServices(serviceByPayee);
		},
		[billRequestData]
	);

	return (
		<>
			<ProviderServiceHeader
				onClickBack={handleClickBack}
				title={serviceItemData.title}
				icon={serviceItemData?.imageUrl ?? ''}
			/>
			{!!(
				visibleFieldsList.length &&
				!isShowDetails &&
				(billRequestData
					? serviceData.billType === BILL_TYPE.FIRST ||
					  (!Array.isArray(billRequestData) &&
							(billRequestData as IBillCreateRequestArrayDataItem)?.tariffItem.tariff_items &&
							!Object.keys((billRequestData as IBillCreateRequestArrayDataItem)?.tariffItem.tariff_items).length)
					: !billRequestData)
			) && (
				<ProviderServiceFieldsList
					onSubmit={handleSubmit}
					fieldsList={visibleFieldsList}
					values={serviceFieldsValues}
					serviceId={serviceData.serviceId}
					setIsValid={(value: boolean) => setIsValid(value)}
					onChange={(values: IAddServiceFieldValueRequest[] | IUpdateServiceFieldValueRequest[]) =>
						setServiceFieldsValues(values as IAddServiceFieldValueRequest[])
					}
					providerAccountIdFieldName={getContractNumberFieldName(serviceData)}
					isFieldsDisabled={
						serviceData.billType !== BILL_TYPE.THIRD && visibleFieldsList.length === serviceFieldsList.length
						// ||
						// (!!billRequestData?.tariff_items && !Object.keys(billRequestData?.tariff_items).length)
					}
				/>
			)}
			{!!(billRequestData && serviceData.billType !== BILL_TYPE.FIRST && !isShowDetails) &&
				tariffItemsList.map((tariffItem, index) => (
					<ServiceFieldsTariffItem
						onChangeFee={onChangeFee}
						key={`tarif-list-${tariffItem.payee.$.id}-${index}`}
						tariffItem={tariffItem}
						onSelect={handleSelectService}
						isSelected={!!selectedServicesToPay?.some((service) => service.tariffItem.payee._ === tariffItem.payee._)}
						isSelectionAllowed={Array.isArray(billRequestData)}
					/>
				))}
			{isShowDetails && (
				<ProviderServiceDetails
					data={isValidBillRequestData(billRequestData) ? billRequestData : serviceData}
					providerAccountId={findServiceProviderAccountIdValue(serviceFieldsValues, serviceData)}
					amount={getAmountTitle(
						// eslint-disable-next-line no-nested-ternary
						billRequestData
							? getServicesOtherPortmoneAmountFromTariffItems(billRequestData) ||
							  serviceFieldsValues.find(checkIsAmountServiceField)?.value
								? +(serviceFieldsValues.find(checkIsAmountServiceField) as IAddServiceFieldValueRequest).value
								: 0
							: serviceFieldsValues.find(checkIsAmountServiceField)?.value || 0
					)}
				/>
			)}

			{!!visibleFieldsList.length && !isShowDetails && (
				<CustomButton disabled={isFormDisabled} label="Продовжити" onClick={handleSubmit} />
			)}
			{isLoading && <Loader />}
		</>
	);
};
