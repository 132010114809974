import { FC } from 'react';
import { ReactComponent as AddCircleSVG } from 'assets/icons/add-circle.svg';
import { CustomButton } from 'components/shared';
import { PaymentMethodFeeWrapper } from '../FeeWrapper';
import styles from './index.module.scss';

type PayByAnotherCardProps = {
	fee: number;
	onClick: () => void;
	disabled: boolean;
};

export const PayByAnotherCardButton: FC<PayByAnotherCardProps> = ({ fee, onClick, disabled }) => {
	return (
		<PaymentMethodFeeWrapper fee={fee}>
			<CustomButton
				className={styles.button}
				fill="outline"
				label="Інша банківська картка"
				startIcon={<AddCircleSVG />}
				onClick={onClick}
				disabled={disabled}
			/>
		</PaymentMethodFeeWrapper>
	);
};
