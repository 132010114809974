export const WAITING_PROVIDER_DATA_HINT_DATA = {
	title: 'Очікуємо дані від усіх КП',
	subTitle: 'Ви можете сплатити доступні або очікувати',
};

export const WAITING_PROVIDER_DATA_HINT_MODAL_DATA = {
	title: 'Інформація',
	description: [
		// eslint-disable-next-line max-len
		'Дані надходять від постачальників комунальних послуг 10–15 числа кожного місяця. Щойно комунальне підприємство надішле інформацію – дані оновляться, і ви зможете сплатити за всі послуги. Зараз ви можете сплатити доступні або очікувати',
	],
};

export const EMPTY_RECEIPT_HINT_DATA = {
	title: 'Дані щодо комунальних послуг відсутні',
	subTitle: 'Зверніться у підтримку за інформацією',
};

export const EMPTY_RECEIPT_HINT_MODAL_DATA = {
	title: 'Інформація',
	description: [
		// eslint-disable-next-line max-len
		'Дані від постачальників комунальних послуг відсутні. Ви можете звернутися до центру клієнтської підтримки HOST, щоб оператори перевірили дані за цією адресою',
	],
};

export const ADD_SERVICE_HINT_DATA = {
	title: 'Додайте шаблон до адреси',
	subTitle: 'Для сплати в кілька кліків',
};

export const ADD_SERVICE_HINT_MODAL_DATA = {
	title: 'Додавання шаблону',
	description: [
		'Натисніть "Додати послугу", оберіть потрібну вам послугу та вкажіть її реквізити.',
		'Ось і все - тепер ви можете оплачувати даний шаблон у кілька кліків, просто вказуючи суму',
	],
};

export const MAX_LENGTH_SENT_TO_BILL_PROVIDERS = 4
