import { FC } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import classNames from 'classnames';
import { PaymentMethodsKeys } from 'utils/types';
import styles from './index.module.scss';

type ReceiptAvailablePaymentMethodsFilterProps = {
	onChange: (value: PaymentMethodsKeys) => void;
	activePaymentMethodGroup: PaymentMethodsKeys;
};

export const ReceiptAvailablePaymentMethodsFilter: FC<ReceiptAvailablePaymentMethodsFilterProps> = ({
	onChange,
	activePaymentMethodGroup,
}) => {
	const isRadapaySelected = activePaymentMethodGroup === 'isRadaPay';

	const handleChangeActiveMethod = (_: unknown, value: string) => {
		onChange(value as PaymentMethodsKeys);
	};

	return (
		<FormControl>
			<FormLabel className={styles.label} id="single-receipt-payment-methods-filter">
				<Typography color="var(--color-neutral-500)">Оберіть метод оплати:</Typography>
			</FormLabel>
			<RadioGroup
				className={styles.wrapper}
				aria-labelledby="single-receipt-payment-methods-filter"
				value={activePaymentMethodGroup}
				onChange={handleChangeActiveMethod}
			>
				<FormControlLabel
					className={classNames(styles.item, { [styles.selected]: isRadapaySelected })}
					value="isRadaPay"
					control={<Radio />}
					label={<Typography>HOSTCARD</Typography>}
				/>
				<FormControlLabel
					className={classNames(styles.item, { [styles.selected]: !isRadapaySelected })}
					value="isOtherPay"
					control={<Radio />}
					label={<Typography>VISA, MASTERCARD</Typography>}
				/>
			</RadioGroup>
		</FormControl>
	);
};
