import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Typography } from '@mui/material';
import { getBillsReport } from 'store/bill/actions';
import { resetBillsReport } from 'store/bill/reducer';
import { getBillsReportSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getReportPaymentReceipt } from 'store/report/actions';
import { resetReportReceipt } from 'store/report/reducer';
import { getReportPaymentReceiptSelector } from 'store/report/selectors';
import { openDocumentViewer, setAppLoading } from 'store/system/reducer';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { BillsPaymentDetails, BillsReportProviderItem } from 'components/Bills';
import { ReceiptPageHeader } from 'components/Receipt';
import { Loader, ProviderAccordionGroup } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { DATE_FORMAT, PAYMENT_STATUS, PDF_TYPE, ROUTER_URL } from 'utils/enums';
import {
	formatUTCDateView,
	getErrorMessage,
	getPortmoneSubBillsByService,
	getReportDownloadFileName,
} from 'utils/helpers';
import { FirebaseAnalytics, ReportService } from 'utils/services';
import { IBillsSubBillItem, IReportPaymentReceiptResponse } from 'utils/types';
import styles from './index.module.scss';

export const ServicesBillsReportPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const params = useParams<{ id: string }>();

	const billsReport = useAppSelector(getBillsReportSelector);
	const reportReceipt = useAppSelector(getReportPaymentReceiptSelector);
	const profile = useAppSelector(getUserProfileSelector);

	const [subBills, setSubBills] = useState<IBillsSubBillItem[][]>([]);

	useEffect(() => {
		return () => {
			dispatch(resetBillsReport());
			dispatch(resetReportReceipt());
		};
	}, []);

	useEffect(() => {
		if (profile && params.id) {
			handleGetBillsReport(params.id);
		}
	}, [profile]);

	useEffect(() => {
		if (billsReport.data && profile) {
			handleGetReportData(billsReport.data.id, profile.userId);
			const subBillsPayload = getPortmoneSubBillsByService(billsReport.data.subBills);
			setSubBills(subBillsPayload);
		}
	}, [billsReport.data]);

	const handleGetBillsReport = async (billId: string): Promise<void> => {
		try {
			await dispatch(getBillsReport(billId)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleGetReportData = async (
		id: string,
		userId: string
	): Promise<IReportPaymentReceiptResponse | undefined> => {
		try {
			const data = await dispatch(getReportPaymentReceipt({ id, userId })).unwrap();
			return data;
		} catch (error) {
			if (error?.statusCode !== 400 && error?.error !== 'BadRequest') {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		}
	};

	const handleClickDownload = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SERVICES_BILLS_REPORT,
				FIREBASE_EVENT_ANALYTICS_BUTTON.DOWNLOAD
			);
			if (profile && billsReport.data) {
				dispatch(setAppLoading(true));

				let reciept: IReportPaymentReceiptResponse | null = reportReceipt.data;

				if (!reciept?.s3URL) {
					reciept = (await handleGetReportData(billsReport.data.id, profile.userId)) ?? null;
				}

				if (!reciept?.s3URL) {
					throw new Error('Квитанцію ще не сформовано. Спробуйте через хвилину');
				}

				dispatch(
					openDocumentViewer({
						url: reciept.s3URL,
						isShareEnabled: true,
						fileName: getReportDownloadFileName(reciept, PDF_TYPE.PAYMENT),
					})
				);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SERVICES_BILLS_REPORT,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		history.push(ROUTER_URL.SERVICES_ARCHIVE_LIST);
	};

	const isBillWithRelatedBills = !!billsReport.data?.relatedBills.length;

	const handleDownloadRelatedBll = async (data: IBillsSubBillItem) => {
		try {
			if (profile && data.parentBillId) {
				dispatch(setAppLoading(true));
				const response = await ReportService.getPaymentReceipt({
					id: data.parentBillId,
					userId: profile.userId,
				});

				if (!response.data.s3URL) throw new Error('Квитанцію ще не сформовано. Спробуйте через хвилину');
				else if (response.data) {
					dispatch(
						openDocumentViewer({
							url: response.data.s3URL,
							isShareEnabled: true,
							fileName: getReportDownloadFileName(response.data, PDF_TYPE.PAYMENT),
						})
					);
				}
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	return (
		<div className="page">
			<div className="receipt-page-content">
				{!!billsReport.data && (
					<>
						<ReceiptPageHeader
							title={
								<Typography variant="subtitle1" component="span">
									{`Платіж № ${billsReport.data.shortenedId}`}
								</Typography>
							}
							description={
								<Typography className={styles.header__description} variant="body2" component="span">
									{formatUTCDateView(
										billsReport.data.paidAt ?? billsReport.data.createdAt,
										DATE_FORMAT.RADABANK_TOKEN_DATE
									)}
								</Typography>
							}
							onBackClick={handleGoBack}
							onDownload={
								billsReport.data.subBills[0].status === PAYMENT_STATUS.PAID && !isBillWithRelatedBills
									? handleClickDownload
									: undefined
							}
						/>
						<div className="report-bills-content">
							<ProviderAccordionGroup>
								{subBills.map((item) => (
									<BillsReportProviderItem
										onClickDownload={
											item.some((subBillItem) => subBillItem.status === PAYMENT_STATUS.PAID) && isBillWithRelatedBills
												? handleDownloadRelatedBll
												: undefined
										}
										id={item[0]?.id}
										key={`report-service-provider-${item[0].id}`}
										serviceList={item}
									/>
								))}
								{billsReport.data.relatedBills.map((relatedBill) => (
									<BillsReportProviderItem
										key={relatedBill.id}
										id={relatedBill.id}
										serviceList={relatedBill.subBills}
										onClickDownload={
											relatedBill.subBills.length &&
											relatedBill.subBills.some((item) => item.status === PAYMENT_STATUS.PAID)
												? handleDownloadRelatedBll
												: undefined
										}
									/>
								))}
							</ProviderAccordionGroup>
							<BillsPaymentDetails data={billsReport.data} />
						</div>
					</>
				)}
			</div>
			{billsReport.isLoading && <Loader />}
		</div>
	);
};
