import { FC, ReactNode, useMemo } from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { getCreditCardIcon } from 'utils/helpers';
import { ICardListItemResponse } from 'utils/types';
import styles from './index.module.scss';

interface CardPreviewProps {
	data: ICardListItemResponse;
	icon?: ReactNode;
	className?: string;
}

export const CardPreview: FC<CardPreviewProps> = ({ data, className = '', icon }) => {
	const DefaultIcon = useMemo(() => getCreditCardIcon(data.cardName, data.panMasked), [data.cardName, data.panMasked]);

	return (
		<div className={classNames(styles.content, className)}>
			<div key={data.cardId} className={styles.content__icon}>
				{icon || <DefaultIcon />}
			</div>

			<div className={styles.content__description}>
				<Typography variant="subtitle1">** {data.panMasked.split('*')[1]}</Typography>
				<Typography variant="body2">{data.cardName ?? ''}</Typography>
			</div>
		</div>
	);
};
