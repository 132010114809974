import { PORTMONE_AMOUNT_FIELD_NAME } from 'utils/constants';
import { CHECK_RESPONSE, CURRENCY_LABEL } from 'utils/enums';
import {
	IAddServiceFieldValueRequest,
	IBillCreateRequestArrayDataItem,
	IBillRequestData,
	ICreateBillRequestServiceFieldsData,
	IGetServiceDataResponse,
	IOtherServicePaymentRequest,
	IReceiptOtherServiceResponse,
	IServiceBillError,
	IServiceBillRequestData,
	IServiceDataObject,
	IServiceFieldResponse,
	IServiceFieldValueResponse,
	ITariffItemData,
	IUpdateServiceFieldValueRequest,
} from 'utils/types';

export const getServiceInputConfig = (
	data: IAddServiceFieldValueRequest | IUpdateServiceFieldValueRequest,
	fieldsList: IServiceFieldResponse[]
): any | undefined => {
	const currentFieldConfig = fieldsList.find((item) => item.name === data.name);
	if (currentFieldConfig) {
		const payload = {
			name: currentFieldConfig.name,
			placeholder: currentFieldConfig.placeHolder || '',
			label: currentFieldConfig.title || '',
			helperText: currentFieldConfig.errorText || currentFieldConfig.hint || getServiceHelperText(currentFieldConfig),
			error: !!currentFieldConfig.errorText,
			inputProps: {
				inputMode: currentFieldConfig.type === 'NUM' ? 'decimal' : 'text',
				maxLength: currentFieldConfig.maxLength ?? undefined,
			},
			value: data.value,
			min: currentFieldConfig.minAmount ?? undefined,
			max: currentFieldConfig.maxAmount ?? undefined,
		};

		return payload;
	}
};

export const checkIsAmountServiceFieldName = (name: string): boolean => name === PORTMONE_AMOUNT_FIELD_NAME;

export const checkIsAmountServiceField = (
	item:
		| IAddServiceFieldValueRequest
		| IUpdateServiceFieldValueRequest
		| IServiceFieldValueResponse
		| IServiceFieldResponse
): boolean => checkIsAmountServiceFieldName(item.name);

export const checkIsServiceValueAllowed = (
	value: string,
	config: IServiceFieldResponse | null,
	isZeroAllowed = false
): boolean => {
	let isValid = true;

	if (!isZeroAllowed && config?.minAmount && +value < config.minAmount) {
		isValid = false;
	}
	if (config?.maxAmount && +value > config.maxAmount) {
		isValid = false;
	}
	if (config?.maxLength && value.length > config.maxLength) {
		isValid = false;
	}
	return isValid;
};

export const getServiceHelperText = (config: IServiceFieldResponse | undefined): string => {
	let text = '';
	if ((config && config?.minAmount) || config?.maxAmount) {
		text = `${config?.minAmount ? `Від ${config.minAmount}${CURRENCY_LABEL.UAH}` : ''} ${
			config?.maxAmount ? `до ${config.maxAmount}${CURRENCY_LABEL.UAH}` : ''
		}`;
	}
	return text;
};

export const getTariffItemDataPreviewByKey = (data: ITariffItemData, key: string): ITariffItemData => ({
	name: data[`${key}_name`] as string,
	state: data[`${key}_state`],
	value: data[key],
	payee: data.payee,
});

export const sortServicesFieldsByFieldsNames = (list: IServiceFieldResponse[]): IServiceFieldResponse[] =>
	list.sort((a, b) => {
		if (a.name === PORTMONE_AMOUNT_FIELD_NAME || b.name === PORTMONE_AMOUNT_FIELD_NAME) {
			return a.name === PORTMONE_AMOUNT_FIELD_NAME ? 1 : -1;
		}
		return a.name.localeCompare(b.name);
	});

export const getContractNumberFieldName = (data: IGetServiceDataResponse | IReceiptOtherServiceResponse): string =>
	data.dataObject?.contractNumber.split('.').slice(-1)[0] ?? '';

export const findServiceProviderAccountIdValue = (
	fieldValues: IAddServiceFieldValueRequest[] | IUpdateServiceFieldValueRequest[] | IServiceFieldValueResponse[],
	data: IGetServiceDataResponse | IReceiptOtherServiceResponse
): string => {
	const providerAccountIdFieldName = getContractNumberFieldName(data);
	return fieldValues.find((item) => item.name === providerAccountIdFieldName)?.value ?? '';
};

export const findPortmoneProviderAmountFieldValue = (serviceRequestData: IOtherServicePaymentRequest): string => {
	const serviceFieldsDataList = Object.values(serviceRequestData?.serviceProviderData[0]?.serviceFieldsData);
	if (serviceFieldsDataList.length) {
		const key = Object.keys(serviceRequestData.serviceProviderData[0].serviceFieldsData).find(
			checkIsAmountServiceFieldName
		);
		if (key) {
			return serviceRequestData.serviceProviderData[0].serviceFieldsData[key];
		}
	}
	return '';
};

export const flatServiceBillRequestData = (
	billRequestData: (IBillCreateRequestArrayDataItem | IServiceBillError)[]
): {
	serviceInfo: IBillCreateRequestArrayDataItem['services'];
	tariffItem: IBillCreateRequestArrayDataItem['tariffItem'];
}[] => {
	return billRequestData.reduce(
		(
			acc: {
				serviceInfo: IBillCreateRequestArrayDataItem['services'];
				tariffItem: IBillCreateRequestArrayDataItem['tariffItem'];
			}[],
			curr
		) => {
			if (Array.isArray(curr)) {
				return [...acc, ...flatServiceBillRequestData(curr)];
			}

			// Skips items with error
			if ('error' in curr) {
				return acc;
			}

			return [...acc, { serviceInfo: { ...curr.services }, tariffItem: curr.tariffItem }];
		},
		[]
	);
};

export const flatReceiptServiceBillRequestData = (
	billRequestData: (IBillRequestData | IServiceBillError)[]
): IBillRequestData[] => {
	return billRequestData.reduce((acc, curr) => {
		if (Array.isArray(curr)) {
			return [...acc, ...flatReceiptServiceBillRequestData(curr)];
		}

		// Skips items with error
		if ('error' in curr) {
			return acc;
		}

		return [...acc, curr];
	}, [] as IBillRequestData[]);
};

export const getErrorMessageFromTarrifItems = (tarrifs?: IServiceBillRequestData | IBillRequestData): string => {
	if (!tarrifs) return '';
	if (!Array.isArray(tarrifs)) {
		if ('error' in tarrifs) return tarrifs.error as string;
		return '';
	}
	if (tarrifs.every((item) => !Array.isArray(item) && 'error' in item)) {
		const [first] = tarrifs as IServiceBillError[];

		if (Array.isArray(first)) return '';

		return first.error as string;
	}
	return '';
};

export const getServiceTariffItemsList = (data: {
	// key as id
	[key: string]: ITariffItemData;
}): ITariffItemData[] => {
	return Object.values(data)
		.map((item) => {
			// remove all items if it readings of a meter
			const objectKeysWithoutCounters = Object.keys(item)
				.reduce(
					(keysAcc, key) => (key.includes('_name') ? [...keysAcc, key.replace('_name', '')] : keysAcc),
					[] as string[]
				)
				.filter((item) => item !== 'counter');
			return objectKeysWithoutCounters.reduce(
				(objectAcc, key) => ({
					...objectAcc,
					name: item.name,
					[key]: item[key],
					[`${key}_name`]: item[`${key}_name`],
					[`${key}_state`]: item[`${key}_state`],
				}),
				{} as ITariffItemData
			);
		})
		.filter((item) => Object.keys(item).length);
};

export const getServiceBillRequestFieldsNames = (data: IServiceDataObject): string[] =>
	Object.values(data).reduce(
		(acc, item) => (item.includes('@bill.payerData.') ? [...acc, item.split('.').slice(-1)[0]] : acc),
		[] as string[]
	);

export const getServicesFieldsList = (
	fieldsList: IServiceFieldResponse[],
	serviceDataObject: IServiceDataObject,
	isOnlyRequired?: boolean
): IServiceFieldResponse[] => {
	const providerFieldsNameList: string[] = getServiceBillRequestFieldsNames(serviceDataObject);
	const serviceFieldsList = fieldsList.filter((item) =>
		isOnlyRequired
			? providerFieldsNameList.includes(item.name) && item.required === CHECK_RESPONSE.YES
			: providerFieldsNameList.includes(item.name)
	);
	return serviceFieldsList;
};

export const getServiceBillRequestKeyCombination = (
	data: IServiceDataObject
): { reqKeyName: string; fieldValueName: string }[] => {
	const fieldValueNamePattern = '@bill.payerData.';
	return Object.keys(data)
		.filter((reqKeyName) => data[reqKeyName].includes(fieldValueNamePattern))
		.reduce(
			(acc, reqKeyName) => [...acc, { reqKeyName, fieldValueName: data[reqKeyName].split(fieldValueNamePattern)[1] }],
			[] as { reqKeyName: string; fieldValueName: string }[]
		);
};

export const getCreateBillRequestServiceFieldsData = (
	data: IServiceDataObject,
	fieldsValuesList: IAddServiceFieldValueRequest[] | IUpdateServiceFieldValueRequest[]
): ICreateBillRequestServiceFieldsData => {
	const serviceFieldsDataKeyCombination = getServiceBillRequestKeyCombination(data);
	const serviceFieldsData = serviceFieldsDataKeyCombination.reduce((acc, item) => {
		const fieldValue = fieldsValuesList.find((fieldValues) => fieldValues.name === item.fieldValueName)?.value ?? '';

		return fieldValue
			? {
					...acc,
					[item.reqKeyName]: fieldValue,
			  }
			: acc;
	}, {} as ICreateBillRequestServiceFieldsData);
	return serviceFieldsData;
};

export const filterEmptyAmountBillCreateServices = (services: IBillCreateRequestArrayDataItem[]) =>
	services.filter((service) => Number(service.tariffItem.amount) > 0);
