/* eslint-disable no-nested-ternary */
import { FC, useEffect, useRef, useState } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { TextField, Typography } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { showToast, useAppDispatch } from 'store';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { Checkbox } from 'components/shared/Checkbox';
import { CURRENCY_LABEL, PROVIDER_TYPE } from 'utils/enums';
import {
	convertCoinsToUAH,
	convertUAHToCoins,
	getAmountTitle,
	getAmountToPayString,
	getErrorMessage,
	getErrorMessageFromTarrifItems,
	getProviderServiceTitle,
	getServiceTariffItemsList,
	handleKeyDownServiceAmount,
} from 'utils/helpers';
import { useDebounce } from 'utils/hooks';
import { BillService } from 'utils/services';
import { IBillRequestData, IProviderServiceResponse, ITariffItemData } from 'utils/types';
import { ServiceTariffItem } from './ServiceTariffItem';
import styles from './index.module.scss';

interface EditServiceAmountToPayProps {
	data: IProviderServiceResponse;
	incomeBalance: number;
	isChecked: boolean;
	onSelect: (data: IProviderServiceResponse) => void;
	onChange: (data: IProviderServiceResponse) => void;
	feeAmount?: number | null;
	onChangeFee?: (
		providerType: PROVIDER_TYPE,
		serviceId: string | number,
		amount: number,
		hostCardAmount: number
	) => void;
	contentClassName?: string;
}

export const EditServiceAmountToPay: FC<EditServiceAmountToPayProps> = ({
	data,
	isChecked,
	onSelect,
	onChange,
	incomeBalance,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	feeAmount,
	onChangeFee,
	contentClassName = '',
}) => {
	const dispatch = useAppDispatch();

	const inputRef = useRef<HTMLInputElement>(null);

	const debouncedAmountToPay = useDebounce<number | null>(convertUAHToCoins(data.amountToPay), 700);

	useEffect(() => {
		if (debouncedAmountToPay) {
			getServiceFee(debouncedAmountToPay, data.providerType, data.code);
		}
	}, [debouncedAmountToPay]);

	const billReceipt = useAppSelector(getBillReceiptSelector);

	const [isDisabled, setIsDisabled] = useState(false);
	const [tarifItemsData, setTarifItemsData] = useState<{ description?: string; list: ITariffItemData[] }>({
		description: '',
		list: [],
	});

	useEffect(() => {
		setIsDisabled(billReceipt.isLoading || !!billReceipt.data?.isArchived);
	}, [billReceipt, data]);

	useEffect(() => {
		if ((data.tarifItems as IBillRequestData)?.tariff_items) {
			const payload = getServiceTariffItemsList((data.tarifItems as IBillRequestData).tariff_items);
			setTarifItemsData({ list: payload, description: (data.tarifItems as IBillRequestData)?.description ?? '' });
		}
	}, [data.tarifItems]);

	const handleChangeValue = ({ value }: NumberFormatValues): void => {
		onChange({ ...data, amountToPay: value === '' ? 0 : +value });
		if (inputRef.current && +value === 0) {
			setTimeout(() => {
				(inputRef.current as HTMLInputElement).setSelectionRange(1, 1);
			});
		}
	};

	const getServiceFee = async (amountValue: number, providerType: PROVIDER_TYPE, code: string): Promise<void> => {
		try {
			if (onChangeFee) {
				let feeAmount = 0;
				let feeHostCardAmount = 0;
				if (amountValue) {
					const response = await BillService.getServiceFee(amountValue, code, providerType, true);
					feeAmount = convertCoinsToUAH(response.data.fee);
					feeHostCardAmount = convertCoinsToUAH(response.data.feeHostCard);
				}
				onChangeFee(providerType, data.id, feeAmount, feeHostCardAmount);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const itemError = getErrorMessageFromTarrifItems(data.tarifItems ?? undefined);

	return (
		<div className={styles.wrapper}>
			<div className={classNames(styles.content, contentClassName)}>
				<div className={styles.content__info}>
					{/* eslint-disable-next-line no-nested-ternary */}
					{itemError ? (
						<Typography variant="subtitle2" fontWeight="normal">
							{itemError}
						</Typography>
					) : tarifItemsData.list.length ? (
						<div className={styles.tariff__list}>
							<Typography sx={{ alignSelf: 'flex-start' }} variant="caption">
								{tarifItemsData.description}
							</Typography>
							{tarifItemsData.list.map((item, index) => (
								<ServiceTariffItem key={`tarif-list-${item.name}-${index}`} data={item} />
							))}
						</div>
					) : (
						<>
							<Typography className={styles.content__title} variant="subtitle2">
								{getProviderServiceTitle(data)}
							</Typography>
							{/* {Number.isFinite(feeAmount) ? (
								// TODO: fix fee and uncomment it later
								<Typography variant="caption">
								  Комісія: {getAmountTitle(feeAmount as number)}
								</Typography>
							) : ( */}
							<Typography variant="caption">
								Баланс:
								<span
									className={classNames(styles.content__info_accented, {
										[styles.content__info_positive]: incomeBalance <= 0,
									})}
								>{` ${getAmountTitle(getAmountToPayString(incomeBalance))} `}</span>
								на {dayjs(data.createdAt).format('DD.MM.YYYY')}
							</Typography>
							{/* )} */}
							<NumericFormat
								suffix={CURRENCY_LABEL.UAH}
								id={`${data.id}-input`}
								customInput={TextField}
								inputRef={inputRef}
								decimalScale={2}
								value={data.amountToPay}
								onValueChange={handleChangeValue}
								allowNegative={false}
								allowLeadingZeros
								fixedDecimalScale
								defaultValue={0}
								thousandSeparator=" "
								size="small"
								inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
								disabled={isDisabled}
								allowedDecimalSeparators={[',']}
								onKeyDown={(event) => handleKeyDownServiceAmount(event, data.amountToPay)}
							/>
						</>
					)}
				</div>
				{!data.tarifItems && !data.isArchived && (
					<Checkbox
						id={`${data.id}-checkbox`}
						checked={isChecked}
						onChange={() => onSelect(data)}
						disabled={isDisabled}
					/>
				)}
			</div>
		</div>
	);
};
