import { FC, useState } from 'react';
import classNames from 'classnames';
import { CustomModal } from 'components/shared';
import { IRadabankInternalCurrentCard } from 'utils/types';
import { RadabankCardForTransferList } from '../List';
import styles from './index.module.scss';

interface RadabankChooseCardForTransferModalProps {
	isOpen: boolean;
	onClose: () => void;
	cardList: IRadabankInternalCurrentCard[];
	onSelectCard: (card: IRadabankInternalCurrentCard) => void;
	selectedCard: IRadabankInternalCurrentCard;
	title?: string;
}

export const RadabankChooseCardForTransferModal: FC<RadabankChooseCardForTransferModalProps> = ({
	isOpen,
	onClose,
	cardList,
	onSelectCard,
	selectedCard,
	title,
}) => {
	const [isHideBackdrop, setIsHideBackdrop] = useState(false);

	const handleHideBackdrop = () => {
		setIsHideBackdrop(true);
	};

	const handleShowBackdrop = () => {
		setIsHideBackdrop(false);
	};

	return (
		<CustomModal
			title={title}
			isCloseHeader={!!title}
			className={classNames(styles.modal, {
				// In order to prevent flickering on IOS
				[styles['hide-backdrop']]: isHideBackdrop,
			})}
			onWillDismiss={handleHideBackdrop}
			onWillPresent={handleShowBackdrop}
			isOpen={isOpen}
			onClose={onClose}
		>
			<RadabankCardForTransferList cardList={cardList} onSelectCard={onSelectCard} selectedCardId={selectedCard.id} />
		</CustomModal>
	);
};
