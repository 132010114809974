import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
	getRadabankInternalCardsList,
	getRadabanlInternalClientSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { ConfirmOTPCode } from 'components/Auth';
import { ArrowBackButton, CustomButton } from 'components/shared';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_OTP_LENGTH,
} from 'utils/constants';
import { RADABANK_BOOLEAN_VALUE, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { decryptData, getErrorMessage } from 'utils/helpers';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics, RadabankService, StorageService } from 'utils/services';
import { IUserProfileResponse } from 'utils/types';

export const RadabankAuthInternalAuthorizePage: FC = () => {
	const history = useHistory();
	const { state }  = useLocation<{ from?: string }>();

	const dispatch = useAppDispatch();

	const handleClickBack = useGoBackRadabank();

	const profile = useAppSelector(getUserProfileSelector);
	const radabankInternalClient = useAppSelector(getRadabanlInternalClientSelector);

	const [otp, setOtp] = useState('');
	const [isValid, setIsValid] = useState(true);

	useEffect(() => {
		return () => {
			StorageService.remove(STORAGE_KEY.VERIFICATION_ID_DATE);
		};
	}, []);

	useEffect(() => {
		handleAuthorize();
	}, []);

	const handleChangeOTP = (value: string): void => {
		setIsValid(true);
		setOtp(value);
	};

	const handleAuthorize = async (): Promise<void> => {
		try {
			dispatch(setAppLoading(true));
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.INTERNAL_AUTH,
				FIREBASE_EVENT_ANALYTICS_BUTTON.RESEND_OTP
			);
			const password = await StorageService.get<string>(STORAGE_KEY.USER_PASSWORD);
			const decryptedPasswordData = decryptData(password ?? '');

			if (!decryptedPasswordData || !password || !profile || !radabankInternalClient) {
				return history.replace(ROUTER_URL.ERROR);
			}

			if (radabankInternalClient.changepassword === RADABANK_BOOLEAN_VALUE.TRUE) {
				await RadabankService.activateInternal({
					username: radabankInternalClient.username,
					userId: profile.userId,
					passnew: decryptedPasswordData,
					passrenew: decryptedPasswordData,
				});
			}
			await RadabankService.authorizeInternal({
				userId: profile.userId,
				username: radabankInternalClient.username,
				userpass: decryptedPasswordData,
			});
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleSubmit = async (): Promise<void> => {
		try {
			dispatch(setAppLoading(true));
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.INTERNAL_AUTH,
				FIREBASE_EVENT_ANALYTICS_BUTTON.CONTINUE
			);
			const userId = (profile as IUserProfileResponse).userId;
			await RadabankService.verifyInternalSms({
				userId,
				smsdata: otp,
			});
			const isOuterRedirect = !!state?.from;

			if(!isOuterRedirect) {
				dispatch(getRadabankInternalCardsList({ userId }));
			}
			history.replace(state?.from || ROUTER_URL.RADABANK_CARD);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.INTERNAL_AUTH,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	return (
		<div className="host-card-page-color-white-auth">
			<ArrowBackButton onClick={handleGoBack} />
			<div className="auth-content">
				<ConfirmOTPCode
					title="Маємо перевірити ваш номер ще раз. Введіть код із SMS"
					phone={profile?.phone ?? ''}
					value={otp}
					onChange={handleChangeOTP}
					isValid={isValid}
					onResendCode={handleAuthorize}
					length={RADABANK_OTP_LENGTH}
					onSubmit={handleSubmit}
				/>
			</div>
			<CustomButton label="Продовжити" onClick={handleSubmit} disabled={otp.length !== RADABANK_OTP_LENGTH} />
		</div>
	);
};
